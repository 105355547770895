<h3>Rendeléseim</h3>


  <div class="row w-100 mb-3">
    <div class="col">Dátum</div>
    <div class="col-auto text-center text-nowrap">Rendelési azonosító</div>
    <div class="col text-end pe-0">Végösszeg</div>
  </div>

  <accordion [isAnimated]="true">
    <accordion-group [panelClass]="customAccordion">
      <div class="w-100 shadow-none pt-2" accordion-heading tooltip="Kattints a rendelés megtekintéséhez!" [delay]="500">


        <div class="row">
          <div class="col">2022.04.16.</div>
          <div class="col text-center">1110</div>
          <div class="col d-flex justify-content-end text-nowrap">12 000 Ft</div>
        </div>
      </div>

      <div class="orders-container">
        <div class="product">
          <div class="product-image">
            <img src="/assets/images/product/k-karp_019-25-904_parent_drake_xtr_bite_alarm_1.jpg" alt="product">
          </div>

          <div class="product-details">
            <span class="mt-0 mb-0 product-title">K-Karp Drake XTR Stiff Hanger swinger</span>
          </div>

          <div class="product-line-price">
            2 x <span class="text-danger">10 000Ft</span>
          </div>
        </div>
        <div class="product">
          <div class="product-image">
            <img src="/assets/images/product/k-karp_019-25-904_parent_drake_xtr_bite_alarm_1.jpg" alt="product">
          </div>

          <div class="product-details">
            <span class="mt-0 mb-0 product-title">K-Karp Drake XTR Stiff Hanger swinger</span>
          </div>

          <div class="product-line-price">
            2 x <span class="text-danger">10 000Ft</span>
          </div>
        </div>
        <div class="product">
          <div class="product-image">
            <img src="/assets/images/product/k-karp_019-25-904_parent_drake_xtr_bite_alarm_1.jpg" alt="product">
          </div>

          <div class="product-details">
            <span class="mt-0 mb-0 product-title">K-Karp Drake XTR Stiff Hanger swinger</span>
          </div>

          <div class="product-line-price">
            2 x <span class="text-danger">10 000Ft</span>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center">
        <button type="button" class="btn btn-sm btn-info text-white"><i class="fa fa-lg fa-file-o me-2"></i> Számla letöltése</button>
      </div>

    </accordion-group>
    <accordion-group [panelClass]="customAccordion">
      <div class="w-100 shadow-none pt-2"  accordion-heading>

        <div class="row">
          <div class="col">2022.04.16.</div>
          <div class="col text-center">1110</div>
          <div class="col d-flex justify-content-end text-nowrap">12 000 Ft</div>
        </div>
      </div>

      <div class="orders-container">
        <div class="product">
          <div class="product-image">
            <img src="/assets/images/product/k-karp_019-25-904_parent_drake_xtr_bite_alarm_1.jpg" alt="product">
          </div>

          <div class="product-details">
            <span class="mt-0 mb-0 product-title">K-Karp Drake XTR Stiff Hanger swinger</span>
          </div>

          <div class="product-line-price">
            2 x <span class="text-danger">10 000Ft</span>
          </div>
        </div>
        <div class="product">
          <div class="product-image">
            <img src="/assets/images/product/k-karp_019-25-904_parent_drake_xtr_bite_alarm_1.jpg" alt="product">
          </div>

          <div class="product-details">
            <span class="mt-0 mb-0 product-title">K-Karp Drake XTR Stiff Hanger swinger</span>
          </div>

          <div class="product-line-price">
            2 x <span class="text-danger">10 000Ft</span>
          </div>
        </div>
        <div class="product">
          <div class="product-image">
            <img src="/assets/images/product/k-karp_019-25-904_parent_drake_xtr_bite_alarm_1.jpg" alt="product">
          </div>

          <div class="product-details">
            <span class="mt-0 mb-0 product-title">K-Karp Drake XTR Stiff Hanger swinger</span>
          </div>

          <div class="product-line-price">
            2 x <span class="text-danger">10 000Ft</span>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center">
        <button type="button" class="btn btn-sm btn-info text-white"><i class="fa fa-lg fa-file-o me-2"></i> Számla letöltése</button>
      </div>

    </accordion-group>
    <accordion-group [panelClass]="customAccordion">
      <div class="w-100 shadow-none pt-2"  accordion-heading tooltip="Kattints a rendelés megtekintéséhez!" [delay]="500">

        <div class="row">
          <div class="col">2022.04.16.</div>
          <div class="col text-center">1110</div>
          <div class="col d-flex justify-content-end text-nowrap">12 000 Ft</div>
        </div>
      </div>

      <div class="orders-container">
        <div class="product">
          <div class="product-image">
            <img src="/assets/images/product/k-karp_019-25-904_parent_drake_xtr_bite_alarm_1.jpg" alt="product">
          </div>

          <div class="product-details">
            <span class="mt-0 mb-0 product-title">K-Karp Drake XTR Stiff Hanger swinger</span>
          </div>

          <div class="product-line-price">
            2 x <span class="text-danger">10 000Ft</span>
          </div>
        </div>
        <div class="product">
          <div class="product-image">
            <img src="/assets/images/product/k-karp_019-25-904_parent_drake_xtr_bite_alarm_1.jpg" alt="product">
          </div>

          <div class="product-details">
            <span class="mt-0 mb-0 product-title">K-Karp Drake XTR Stiff Hanger swinger</span>
          </div>

          <div class="product-line-price">
            2 x <span class="text-danger">10 000Ft</span>
          </div>
        </div>
        <div class="product">
          <div class="product-image">
            <img src="/assets/images/product/k-karp_019-25-904_parent_drake_xtr_bite_alarm_1.jpg" alt="product">
          </div>

          <div class="product-details">
            <span class="mt-0 mb-0 product-title">K-Karp Drake XTR Stiff Hanger swinger</span>
          </div>

          <div class="product-line-price">
            2 x <span class="text-danger">10 000Ft</span>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center">
        <button type="button" class="btn btn-sm btn-info text-white text-white"><i class="fa fa-lg fa-file-o me-2"></i> Számla letöltése</button>
      </div>

    </accordion-group>
  </accordion>


  <app-my-order-item *ngFor="let order of orders" [order]="order"></app-my-order-item>

