import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { User } from '../model/User';
import { ExternalUserType } from '../model/ExternalUserType';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private httpClient: HttpClient) {
  }

  login(token: string): Observable<User> {
    // return this.httpClient.post<User>('/api/authentication/login', {}, {headers: {Authorization: `Authorization ${token}`}});
    return of({
      username: 'user',
      role: 'USER',
      email: 'user@gmail.com',
      reviews: [],
      favouriteProducts: [],
      isFacebookUser: false,
      externalUserId: '123',
      externalUserType: ExternalUserType.AWS,
      photoUrl: '',
      userId: '3',
      id: 123
    });
  }

  logout(): Observable<any> {
    return this.httpClient.post<void>('/api/authentication/logout', {});
  }
}
