import {Component, EventEmitter, Inject, OnDestroy, OnInit, Output} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { initCartSelector } from '../../../store/cart/CartSelector';
import { Store } from '@ngrx/store';
import { CartState } from '../../../store/cart/CartReducer';
import { Router } from '@angular/router';
import { FormService } from '../../../service/form.service';
import { Subscription } from 'rxjs';
import { PaymentService } from '../../../service/payment.service';
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html'
})
export class CheckoutComponent implements OnInit, OnDestroy {

  // @ViewChild('checkoutForm')
  // checkoutForm: any;

  fullForm: FormGroup;
  isDeliveryAddressTheSame = false;
  isCompany: boolean;
  cart: any;
  selectedCountry = 'hu';
  selectedAddress: any = '';
  selectedPhoneCountry = '';

  companyChecked = false;
  deliveryIsChecked1 = false;
  deliveryIsChecked2 = false;
  deliveryIsChecked3 = false;
  customCompanyPanelClass = 'company-panel';

  // Accordion check out
  isFirstOpen = true;
  oneAtATime = true;
  customAccordion = 'custom-accordion';
  firstChecked = true;
  isOpen = [false, false, false];
  isDisabled = [false, false, false];

  @Output()
  cartClicked: EventEmitter<string> = new EventEmitter<string>();

  //   = Examples.find(e => {
  //   console.log(e)
  //   return e.name === 'hu'
  // });

  private subscriptions: Array<Subscription> = new Array<Subscription>();

  constructor(private readonly fb: FormBuilder,
              private readonly cartStore: Store<CartState>,
              private readonly router: Router,
              private readonly paymentService: PaymentService,
              private readonly formService: FormService,
              @Inject(DOCUMENT) private document: any) {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.cartStore.select(initCartSelector).subscribe((cart) => {
        this.cart = cart;
        if (Object.values(this.cart).length === 0) {
          this.router.navigateByUrl('');
        }
      })
    );
    this.fullForm = this.formService.getCheckoutForm();
  }

  // post price, shipping cost, order reference number, számlázási adatok, email, language, redirecturl, (invoice data), shipping data
  continue() {
    console.log(this.fullForm);
    // this.paymentService.sendPayment(this.fullForm.value).subscribe((resp) => {
    //   console.log(resp);
    // });
    // this.router.navigate(['https://cmsforms.rootor.eu/payment_dev.php'], {
    //   queryParams: {
    //     address: 'valami'
    //   }
    // });
    // window.location.href = 'https://cmsforms.rootor.eu/payment_dev.php?address=valami'
    // console.log(this.checkoutForm);
    // this.checkoutForm.nativeElement.submit();
  }

  changeValue(e) {
    this.isDeliveryAddressTheSame = e;
  }
  companyCheck() {
    this.companyChecked = this.companyChecked !== true;
  }

  deliveryFirstCheck() {
    this.firstChecked = this.firstChecked !== false;
  }

  deliveryCheck1() {
    this.deliveryIsChecked1 = true;
    this.deliveryIsChecked2 = false;
    this.deliveryIsChecked3 = false;
  }

  deliveryCheck2() {
    this.deliveryIsChecked2 = true;
    this.deliveryIsChecked1 = false;
    this.deliveryIsChecked3 = false;
  }

  deliveryCheck3() {
    this.deliveryIsChecked3 = true;
    this.deliveryIsChecked1 = false;
    this.deliveryIsChecked2 = false;
  }

  openGlsParcelshopMap() {
    console.log('opening GLS map');
  }

  isOpenChange(event, index: number): void {
    this.isDisabled[0] = false;
    this.isDisabled[1] = false;
    this.isDisabled[2] = false;

    this.isDisabled[index] = true;
    // if (this.isOpen[index]) {
    //   event.preventDefault();
    // }
  }
}
