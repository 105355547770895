import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../../../service/user.service';
import { Profile } from '../../../model/Profile';
import { FormGroup } from '@angular/forms';
import { FormService } from '../../../service/form.service';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { User } from '../../../model/User';
import { AddAddress, InitProfile } from '../../../store/user/UserActions';
import { initProfileSelector, initUserSelector } from '../../../store/user/UserSelector';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html'
})
export class AccountComponent implements OnInit, OnDestroy {

  user: User;
  profile: Profile;

  companyForm: FormGroup;
  addressForm: FormGroup;

  addNewAddressState: boolean;

  isSubmitEnabled: boolean = true;

  private subscriptions: Array<Subscription> = new Array<Subscription>();

  constructor(private readonly userService: UserService,
              private readonly formService: FormService,
              private readonly activatedRoute: ActivatedRoute,
              private readonly userStore: Store<User>) {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngOnInit(): void {
    this.profile = this.activatedRoute.snapshot.data.preload;
    this.userStore.dispatch(new InitProfile(this.profile));
    this.userStore.select(initProfileSelector).subscribe((profile) => {
      this.profile = profile;
    });
    this.subscriptions.push(
      this.userStore.select(initUserSelector).subscribe((user) => {
        this.user = user;
      })
    );
    this.addressForm = this.formService.getAddressForm();
  }

  changeAddNewAddressState() {
    this.addNewAddressState = true;
  }

  closeAddNewAddress() {
    this.addNewAddressState = false;
    this.addressForm.reset();
  }

  addNewAddress() {
    if (this.addressForm.invalid) {
      return;
    }

    this.userService.addAddress(this.addressForm.value).subscribe(() => {
      this.userStore.dispatch(new AddAddress(this.addressForm.value));
      this.closeAddNewAddress();
    });
   }
}
