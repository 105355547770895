<div class="container-fluid check-out-container">

  <div class="row ms-lg-2">
    <div class="col-24 col-lg-14 p-0">

      <mat-horizontal-stepper class="stepper" linear #stepper>
<!--        <mat-step [stepControl]="fullForm" [editable]="true">-->
          <mat-step>
          <ng-template matStepLabel>Elérhetőség</ng-template>

          <div class="d-flex justify-content-between">
<h3>Hol érhetünk el?</h3>
<button class="btn btn-sm btn-outline-primary btn-rounded align-self-start"><i class="fa fa-sign-in"></i> Bejelentkezés</button>
          </div>

          <div class="form-check mb-4">
            <input class="form-check-input" type="checkbox" id="companyCheck" [checked]="companyChecked" (click)="companyCheck()">
            <label class="form-check-label" for="companyCheck">
              Cégként vásárolok
            </label>
          </div>

          <div class="row g-4 mb-3">

            <div class="col-24">
              <accordion [isAnimated]="true">
                <accordion-group [isOpen]="companyChecked" heading="Cégadatok" [panelClass]="customCompanyPanelClass">

                  <div class="row g-4">
                    <div class="col-lg-24 col-xl-12">
                      <div class="form-floating mb-1">
                        <input type="text" class="form-control" id="companyNameInput" placeholder="Cégnév">
                        <label for="companyNameInput">Cégnév</label>
                      </div>
                    </div>
                    <div class="col-lg-12 col-xl-6">
                      <div class="form-floating">
                        <select class="form-select" aria-label="adószám" id="taxInput">
                          <option value="hu">Magyar adószám</option>
                          <option value="eu">EU adószám</option>
                        </select>
                        <label for="taxInput">Adószám típus</label>
                      </div>
                    </div>
                    <div class="col-lg-12 col-xl-6">
                      <div class="form-floating">
                        <input type="text" class="form-control" id="taxNumber" placeholder="Adószám">
                        <label for="taxNumber">Adószám</label>
                      </div>
                    </div>

                  </div>
                </accordion-group>
              </accordion>
            </div>

            <div class="col-24 col-md">
          <div class="form-floating mb-2">
            <input type="email" class="form-control" id="emailInput" placeholder="E-mail cím">
            <label for="emailInput">E-mail cím</label>
          </div>
            </div>

            <div class="col-24 col-md">
              <div class="form-floating mb-2">
                <input type="text" class="form-control" id="nameInput" placeholder="Név">
                <label for="nameInput">Név</label>
              </div>
            </div>
          </div>

          <div class="row g-4">

<!--              <label for="exampleDataList" class="form-label">Datalist example</label>-->
<!--              <input class="form-control" list="datalistOptions" id="exampleDataList" placeholder="Type to search...">-->
<!--              <datalist id="datalistOptions">-->
<!--                <option value="San Francisco">-->
<!--                <option value="New York">-->
<!--                <option value="Seattle">-->
<!--                <option value="Los Angeles">-->
<!--                <option value="Chicago">-->
<!--              </datalist>-->
            <div class="col-24 col-md">
              <div class="form-floating">
                <input type="text" class="form-control" id="phoneInput" placeholder="Telefonszám">
                <label for="phoneInput">Telefonszám</label>
              </div>
            </div>

            <div class="col-24 col-md d-flex align-items-center">
              <div class="form-text"><i class="fa fa-lg fa-exclamation-triangle text-danger"></i> Helyes formátum: +3612345678</div>
            </div>


            <div class="col-24 form-check mb-4 mt-5">
              <input class="form-check-input" type="checkbox"  id="newsletterCheck">
              <label class="form-check-label" for="newsletterCheck">
                Szeretnék hírlevelet kapni az akciókról, újdonságokról.
              </label>
            </div>


          </div>

          <div class="d-flex justify-content-sm-end mt-5 mt-sm-0">
            <button matStepperNext class="btn btn-primary text-white flex-grow-1 flex-sm-grow-0"><i class="fa fa-chevron-right"></i> Tovább a szállítási módokhoz</button>
          </div>

        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Szállítás</ng-template>

            <h3>Hova szállítsuk a terméket?</h3>

          <div class="row g-4 mt-2">

            <div class="col-lg-12">
              <div class="form-floating">
                <select class="form-select" aria-label="ország" id="countryInput">
                  <option value="hu">Magyarország</option>
                  <option value="sk">Szlovákia</option>
                  <option value="ro">Románia</option>
                </select>
                <label for="countryInput">Szállítási módok az országodban</label>
              </div>
            </div>

            <div class="col-lg-12">
              <div class="form-floating">
                <input type="text" class="form-control form-control-sm text-info text-truncate" disabled value="Kovács Béla, kovacs.bela@gmail.com, +3612345678">
                <label><i class="fa fa-pencil"></i> Elérhetőség</label>
              </div>
            </div>
          </div>

          <div class="col-24 pt-4">

            <accordion [isAnimated]="true" [closeOthers]="oneAtATime">
              <accordion-group  [panelClass]="customAccordion" [(isOpen)]="isOpen[0]" [isDisabled]="isDisabled[0]" (isOpenChange)="isOpenChange($event, 0)">

              <div class="w-100 shadow-none pt-4"  accordion-heading (click)="deliveryCheck1()">
                <span class="badge badge-secondary bg-info float-right pull-right">Ingyenes</span>
                <div class="form-check mb-4 pull-left float-left">
                  <input class="form-check-input" type="checkbox" id="delivery1" [checked]="deliveryIsChecked1">
                  <label class="form-check-label" for="delivery1">
                    Átvétel GLS csomagpontban
                  </label>
                </div>
              </div>
                <img src="/assets/images/gls-csomagpont-logo.png" alt="GLS csomagpont" class="pull-right">
                <p>Kérheti csomagját az ország minden részében elérhető GLS csomagpontra is. A megérkezéstől számított 5 nap áll rendelkezésére az átvételre. Felhívjuk figyelmét, hogy az átvételkor a személyazonosságát érvényes személy igazolvánnyal, vagy útlevéllel kell igazolnia.</p>
                <p>Kérjük, válassza ki az Önhöz legközelebbi csomagpontot a térkép segítségével <span (click)="openGlsParcelshopMap()">ide kattintva.</span></p>

                <div id="glsparcelshopmap_container" style="padding: 10px 0px;">
                  <button id="searchbutton" class="button btn btn-sm btn-info text-white">
                    <span>Keresés</span>
                  </button>
<!--                  <div id="glsparcelshopmap" style="width: 600px; height: 400px;"></div>-->
                </div>
              </accordion-group>
              <accordion-group [panelClass]="customAccordion" [(isOpen)]="isOpen[1]" [isDisabled]="isDisabled[1]" (isOpenChange)="isOpenChange($event, 1)">
                <div class="w-100 shadow-none pt-4"  accordion-heading (click)="deliveryCheck2()">
                  <span class="badge badge-secondary bg-info float-right pull-right">2 500 Ft</span>
                  <div class="form-check mb-4 pull-left float-left w-75">
                    <input class="form-check-input" type="checkbox" id="delivery2" [checked]="deliveryIsChecked2">
                    <label class="form-check-label" for="delivery2">
                      Házhozszállítás GLS futárszolgálattal magyarországi címre<br>
                      <span class="form-text">Fizetés internetes bankkártyás fizetéssel, vagy Paypal felületen, vagy a csomag átvételekor</span>
                    </label>

                  </div>

                </div>
                <img src="/assets/images/gls-logo.png" alt="GLS" class="pull-right">
                <p>Csomagját a GLS futárszolgálat munkatársai fogják kiszállítani Önnek a megrendelés beérkezését követő 1-3 munkanapon belül. A 17.000 Ft alatti rendelések szállítási díja 1.950 Ft. A 17.000 Ft feletti rendelések szállítási díját mi fizetjük Ön helyett. Az árak tartalmazzák az áfa értékét. Választható fizetési módok:
                </p>
              <ul>
                  <li>fizetés a csomag átvételekor (utánvét)</li>
                  <li>internetes bankkártyás előrefizetés</li>
                  <li>internetes Paypal előrefizetés</li>
              </ul>

              <div class="row g-3 pb-4">
                <div class="col-24 col-sm-7 col-lg-10 col-xxl-8">
                  <div class="form-floating">
                    <input type="text" class="form-control" id="deliveryZip" placeholder="Irányítószám">
                    <label for="deliveryZip">Irányítószám</label>
                  </div>
                </div>

                <div class="col-24 col-sm-17 col-lg-14 col-xxl-16">
                  <div class="form-floating">
                    <input type="text" class="form-control" id="deliveryCity" placeholder="Város">
                    <label for="deliveryCity">Város</label>
                  </div>
                </div>

                <div class="col-24 col-md-14 col-lg-13 col-xl-14">
                  <div class="form-floating">
                    <input type="text" class="form-control" id="deliveryAddress" placeholder="Cím (utca, házszám)">
                    <label for="deliveryAddress">Cím (utca, házszám)</label>
                  </div>
                </div>

                <div class="col-24 col-md-10 col-lg-11 col-xl-10">
                  <div class="form-floating">
                    <input type="text" class="form-control" id="additionalAddress" placeholder="Emelet, ajtó, egyéb (opcionális)">
                    <label for="additionalAddress">Emelet, ajtó, egyéb (opcionális)</label>
                  </div>
                </div>

              </div>


              </accordion-group>
              <accordion-group [panelClass]="customAccordion" [(isOpen)]="isOpen[2]" [isDisabled]="isDisabled[2]" (isOpenChange)="isOpenChange($event, 2)">
                <div class="w-100 shadow-none pt-4"  accordion-heading  (click)="deliveryCheck3()">
                  <span class="badge badge-secondary bg-info float-right pull-right">Ingyenes</span>
                  <div class="form-check mb-4 pull-left float-left w-75">
                    <input class="form-check-input" type="checkbox" id="delivery3" [checked]="deliveryIsChecked3" (click)="deliveryIsChecked3 ? $event.preventDefault() : null">
                    <label class="form-check-label" for="delivery3">
                      Házhozszállítás GLS futárszolgálattal magyarországi címre<br>
                      <span class="form-text">Fizetés banki utalással</span>
                    </label>

                  </div>

                </div>
                <img src="/assets/images/gls-logo.png" alt="GLS" class="pull-right">
                <p>A banki utalásos fizetési mód esetén egy kedvezőbb árfekvésű szállítást tudunk Önnek ajánlani. Ebben az esetben a bruttó 17.000 Ft alatti rendelések szállítási díja  bruttó 1.950 Ft helyett csak 1.590 Ft. A bruttó 17.000 Ft feletti rendelések szállítási díját pedig mi álljuk Ön helyett.</p>
                <p>Az összeg számlánkra érkezését követő 1-3 munkanapon belül  a csomagját a GLS futárszolgálat munkatársai fogják kiszállítani.</p>


                <div class="row g-3 pb-4">
                  <div class="col-24 col-sm-7 col-lg-10 col-xxl-8">
                    <div class="form-floating">
                      <input type="text" class="form-control" id="deliveryZip" placeholder="Irányítószám">
                      <label for="deliveryZip">Irányítószám</label>
                    </div>
                  </div>

                  <div class="col-24 col-sm-17 col-lg-14 col-xxl-16">
                    <div class="form-floating">
                      <input type="text" class="form-control" id="deliveryCity" placeholder="Város">
                      <label for="deliveryCity">Város</label>
                    </div>
                  </div>

                  <div class="col-24 col-md-14 col-lg-13 col-xl-14">
                    <div class="form-floating">
                      <input type="text" class="form-control" id="deliveryAddress" placeholder="Cím (utca, házszám)">
                      <label for="deliveryAddress">Cím (utca, házszám)</label>
                    </div>
                  </div>

                  <div class="col-24 col-md-10 col-lg-11 col-xl-10">
                    <div class="form-floating">
                      <input type="text" class="form-control" id="additionalAddress" placeholder="Emelet, ajtó, egyéb (opcionális)">
                      <label for="additionalAddress">Emelet, ajtó, egyéb (opcionális)</label>
                    </div>
                  </div>

                </div>

              </accordion-group>
            </accordion>

            <div class="d-flex justify-content-sm-end mt-5">
              <button matStepperNext class="btn btn-primary text-white flex-grow-1 flex-sm-grow-0"><i class="fa fa-chevron-right"></i> Tovább az fizetéshez</button>
            </div>

          </div>


<!--          <p>You are now done.</p>-->
<!--          <div>-->
<!--            <button mat-button matStepperPrevious>{{'checkout.back' | translate}}</button>-->
<!--            <button mat-button (click)="stepper.reset()">Reset</button>-->
<!--          </div>-->
        </mat-step>
        <mat-step [editable]="true">

          <div class="row g-4 mt-2">

            <div class="col-lg-12">
                <div class="form-floating">
                  <input type="text" class="form-control form-control-sm text-info text-truncate" disabled value="Kovács Béla, kovacs.bela@gmail.com, +3612345678">
                  <label><i class="fa fa-pencil"></i> Elérhetőség</label>
                </div>
            </div>

            <div class="col-lg-12">
              <div class="form-floating">
                <input type="text" class="form-control form-control-sm text-info text-truncate" disabled  value="Házhozszállítás GLS futárszolgálattal magyarországi címre - Magyarország 2051 Biatorbágy Arany J. u. 4/A">
                <label><i class="fa fa-pencil"></i> Szállítás</label>
              </div>
            </div>
          </div>

          <h3 class="mt-5">Válassz fizetési módot</h3>


          <ng-template matStepLabel>Fizetés</ng-template>
          <form [formGroup]="fullForm">

            <div class="pt-4">

              <accordion [isAnimated]="true" [closeOthers]="oneAtATime">
                <accordion-group [panelClass]="customAccordion">
                  <div class="w-100 shadow-none pt-4"  accordion-heading>
                    <span class="badge badge-secondary bg-info pull-right">Ingyenes</span>
                    <div class="form-check mb-4 pull-left w-75">
                      <input class="form-check-input" type="checkbox" id="payment1">
                      <label class="form-check-label" for="payment1">
                        Utánvétel - Fizetés a csomag átvételekor
                      </label>

                    </div>

                  </div>
                  <i class="fa fa-3x fa-credit-card me-4 pull-right" tooltip="Kártyás fizetés" [adaptivePosition]="false" placement="bottom" [delay]="1000"></i> <i class="fa fa-3x fa-money pull-right" tooltip="Készpénzes fizetés" [adaptivePosition]="false" placement="bottom" [delay]="1000"></i>
                  <p>Házhoz szállítás esetén a számla végösszegét a csomag átvételekor a GLS futárszolgálat munkatársának kell megfizetni. Amennyiben a csomagot GLS csomagpontba kérte, a csomagpont  kiválasztásakor információt kap arról, hogy lehetséges - e a csomagpontban a kártyás fizetés.
                  </p>

                </accordion-group>
                <accordion-group [panelClass]="customAccordion">
                  <div class="w-100 shadow-none pt-4" accordion-heading>
                    <span class="badge badge-secondary bg-info pull-right">Ingyenes</span>
                    <div class="form-check mb-4 pull-left w-75">
                      <input class="form-check-input" type="checkbox" id="payment2">
                      <label class="form-check-label" for="payment2">
                        Bankkártyás fizetés
                      </label>

                    </div>

                  </div>
                  <i class="fa fa-3x fa-credit-card me-4 pull-right" tooltip="Kártyás fizetés" [adaptivePosition]="false" placement="bottom" [delay]="1000"></i>
                  <p>Az internetes bankkártyás fizetés során a fizetési folyamat végén átirányítjuk az OTP Bank internetes fizetési oldalára, majd a fizetés végén visszairányítjuk webáruházunkba.  Használható kártyatípusok: MasterCard , Maestro, Visa

                    </p>
                  <p class="p-2 p-lg-3 text-info border border-info"><i class="fa fa-lg fa-exclamation-triangle text-danger"></i> Felhívjuk figyelmét, hogy az internetes bankkártyás fizetés esetén kötelező a kétlépcsős azonosítás elvégzése. Ezt SMS-ben kapott kód megadásával, vagy mobilaplikáció segítségével tudja megtenni. Ennek részleteiről a bankkártyát kibocsátó pénzintézet tud bővebb felvilágosítást adni.</p>

                </accordion-group>
                <accordion-group [panelClass]="customAccordion">
                  <div class="w-100 shadow-none pt-4" accordion-heading>
                    <span class="badge badge-secondary bg-info pull-right">Ingyenes</span>
                    <div class="form-check mb-4 pull-left w-75">
                      <input class="form-check-input" type="checkbox" id="payment3">
                      <label class="form-check-label" for="payment3">
                        PayPal fizetés
                      </label>

                    </div>

                  </div>
                  <i class="fa fa-3x fa-cc-paypal me-4 pull-right" tooltip="Paypal fizetés" [adaptivePosition]="false" placement="bottom" [delay]="1000"></i>
                  <p>Fizetés a világ egyik legnagyobb - eredetileg - kifejezetten internetes fizetésre szakosodott online felületén. Hogy mennyire biztonságos? 2015 elején több mint 165 millió regisztrált felhasználója volt mintegy 200 országban. A PayPal nem elektronikus bank, hanem egy biztonságos internetes pénzügyi “közvetítő”. A fizetés pár kattintással elvégezhető a PayPal saját internetes felületén.</p>

                </accordion-group>
              </accordion>

            <h3 class="mt-5 pt-3">Számlázási cím</h3>

            <div class="form-check mb-4">
              <input class="form-check-input" type="checkbox" id="companyCheck" [checked]="companyChecked" (click)="companyCheck()">
              <label class="form-check-label" for="companyCheck">
                Cégként vásárolok
              </label>
            </div>

            <div class="row g-4 mb-3">

              <div class="col-24">
                <accordion [isAnimated]="true">

                  <accordion-group [isOpen]="!companyChecked" [panelClass]="customCompanyPanelClass">
                    <div class="form-floating mb-2">
                      <input type="text" class="form-control" id="nameInput" placeholder="Név">
                      <label for="nameInput">Név</label>
                    </div>
                  </accordion-group>

                  <accordion-group [isOpen]="companyChecked" heading="Cégadatok" [panelClass]="customCompanyPanelClass">
                    <div class="row g-4">
                      <div class="col-lg-24 col-xl-12">
                        <div class="form-floating mb-1">
                          <input type="text" class="form-control" id="companyNameInput" placeholder="Cégnév">
                          <label for="companyNameInput">Cégnév</label>
                        </div>
                      </div>

                      <div class="col-lg-12 col-xl-6">
                        <div class="form-floating">
                          <select class="form-select" aria-label="adószám" id="taxInput">
                            <option value="hu">Magyar adószám</option>
                            <option value="eu">EU adószám</option>
                          </select>
                          <label for="taxInput">Adószám típus</label>
                        </div>
                      </div>

                      <div class="col-lg-12 col-xl-6">
                        <div class="form-floating">
                          <input type="text" class="form-control" id="taxNumber" placeholder="Adószám">
                          <label for="taxNumber">Adószám</label>
                        </div>
                      </div>

                    </div>

                  </accordion-group>
                </accordion>
              </div>

              <div class="col-sm-8 col-lg-7 col-xl-8">
                <div class="form-floating">
                  <select class="form-select" aria-label="ország" id="countryInput">
                    <option value="hu">Magyarország</option>
                    <option value="sk">Szlovákia</option>
                    <option value="ro">Románia</option>
                  </select>
                  <label for="countryInput">Ország</label>
                </div>
              </div>

              <div class="col-24 col-sm-6 col-lg-6 col-xl-4">
                <div class="form-floating">
                  <input type="text" class="form-control" id="billingZip" placeholder="Irányítószám">
                  <label for="billingZip">Irányítószám</label>
                </div>
              </div>

              <div class="col-24 col-sm-10 col-lg-11 col-xl-12">
                <div class="form-floating">
                  <input type="text" class="form-control" id="billingCity" placeholder="Város">
                  <label for="billingCity">Város</label>
                </div>
              </div>

              <div class="col-24 col-md-14 col-lg-13 col-xl-14">
                <div class="form-floating">
                  <input type="text" class="form-control" id="billingAddress" placeholder="Cím (utca, házszám)">
                  <label for="billingAddress">Cím (utca, házszám)</label>
                </div>
              </div>

              <div class="col-24 col-md-10 col-lg-11 col-xl-10">
                <div class="form-floating">
                  <input type="text" class="form-control" id="additionalBillingAddress" placeholder="Emelet, ajtó, egyéb (opcionális)">
                  <label for="additionalBillingAddress">Emelet, ajtó, egyéb (opcionális)</label>
                </div>
              </div>
            </div>

              <div class="d-flex justify-content-sm-end mt-5">
                <button matStepperNext class="btn btn-primary text-white flex-grow-1 flex-sm-grow-0"><i class="fa fa-chevron-right"></i> Tovább az összegzéshez</button>
              </div>

            </div>



    </form>



<!--            <div class="container">-->
<!--              <div class="row justify-content-around mb-5">-->
<!--                <div class="col-8">-->
<!--                  <button class="btn btn-outline-primary col-24" (click)="isCompany = false;" [ngClass]="!isCompany ? 'active' : ''">{{ 'checkout.privatePerson' | translate}}</button>-->
<!--                </div>-->
<!--                <div class="col-8">-->
<!--                  <button class="btn btn-outline-primary col-24" (click)="isCompany = true;" [ngClass]="isCompany ? 'active' : ''">{{ 'checkout.companySelector' | translate}}</button>-->
<!--                </div>-->
<!--              </div>-->




<!--              <h2 class="mt-5 mb-0">Számlázási cím</h2>-->
<!--              <div class="row">-->
<!--                <ng-container *ngTemplateOutlet="addressTemplate; context: {form: fullForm.get('address')}"></ng-container>-->
<!--              </div>-->



<!--              <h2 class="mt-5" *ngIf="isCompany">Cég adatok</h2>-->
<!--              <div class="row mb-5 mt-5" *ngIf="isCompany" [formGroupName]="'company'">-->
<!--&lt;!&ndash;                <div class="col-12">&ndash;&gt;-->
<!--&lt;!&ndash;                  <input class="col-24 form-control" type="text" name="companyName" formControlName="companyName" [placeholder]="'company.companyName' | translate">&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="col-12">&ndash;&gt;-->
<!--&lt;!&ndash;                  <input class="col-24 form-control" type="text" name="taxNumber" formControlName="taxNumber" [placeholder]="'company.taxNumber' | translate">&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->

<!--                <ng-container *ngTemplateOutlet="addressTemplate; context: {form: fullForm.get('company').get('address')}"></ng-container>-->
<!--              </div>-->

<!--              <div class="row ms-5 mt-5 mb-5">-->
<!--                <div>-->
<!--                  <label>{{'checkout.isDeliveryAddressTheSame' | translate}}</label>-->
<!--                  <input type="checkbox" (ngModelChange)="changeValue($event)" [ngModel]="isDeliveryAddressTheSame" [ngModelOptions]="{standalone: true}" [placeholder]="'checkout.isDeliveryAddressTheSame' | translate">-->
<!--                </div>-->

<!--              </div>-->

<!--              <div class="mt-3" *ngIf="!isDeliveryAddressTheSame">-->
<!--                <h2>Szállítási cím</h2>-->
<!--                <div class="row">-->
<!--                  <ng-container *ngTemplateOutlet="addressTemplate; context: {form: fullForm.get('additionalAddress')}"></ng-container>-->
<!--                </div>-->
<!--              </div>-->

<!--              <div class="row justify-content-center mb-5 mt-5">-->
<!--                <button (click)="continue()" class="btn btn-primary">{{'continue' | translate}}</button>-->
<!--              </div>-->
<!--            </div>-->
<!--          </form>-->
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Összegzés</ng-template>
          aaa
        </mat-step>
      </mat-horizontal-stepper>

    </div>

    <div class="col-24 col-lg-10">

      <app-checkout-shopping-cart></app-checkout-shopping-cart>


    </div>
  </div><!-- row END -->
</div><!-- container-fluid END -->



<ng-template #addressTemplate let-form="form">
  <div class="col-24 flex-column mt-5" [formGroup]="form">
    <div class="row">
      <div class="col-12">
        <input type="text" class="col-24 form-control" name="country" formControlName="country" [placeholder]="'address.country' | translate">
      </div>
      <div class="col-12 d-flex ps-0 pe-0">
        <div class="col-10">
          <input type="text" class="form-control" name="postalCode" formControlName="postalCode" [placeholder]="'address.postalCode' | translate">
        </div>
        <div class="col-14 ps-1">
          <input type="text" class="form-control" name="city" formControlName="city" [placeholder]="'address.city' | translate">
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-24">
        <input type="text" class="col-24 form-control" name="address" formControlName="address" [placeholder]="'address.address' | translate">
      </div>
    </div>
  </div>
</ng-template>


