import { Action } from '@ngrx/store';
import { Profile } from '../../model/Profile';
import { Order } from '../../model/Order';
import { UserReview } from '../../model/UserReview';
import { User } from '../../model/User';
import { Address } from '../../model/Address';
import { PersonalData } from '../../model/PersonalData';
import { Company } from '../../model/Company';

export enum UserActionTypes {
  Login = 'Log in ',
  Logout = 'Log out ',
  InitProfile = 'Init profile',
  UpdatePersonalData = 'Update personal data',
  UpdateCompanyData = 'Update company data',
  InitOrders = 'Init orders',
  InitRatings = 'Init ratings',
  InitFavouriteProducts = 'Init favourite products',
  AddAddress = 'Add address',
  RemoveAddress = 'Remove address',
  ModifyAddress = 'Modify address',
  AddFavouriteProduct = 'AddFavouriteProduct',
  RemoveFavouriteProduct = 'RemoveFavouriteProduct'
}

export class LoginUser implements Action {
  readonly type = UserActionTypes.Login;

  constructor(public payload: User) {
  }
}

export class LogoutUser implements Action {
  readonly type = UserActionTypes.Logout;

  constructor() {
  }
}

export class InitProfile implements Action {
  readonly type = UserActionTypes.InitProfile;

  constructor(public payload: Profile) {
  }
}

export class AddFavouriteProduct implements Action {
  readonly type = UserActionTypes.AddFavouriteProduct;

  constructor(public payload: number) {
  }
}

export class RemoveFavouriteProduct implements Action {
  readonly type = UserActionTypes.RemoveFavouriteProduct;

  constructor(public payload: number) {
  }
}

export class UpdatePersonalData implements Action {
  readonly type = UserActionTypes.UpdatePersonalData;

  constructor(public payload: PersonalData) {
  }
}

export class UpdateCompanyData implements Action {
  readonly type = UserActionTypes.UpdateCompanyData;

  constructor(public payload: Company) {
  }
}

export class AddAddress implements Action {
  readonly type = UserActionTypes.AddAddress;

  constructor(public payload: Address) {
  }
}

export class DeleteAddress implements Action {
  readonly type = UserActionTypes.RemoveAddress;

  constructor(public payload: number) {
  }
}

export class ModifyAddress implements Action {
  readonly type = UserActionTypes.ModifyAddress;

  constructor(public payload: Address) {
  }
}

export class InitOrders implements Action {
  readonly type = UserActionTypes.InitOrders;

  constructor(public payload: Array<Order>) {
  }
}

// TODO ?!
export class InitRatings implements Action {
  readonly type = UserActionTypes.InitRatings;

  constructor(public payload: Array<UserReview>) {
  }
}

export class InitFavouriteProducts implements Action {
  readonly type = UserActionTypes.InitFavouriteProducts;

  constructor(public payload: Array<number>) {
  }
}

export type UserActions =
  LoginUser |
  LogoutUser |
  InitProfile |
  UpdatePersonalData |
  UpdateCompanyData |
  InitOrders |
  InitRatings |
  InitFavouriteProducts |
  AddAddress |
  DeleteAddress |
  ModifyAddress |
  AddFavouriteProduct |
  RemoveFavouriteProduct;
