<div class="row" [formGroup]="companyForm">
  <div class="col-lg-8 mb-3">
    <div class="form-floating">
      <input class="form-control" id="companyNameInput" type="text" formControlName="name" data-lpignore="true">
      <label for="companyNameInput">Cégnév</label>
    </div>
  </div>

  <div class="col-lg-8">
    <div class="form-floating mb-5">
      <input class="form-control" id="companyTaxnumberInput" type="text" formControlName="taxNumber" data-lpignore="true">
      <label for="companyTaxnumberInput">Adószám</label>
    </div>
  </div>

  <div class="col-lg-8">
    <div class="form-floating mb-5">
      <input class="form-control" id="companyCountryInput" type="text" formControlName="country" data-lpignore="true">
      <label for="companyCountryInput">Ország</label>
    </div>
  </div>


  <div class="col-8 col-lg-4">
    <div class="form-floating mb-5">
      <input class="form-control" id="companyPostalCodeInput" type="text" formControlName="postalCode" data-lpignore="true">
      <label for="companyPostalCodeInput">Irányítószám</label>
    </div>
  </div>

  <div class="col-16 col-lg-6">
    <div class="form-floating mb-5">
      <input class="form-control" id="companyCityInput" type="text" formControlName="city" data-lpignore="true">
      <label for="companyCityInput">Város</label>
    </div>
  </div>

  <div class="col-lg-8">
    <div class="form-floating mb-5">
      <input class="form-control" id="companyAddressInput" type="text" formControlName="address" data-lpignore="true">
      <label for="companyAddressInput">Postacím</label>
    </div>
  </div>


  <div class="col-lg-6">
    <div class="form-floating mb-5">
      <input class="form-control" id="companyPhoneInput" type="text" formControlName="phoneNumber" data-lpignore="true">
      <label for="companyPhoneInput">Telefonszám</label>
    </div>
  </div>

  <div class="col-24">
    <div class="hstack gap-3 d-flex justify-content-center mt-5">
      <button class="btn btn-outline-secondary" (click)="cancel()" [disabled]="!companyForm.dirty">Mégsem</button>
      <button class="btn btn-dark" (click)="save()" [disabled]="companyForm.invalid || !companyForm.dirty">Mentés</button>
    </div>
  </div>


</div>
