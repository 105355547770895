<!--<app-regions-map></app-regions-map>-->

<div class="map-container">

</div>


<div class="container article-detail">
  <div class="mt-5" *ngIf="isFishPage && fishUrls[0] === subMenu && !this.subSubMenu">
    <div class="table-responsive table-scroll-small article-content">
      <table class="table table-striped table-hover">
        <thead>
        <tr>
          <th scope="col">hungarianName</th>
          <th scope="col">latinName</th>
          <th scope="col">englishName</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let f of contentState[menu][subMenu].fishes" [routerLink]="'/halak/halszotar/' + f.ID">
          <td>{{ f.hungarianName }}</td>
          <td>{{ f.latinName }}</td>
          <td>{{ f.englishName }}</td>
        </tr>
        </tbody>
      </table>

    </div>
  </div>
<!--  <div class="mt-5" *ngIf="isFishPage && fishUrls[0] === subMenu && this.subSubMenu && contentState[menu][subMenu].pages[subSubMenu]">-->
  <div class="mt-5" *ngIf="isFishPage && fishUrls[0] === subMenu && this.subSubMenu && contentState[menu][subMenu].selectedFish">
    <a [routerLink]="'/halak/halszotar/'"><button class="btn btn-primary">Vissza</button></a>
    <div class="row article-content">
      <div>
        <h1>{{contentState[menu][subMenu].selectedFish.hungarianName}}</h1>
      </div>
      <div [innerHTML]="contentState[menu][subMenu].selectedFish.description">
      </div>
    </div>
  </div>
<!--  <div class="mt-5" *ngIf="isFishPage && fishUrls[0] === subMenu && subSubMenu">-->
<!--    <div class="table-responsive table-scroll-small article-content">-->
<!--      {{selectedFish}}-->
<!--      {{selectedFish.description}}-->
<!--      {{selectedFish | json}}-->
<!--    </div>-->
<!--  </div>-->


  <div class="mt-5" *ngIf="isFishPage && fishUrls[1] === subMenu">
    <div class="row">
      <div class="col-16 article-content pt-0" *ngIf="contentState[menu][subMenu].selectedRule">
        <h3>
          {{contentState[menu][subMenu].selectedRule.title}}
        </h3>
        <div [innerHTML]="contentState[menu][subMenu].selectedRule.description">

        </div>
      </div>
      <div class="col-6">
        <div class="list-group">
          <ng-container *ngFor="let item of contentState[menu][subMenu].rules">
            <a [routerLink]="'/' + menu + '/' + subMenu + '/' + item.ID" class="list-group-item list-group-item-action" matRipple [matRippleUnbounded]="false"
               [matRippleCentered]="true">{{item.title}}</a>
          </ng-container>

        </div>

      </div>
    </div>
  </div>

  <div class="mt-5" *ngIf="isFishPage && (fishUrls[2] === subMenu || fishUrls[3] === subMenu || fishUrls[4] === subMenu || fishUrls[5] === subMenu)">
    <div class="row article-content" *ngIf="contentState[menu][subMenu].selectedFish">
      <div>
        <h1>{{contentState[menu][subMenu].selectedFish.hungarianName}}</h1>
      </div>
      <div [innerHTML]="contentState[menu][subMenu].selectedFish.description">
      </div>
    </div>
  </div>

  <div class="mt-5" *ngIf="isFishPage && fishUrls[7] === subMenu && !this.subSubMenu">
    <div class="table-responsive table-scroll-small article-content">
      <table class="table table-striped table-hover">
        <thead>
        <tr>
          <th scope="col">hungarianName</th>
          <th scope="col">latinName</th>
          <th scope="col">englishName</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let f of contentState[menu][subMenu].fishes" [routerLink]="'/halak/' + fishUrls[7] + '/' + f.ID">
          <td>{{ f.hungarianName }}</td>
          <td>{{ f.latinName }}</td>
          <td>{{ f.englishName }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="mt-5" *ngIf="isFishPage && fishUrls[7] === subMenu && this.subSubMenu && contentState[menu][subMenu].selectedFish">
    <a [routerLink]="'/halak/' + fishUrls[7]"><button class="btn btn-primary">Vissza</button></a>
    <div class="row article-content">
      <div>
        <h1>{{contentState[menu][subMenu].selectedFish.hungarianName}}</h1>
      </div>
      <div [innerHTML]="contentState[menu][subMenu].selectedFish.description">
      </div>
    </div>
  </div>
</div>
<div class="container water-detail">

<!--  <div class="mt-5" *ngIf="isFishPage && fishUrls[1] === subMenu && selectedRule">-->
<!--    <div>-->
<!--      <h2>{{selectedRule.title}}</h2>-->
<!--      <div [innerHTML]="selectedRule.description">-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

  <div class="mt-5" *ngIf="isWaterPage && showWaterMaps && waterUrls.includes(subMenu) && this.subSubMenu">
    <div class="card">
      <div class="card-header text-center">
        <div>Helyszín: {{selectedWater.megye | county}}</div>
      </div>
      <div class="card-body">
        <h1 [innerHTML]="selectedWater.viznev"></h1>

        <div class="row">
          <div class="col-lg-16 col-xxl-19 water-content">
        <p [innerHTML]="selectedWater.leiras"></p>
          </div>

          <div class="col-lg-8 col-xxl-5 water-aside">

            <div class="list-group">
              <span class="list-group-item active">Víz típusa</span>
              <div class="list-group-item">
                {{selectedWater.viztipus | water}}
              </div>
            </div>


            <div class="list-group">
              <span class="list-group-item active">Vízterület (ha)</span>
              <div class="list-group-item">
                {{selectedWater.vizterulet}}
              </div>
            </div>

            <div class="list-group">
              <span class="list-group-item active">Víztérkód</span>
              <div class="list-group-item">
                <code>{{selectedWater.vizterkod}}</code>
              </div>
            </div>

            <div class="list-group">
              <span class="list-group-item active">Fogható halak</span>
              <div class="list-group-item">
                <span *ngFor="let hf of selectedWater.halfajok; let last = last">{{last ? fishNames[hf] : fishNames[hf] + ', '}}</span>
              </div>
            </div>

            <div class="list-group">
              <span class="list-group-item active">Kezelő</span>
              <div class="list-group-item">
                <p>{{selectedWater.kezelonev}}</p>
                <p>{{selectedWater.kezelocim}}</p>
                <p>{{selectedWater.kezelotelefon}}</p>
                <p>{{selectedWater.kezelowww}}</p>
                <p>{{selectedWater.kezeloemail}}</p>
              </div>
            </div>

          </div>

        </div><!-- row end-->


      </div>
    </div>
  </div>

  <div class="row d-flex justify-content-center mt-5" *ngIf="isWaterPage && waterAllUrls.indexOf(subMenu) === 1">
    <div class="col-xl-19 article-content">
      <h1>Aktuális vízállások</h1>
      <div>
        <p>A vízállás mértéke nagyban befolyásolja a horgászatot.&nbsp;Az enyhe áradás és apadás jobb fogási eredmény hozhat mint a vízszint stagnálása. Áradásnál a part mellett és az apadásnál a mélyebb vizeken érdemes horgászni.&nbsp;Az aktuális vízállás&nbsp;adatok naponta többször&nbsp;frissülnek.&nbsp;Az<strong> LNV</strong> érték&nbsp;a&nbsp;vízmércén a vizsgált évig bezárólag előfordult legmagasabb vízállást mutatja.<br>Adatok forrása: <a href="http://www.vizugy.hu" target="_blank" rel="nofollow">www.vizugy.hu</a></p>
      </div>
    </div>
    <div class="col-xl-19 article-content d-flex justify-content-center">
      <table class="w-100" *ngIf="waterLevels">
        <thead>
        <tr>
          <th>Víznév</th>
          <th class="text-end">Vízállás</th>
          <th class="text-end">Legnagyobb víz (LNV)</th>
          <th class="text-end">Frissítve</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let wl of waterLevels">
          <td>{{wl.name}}</td>
          <td class="text-end">{{wl.level}}</td>
          <td class="text-end">{{wl.lnv}}</td>
          <td class="text-end">{{wl.updated | date:'YYYY.MM.dd hh:mm'}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="container album" *ngIf="isWaterPage">

<!--  <div>-->
<!--    <p>-->
<!--&lt;!&ndash;      Fogható halak: <span *ngFor="let hf of waterModel.halfajok; let last = last">{{last ? fishNames[hf] : fishNames[hf] + ', '}}</span>&ndash;&gt;-->
<!--    </p>-->
<!--  </div>-->

  <div class="row" *ngIf="showWaterMaps && waterUrls.includes(subMenu) && !this.subSubMenu">
    <app-water-card [waterViewType]="0" class="col-6 d-flex" *ngFor="let water of waters" [waterModel]="water" [menu]="menu" [subMenu]="subMenu"></app-water-card>
  </div>




  <div class="row mt-5" *ngIf="!showWaterMaps && waterUrls.includes(subMenu)">
    <app-water-card [waterViewType]="0" class="col-6 mb-5" *ngFor="let water of waters" [waterModel]="water" [menu]="menu" [subMenu]="subMenu"></app-water-card>
  </div>

<!--  <div class="row mt-5" *ngIf="!showWaterMaps && waterUrls.includes(subMenu)">-->
<!--    -->
<!--  </div>-->

  <div *ngIf="waterAllUrls.indexOf(subMenu) === 0">
    <app-regions-map></app-regions-map>
  </div>


</div>

<!--<div class="container article-detail" *ngIf="!isWaterPage">-->

<!--  <div class="row">-->
<!--    <div class="col-xl-19 article-content">-->
<!--      <div *ngIf="content">-->
<!--        <h1>{{content.title}}</h1>-->
<!--        <div [innerHTML]="content.content" class="mt-3"></div>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="col-xl-5 aside">-->
<!--      <app-content-navigation-->
<!--        [title]="subMenu" [navigations]="actualNavigations" [menu]="menu" [subMenu]="subMenu">-->
<!--      </app-content-navigation>-->
<!--    </div>-->
<!--  </div>&lt;!&ndash;  row END &ndash;&gt;-->

<!--</div>-->
