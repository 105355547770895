<div class="p-2 d-flex justify-content-between bg-secondary text-white">

  <div>
  <i class="fa fa-2x fa-shopping-cart"></i>
    <sup autofocus class="badge rounded-pill bg-danger badge-amount">{{numberOfProducts}}</sup>
  <span class="ps-5 lead">{{ 'kosár' }}</span>
  </div>

  <div class="pe-2 pt-1">
  <i class="fa fa-2x fa-times" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" (click)="cartSidenav.close()"></i>
  </div>

</div>

<div *ngIf="productsInCart.length > 0" class="sidecart-container">

  <div *ngFor="let p of productsInCart" class="product mt-1">
      <div class="product-image">
        <img src="{{productsById[p.productId].primaryPicture}}" alt="{{productsById[p.productId].name}}">
      </div>

      <div class="product-details">
        <span class="mt-0 mb-0 product-title">{{productsById[p.productId].name}}</span>
        {{p.amount}} x <span class="text-danger">{{productsById[p.productId].grossPriceCarriage || productsById[p.productId].grossPrice}}
        Ft</span>
     </div>

        <div class="product-quantity">
        <div class="btn-group btn-quantity" role="group" aria-label="Mennyiseg">
          <button  type="button" class="btn btn-sm btn-sub" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" tooltip="Elvesz" [delay]="1000"><i class="fa fa-minus"></i></button>
          <input class="form-control form-control-sm price-input" [(ngModel)]="cart[p.productId].amount" type="number" (click)="$event.target.select()" min="1">
          <button type="button" class="btn btn-sm btn-add" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" tooltip="Hozzáad" [delay]="1000"><i class="fa fa-plus"></i></button>
        </div>
        </div>

        <div class="product-removal">
          <i class="fa fa-lg fa-trash" aria-label="Termék törlése gomb"
             (click)="removeProductFromCart(productsById[p.productId].id)" tooltip="Termék törlése" [adaptivePosition]="false" placement="bottom" [delay]="1000"></i>
        </div>



<!--    <div class="media pt-4">-->

<!--      <div class="prod-image-container align-self-start me-3">-->
<!--      <img src="/api/{{productsById[p.productId].primaryPicture}}" alt="{{productsById[p.productId].name}}">-->
<!--      </div>-->

<!--      <div class="media-body">-->

<!--        <div>-->
<!--          <i class="fa fa-lg fa-trash" aria-label="Termék törlése gomb"-->
<!--             (click)="removeProductFromCart(productsById[p.productId].id)" tooltip="Termék törlése" [adaptivePosition]="false" placement="left" [delay]="1000"></i>-->
<!--        </div>-->

<!--        &lt;!&ndash;          <span class="float-right pt-3" (click)="removeProductFromCart(productsById[p.productId].articleNumber)" tooltip="Termék törlése" [delay]="1000"><i class="fa fa-trash text-danger"></i></span>&ndash;&gt;-->
<!--        -->
<!--        <h5 class="mt-0">{{productsById[p.productId].name}}</h5>-->
<!--&lt;!&ndash;        <p>Hossz m 20 / Mérete 50</p>&ndash;&gt;-->
<!--        {{p.amount}} x <span class="text-danger">{{productsById[p.productId].grossPriceCarriage || productsById[p.productId].grossPrice}}-->
<!--        Ft</span>-->



<!--        <div class="btn-group btn-quantity" role="group" aria-label="Mennyiseg">-->
<!--          <button  type="button" class="btn btn-sub" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" tooltip="Elvesz" [delay]="1000"><i class="fa fa-minus"></i></button>-->
<!--          <input class="form-control form-control-sm price-input" [(ngModel)]="cart[p.productId].amount" type="number" (click)="$event.target.select()" min="1">-->
<!--          <button type="button" class="btn btn-add" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" tooltip="Hozzáad" [delay]="1000"><i class="fa fa-plus"></i></button>-->
<!--        </div>-->



<!--      </div>-->

<!--    </div>&lt;!&ndash; media END&ndash;&gt;-->
  </div><!-- ngif END-->

  <div class="sidecart-bottom fixed-bottom">
    <hr>
    <div class="d-flex justify-content-between w-100">
      <div class="lead">Részösszeg:</div>
      <div class="text-danger">26 782 Ft</div>
    </div>

    <button class="btn btn-secondary btn-rounded w-100 text-white" *ngIf="numberOfProducts > 0" (click)="navigateToCheckout()">
      <i class="fa fa-chevron-right"></i> {{'cart.toCheckout' | translate}}
    </button>

  </div>

<!--  <div *ngIf="(productsInCart | keyvalue).length === 0">-->
<!--    {{"cart.empty" | translate}}-->
<!--  </div>-->

</div>
<!--sidecart-container END-->

