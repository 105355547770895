<h3>Értékeléseim</h3>

<!--<div class="rates-container">-->


<!--&lt;!&ndash;  EZ A MINTA! &#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45; &ndash;&gt;-->

<!--&lt;!&ndash;  RATING START&ndash;&gt;-->
<!--  <div class="row">-->
<!--    <div class="col-sm-8 col-lg-6 justify-content-center">-->
<!--        <img src="/assets/images/product/k-karp_019-25-904_parent_drake_xtr_bite_alarm_1.jpg" alt="product" class="img-fluid">-->
<!--    </div>-->
<!--  <div class="col">-->
<!--      <h3 class="mt-0 mb-0 product-title">K-Karp Drake XTR Stiff Hanger swinger</h3>-->
<!--      <ngx-input-star-rating [disabled]="true" [value]="4"></ngx-input-star-rating>-->
<!--      <p class="text-muted">1 értékelés</p>-->
<!--      <div class="product-description">-->
<!--        Nehéz az orsó, de ennek tudatában vásároltam. Egyenlőre teljes mértékben megvagyok elégedve vele. Nagyon szép a zsinorképe. Bitang erős a féke.-->
<!--      </div>-->
<!--    <p class="card-text"><small class="text-muted">2022.04.18.</small></p>-->
<!--  </div>-->
<!--  </div>-->
<!--  &lt;!&ndash;  RATING END&ndash;&gt;-->


<!--  &lt;!&ndash;  RATING START&ndash;&gt;-->
<!--  <div class="row">-->
<!--    <div class="col-sm-8 col-lg-6 d-flex justify-content-center">-->
<!--      <img src="/assets/images/product/k-karp_019-25-950_parent_gladio_tx_micro_bite_indicator_set.jpg" alt="product" class="img-fluid">-->
<!--    </div>-->
<!--    <div class="col">-->
<!--      <span class="mt-0 mb-0 product-title">K-Karp Drake XTR Stiff Hanger swinger</span>-->
<!--      <ngx-input-star-rating [disabled]="true" [value]="2" class="stars"></ngx-input-star-rating>-->
<!--      <p class="text-muted">125 értékelés</p>-->
<!--      <div class="product-description">-->
<!--        Nem találtam ebben az árkategóriában ehhez hasonló tudású orsót. Gyönyörű zsinórkép, akadálymentes futás, könnyedén lehet vele nagy távolságra dobni. Nem utolsósorban mutatós darab.-->
<!--      </div>-->
<!--      <p class="card-text"><small class="text-muted">2022.04.18.</small></p>-->
<!--    </div>-->
<!--  </div>-->
<!--  &lt;!&ndash;  RATING END&ndash;&gt;-->
<!--</div>-->


<!--MINTA VÉGE -------------------------------------------------------- -->


<!--&lt;!&ndash;  RATING START&ndash;&gt;-->
<!--<div class="row" *ngFor="let rating of ratings; let last = last;">-->

<!--  <div class="col-sm-8 col-lg-6 d-flex justify-content-center">-->
<!--    <img src="/assets/images/product/k-karp_019-25-950_parent_gladio_tx_micro_bite_indicator_set.jpg" alt="{{rating.product.name}}" class="img-fluid">-->
<!--  </div>-->

<!--  <div class="col">-->
<!--    <span class="mt-0 mb-0 product-title" [routerLink]="'/termek/' + rating.product.articleNumber">{{rating.product.name}}</span>-->
<!--    <ngx-input-star-rating [disabled]="true" [value]="calculateAverageRating(rating.product)" class="mb-2"></ngx-input-star-rating>-->
<!--    <p class="text-muted">{{rating.product.reviews.length}} 1 értékelés</p>-->
<!--    <div class="product-description">-->
<!--      {{rating.review}}-->
<!--    </div>-->
<!--    <p class="card-text"><small class="text-muted">2022.04.18.</small></p>-->
<!--  </div>-->
<!--</div>-->
<!--&lt;!&ndash;  RATING END&ndash;&gt;-->




<!--EZ A REGI --------------------------------------- -->

<!--<div>-->
<!--  <div *ngFor="let rating of ratings; let last = last;">-->
<!--    <mat-divider class="w-50"></mat-divider>-->
<!--    <div class="d-flex mt-3">-->
<!--      <div class="col-2">-->
<!--        <img class="commonListProductPicture" src="/assets/images/product/k-karp_019-25-950_parent_gladio_tx_micro_bite_indicator_set.jpg" alt="">-->
<!--      </div>-->
<!--      <div class="col-12 mt-2">-->
<!--        <a [routerLink]="'/termek/' + rating.product.articleNumber">{{rating.product.name}}</a>-->
<!--        <div class="d-flex align-items-center">-->
<!--          <ngx-input-star-rating [disabled]="true" [value]="calculateAverageRating(rating.product)" class="mb-2"></ngx-input-star-rating>-->
<!--          <span>{{rating.product.reviews.length}} Értékelés</span>-->
<!--        </div>-->
<!--        <div>-->
<!--          {{rating.review}}-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <mat-divider class="w-50" *ngIf="last"></mat-divider>-->
<!--  </div>-->
<!--</div>-->


<div>
  <div *ngFor="let rating of ratings; let last = last;">
    <div class="row">
      <div class="col-sm-8 col-lg-6 d-flex justify-content-center">
        <img src="{{rating.product.primaryPicture}}" alt="product" class="img-fluid">
      </div>
      <div class="col">
        <span class="mt-0 mb-0 product-title"><a [routerLink]="'/termek/' + rating.product.articleNumber">{{rating.product.name}}</a></span>
        <ngx-input-star-rating [disabled]="true" [value]="rating.rating" class="stars"></ngx-input-star-rating>
        <div class="product-description">
          {{rating.review}}
        </div>
        <p class="card-text"><small class="text-muted">{{rating.creationDate | date}}</small></p>
      </div>
    </div>
  </div>
</div>
