import { Component, OnInit } from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-missing-product',
  templateUrl: './missing-product.component.html'
})
export class MissingProductComponent implements OnInit {

  constructor(public modalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  cancelMissingProcuctModal() {
    this.modalRef.hide();
    // this.deliveryModalRef.hide();
    // this.deliveryModalRef = null;
  }

}
