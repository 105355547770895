import { Injectable } from '@angular/core';
import { Product } from '../model/Product';
import { ProductKey } from '../model/ProductKey';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  static convertProductsToJson(products: Array<Product>): ProductKey {
    const json = {};
    products.forEach(p => json[p.id] = p);
    return json;
  }

  static passwordsMatched(firstPasswordLabel: string, secondPasswordLabel: string) {
    return (form: FormGroup): {[key: string]: boolean} | null => {
      const newPassword = form.controls[firstPasswordLabel];
      const newPasswordAgain = form.controls[secondPasswordLabel];
      if (newPassword.value !== newPasswordAgain.value) {
        return {
          passwordsMatched: false
        }
      }
      return null;
    };
  }

  static convertProductsToJsonByArticleNumber(products: Array<Product>): {[k: string]: Product} {
    const json = {};
    products.forEach(p => {
      json[p.articleNumber] = p
      if (p.variations && p.variations.length > 0) {
        p.variations.forEach(pv => {
          json[pv.articleNumber] = pv;
        });
      }
    });

    return json;
  }

  static isNotUndefinedOrNull(value: any) {
    return value !== undefined && value !== null;
  }
}
