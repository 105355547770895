import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { OrderByProperty } from '../../../../model/OrderByProperty';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OrderComponent),
      multi: true
    }
  ]
})
export class OrderComponent implements OnInit, ControlValueAccessor {

  @Input()
  values: Array<OrderByProperty>;

  @Input()
  initialValue: string;

  model: OrderByProperty;

  propagateChange: any = () => {};

  constructor() { }

  ngOnInit(): void {
    if (this.initialValue) {
      this.model = this.values.find(v => v.link === this.initialValue);
      if (!this.model) {
        this.model = this.values[0];
        this.propagateChange(this.model.link);
      }
    } else {
      this.model = this.values[0];
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(obj: OrderByProperty): void {
    if (obj) {
      this.model = this.values.find(v => v.link === obj.link);
    }
  }

  modelChange(event: MatSelectChange) {
    this.propagateChange(event.value);
  }
}
