import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@Component({
  selector: 'app-regions-map',
  templateUrl: './regions-map.component.html',
  styleUrls: ['./regions-map.component.scss']
})
export class RegionsMapComponent implements OnInit, AfterViewInit {

  @HostListener('document:resize', ['$event'])
  onResize(event) {
    if (this.map) {
      console.log(this.map)
    }
    // event.target.innerWidth;
  }


  ngAfterViewInit(): void {
  }

  @ViewChild('map')
  map: any;

  constructor() { }

  ngOnInit(): void {
  }

  highlightElement(element) {
    console.log(element);
  }

}
