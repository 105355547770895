import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Company } from '../../../model/Company';
import { FormGroup } from '@angular/forms';
import { FormService } from '../../../service/form.service';
import { CompanyService } from '../../../service/company.service';
import { UserState } from '../../../store/user/UserReducer';
import { Store } from '@ngrx/store';
import { UpdateCompanyData } from '../../../store/user/UserActions';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html'
})
export class CompanyComponent implements OnInit, OnChanges {

  @Input()
  company: Company;

  companyForm: FormGroup;

  constructor(private readonly formService: FormService,
              private readonly companyService: CompanyService,
              private readonly toastrService: ToastrService,
              private readonly userStore: Store<UserState>) {
  }

  ngOnInit(): void {
  }

  save() {
    this.companyService.updateCompany(this.companyForm.value).subscribe(() => {
      this.userStore.dispatch(new UpdateCompanyData(this.companyForm.value));
      this.toastrService.success('company.data.updated');
    }, err => {
      console.log(err);
    });
  }

  cancel() {
    this.companyForm = this.formService.getCompanyForm(this.company);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.company) {
      this.companyForm = this.formService.getCompanyForm(this.company);
    }
  }
}
