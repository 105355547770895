import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Address } from '../model/Address';
import { PersonalData } from '../model/PersonalData';
import { Company } from '../model/Company';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor(private fb: FormBuilder) {
  }

  getLoginForm(): FormGroup {
    return this.fb.group({
      ...this.getUsernameFormControl(),
      password: new FormControl('', [Validators.required])
    });
  }

  getRegistrationForm(): FormGroup {
    return this.fb.group({
      ...this.getUsernameFormControl(),
      ...this.getEmailFormControl(),
      ...this.getPasswordForm(),
    }, {validators: [UtilsService.passwordsMatched('password', 'passwordAgain')]});
  }

  getForgetPasswordForm(): FormGroup {
    return this.fb.group({
      ...this.getEmailFormControl()
    });
  }

  getRegistrationPersonalData(): FormGroup {
    return this.fb.group({
      ...this.getUsernameFormControl(),
      ...this.getEmailFormControl(),
      // email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(50)])
    });
  }

  getCheckoutForm(): FormGroup {
    return this.fb.group({
      personalData: this.getPersonalDataForm(),
      company: this.getCompanyForm(),
      address: this.getAddressForm(),
      additionalAddress: this.getAddressForm()
    });
  }

  getPasswordForm() {
    return {
      password: new FormControl('', [Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d\\W]{8,63}$')]),
      passwordAgain: new FormControl('', [Validators.required])
    };
  }

  getAddressForm(address?: Address): FormGroup {
    return this.fb.group({
      id: new FormControl({value: address ? address.id : '', disabled: true}),
      ...this.getAddressFormControls(address)
    });
  }

  getPersonalDataForm(personalData?: PersonalData): FormGroup {
    return this.fb.group({
      name: new FormControl(personalData ? personalData.name : '', [Validators.required, Validators.minLength(2), Validators.maxLength(30)]),
      // email: new FormControl(personalData ? personalData.email : '', [Validators.required]),
      ...this.getEmailFormControl(personalData?.email),
      ...this.getPhoneNumberFormControl(personalData ? personalData.phoneNumber : '')
    });
  }

  getCompanyForm(company?: Company): FormGroup {
    return this.fb.group({
      name: new FormControl(company ? company.name : '', [Validators.required, Validators.minLength(2), Validators.maxLength(30)]),
      taxNumber: new FormControl(company ? company.taxNumber : '', [Validators.required, Validators.minLength(2), Validators.maxLength(30)]),
      ...this.getAddressFormControls(company)
    });
  }

  getReviewForm(productId: number) {
    return this.fb.group({
      productId: new FormControl({value: productId, disabled: true}),
      rating: new FormControl(null, Validators.required),
      review: new FormControl('', Validators.maxLength(500))
    });
  }

  getAddressFormControls(address?: Address | Company) {
    return {
      country: new FormControl(address ? address.country : '', [Validators.required, Validators.minLength(2), Validators.maxLength(30)]),
      postalCode: new FormControl(address ? address.postalCode : '', [Validators.required, Validators.minLength(2), Validators.maxLength(30)]),
      city: new FormControl(address ? address.city : '', [Validators.required, Validators.minLength(2), Validators.maxLength(30)]),
      address: new FormControl(address ? address.address : '', [Validators.required, Validators.minLength(2), Validators.maxLength(30)]),
      ...this.getPhoneNumberFormControl(address ? address.phoneNumber : '')
    }
  }

  getPhoneNumberFormControl(phoneNumber: string) {
    return {
      phoneNumber: new FormControl(phoneNumber, [Validators.required])
    }
  }

  getUsernameFormControl(username?: string) {
    return {
      username: new FormControl(username ? username : '', [Validators.required, Validators.minLength(3), Validators.maxLength(12)])
    };
  }

  getEmailFormControl(email?: string) {
    return {
      email: new FormControl(email ? email : '', [Validators.required, Validators.email, Validators.maxLength(50)])
    };
  }
}
