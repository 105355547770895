<div *ngIf="productsInCart.length > 0" class="check-out">

  <section class="cart-checkout">
    <div class="d-flex justify-content-between">
    <h3 class="mb-0 mb-sm-5">Rendelés összesítés</h3>
    <button class="btn btn-sm btn-outline-info btn-rounded" (click)="cartClicked.emit()"><i class="fa fa-shopping-cart"></i> Kosár szerkesztése</button>
    </div>
    <hr>

      <div class="shopping-cart">

        <div *ngFor="let p of productsInCart" class="pt-4">
        <div class="product">
          <div class="product-image">
            <div class="product-image-container">
            <img src="{{productsById[p.productId].primaryPicture}}" alt="{{productsById[p.productId].name}}">
            </div>
          </div>
          <div class="product-details">
              <span class="mt-0 mb-0 product-title">{{productsById[p.productId].name}}</span>
              x <span class="text-danger">{{productsById[p.productId].grossPriceCarriage || productsById[p.productId].grossPrice}}
                Ft</span>
          </div>

          <div class="product-line-price text-nowrap">3360 Ft</div>
        </div>
        </div>

        <div class="totals">
          <div class="totals-item">
            <label>Összeg:</label>
            <div class="totals-value text-nowrap" id="cart-subtotal" style="">36 360 Ft</div>
          </div>
        </div>
      </div>
  </section>

</div>





