import { Action } from '@ngrx/store';

export class ContentPayload {
  path: string;
  menu: string;
  content: any;

  // [k: string]: any;
}

export class SubPageContentPayload extends ContentPayload {
  id: string;
}


export enum ContentActionTypes {
  InitFish0 = 'InitFish0',
  InitFishRules = 'InitFishRules',
  InitFishRulesAndSelectedRule = 'InitFishRulesAndSelectedRule',
  InitContent = 'InitContent',
  AddSubPage = 'AddSubPage'
}

export class InitFish0 implements Action {
  type = ContentActionTypes.InitFish0;

  constructor(public payload: ContentPayload) {
  }
}

export class InitFishRules implements Action {
  type = ContentActionTypes.InitFishRules;

  constructor(public payload: ContentPayload) {
  }
}

export class InitFishRulesAndSelectedRule implements Action {
  type = ContentActionTypes.InitFishRulesAndSelectedRule;

  constructor(public payload: ContentPayload) {
  }
}

export class InitContent implements Action {
  type = ContentActionTypes.InitContent;

  constructor(public payload: ContentPayload) {
  }
}

export class AddSubPage implements Action {
  type = ContentActionTypes.AddSubPage;

  constructor(public payload: SubPageContentPayload) {
  }
}

export type ContentActions =
  InitFish0 |
  InitFishRules |
  InitFishRulesAndSelectedRule |
  AddSubPage;
