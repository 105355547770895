import { ExternalUserType } from './ExternalUserType';

export class User {
  id: number;
  externalUserId: string;
  externalUserType: ExternalUserType;
  userId: string;
  username: string;
  isFacebookUser: boolean;
  role: string;
  email: string;
  photoUrl: string;
  reviews: Array<number>;
  favouriteProducts: Array<number>;

  constructor(userId: string, username: string, isFacebookUser?: boolean, photoUrl?: string) {
    this.userId = userId;
    this.username = username;
    this.isFacebookUser = isFacebookUser;
    this.photoUrl = photoUrl;
  }
}

export class FacebookUser {
  photoUrl: string;
}
