<div class="search-container">

  <div *ngIf="!(categories.length > 0 || manufacturers.length > 0 || products.length > 0)">
    <div>No result</div>
  </div>

  <div class="row" *ngIf="categories.length > 0 || manufacturers.length > 0 || products.length > 0">
    <dl class="col-md-6 search-arrow">
      <dt>Kategóriák</dt>
      <dd *ngFor="let c of categories" [routerLink]="c.link">{{c.name}}</dd>
    </dl>

    <dl class="col-md-12">
      <dt>Termékek</dt>
      <dd *ngFor="let p of products" class="d-flex" [routerLink]="['termek', p.id]">
        <img src="assets/images/thumbs/k-karp_019-25-950_parent_gladio_tx_micro_bite_indicator_set.jpg"
             class="align-self-center me-3" alt="{{p.name}}">
        {{p.name}}
      </dd>
    </dl>

    <dl class="col-md-6 search-arrow">
      <dt>Gyártók</dt>
      <dd *ngFor="let m of manufacturers" [routerLink]="'kereses'" [queryParams]="{manufacturer: m}">{{m}}</dd>
    </dl>

  </div> <!--    row END-->
</div>
