import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UserReview } from '../model/UserReview';

@Injectable({
  providedIn: 'root'
})
export class RatingService {

  constructor(private http: HttpClient) {
  }

  getReviews(): Observable<Array<UserReview>> {
    // return this.http.get<Array<UserReview>>('/api/review');
    return of([
      {
        rating: 5,
        review: 'Perfect product',
        creationDate: new Date(),
        product: {
          // reviews: [],
          // describe: 'descr',
          // grossPrice: 2.44
          "id": 10585,
          "name": "CONTE TENSION SOFT 20 (1 pair)",
          "link": "conte tension soft 20 (1 pair)",
          "primaryPicture": "https://akciosillat.hu/img/5166/5011417566589/5011417566589.jpg?time=1651315494",
          "brand": "Conte elegant",
          "material": "polyamide 87%, elastane 13%",
          "fullDescription": "Thin elastic socks with a sealed elastic band and toe, for sensitive skin with vitamin E, universal size",
          "grossPrice": 0.76,
          // "countAvailable": 7309,
          "description": "Women's socks TENSION SOFT 20 (1 pair), s. 36-39, natural",
          // "pictures": ["https://conteb2b.com/static/uploads/models_photos/zq/up/yn/ZQUPYNZ6gYsmZrWE.jpg?b701d8ea54d68a11232e2a08771cbdf6"],
          "articleNumber": "14С-56СП",
          reviews: [],
          describe: 'fullDesc'
        }
      }
    ])
  }

  reviewProduct(review: any): Observable<void> {
    return this.http.post<void>('/api/review/add', review);
  }
}
