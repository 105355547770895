<h3>Kedvenceim</h3>

<div class="row favourites-container">
  <div class="col-lg-12 col-xxxl-6 pb-5" *ngFor="let p of products; let last = last">
    <div class="card h-100">
      <div class="row g-0">
        <div class="col-md-8 d-flex align-items-center justify-content-center">
          <img src="{{p.primaryPicture}}" [routerLink]="'/termek/' + p.articleNumber" class="img-fluid rounded-start" alt="{{p.name}}">
        </div>
        <div class="col-md-16">
          <div class="card-body">
            <h5 class="card-title" [routerLink]="'/termek/' + p.articleNumber">{{p.name}}</h5>
            <ngx-input-star-rating [disabled]="true" [value]="calculateAverageRating(p)" class="mb-2"></ngx-input-star-rating>
            <div class="card-text pb-0">
              <span *ngIf="p.reviews.length > 0">{{p.reviews.length}} Értékelés</span>
              <span *ngIf="p.reviews.length === 0" class="text-muted">Nincs értékelés</span>
              <p class="text-end me-2 text-danger"><i (click)="deleteFromFavorites(p.id)" class="fa fa-lg fa-trash" tooltip="Termék törlése a kedvencekből" [delay]="500" [adaptivePosition]="false" placement="end"></i></p>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<!--<div class="row flex-column mt-5" *ngFor="let p of products; let last = last">-->
<!--  <mat-divider class="w-50"></mat-divider>-->
<!--  <div class="d-flex mt-3">-->
<!--    <div class="col-2">-->

<!--      &lt;!&ndash;    <img src="{{p.primaryPicture}}" alt="">&ndash;&gt;-->
<!--&lt;!&ndash;      <img class="commonListProductPicture" src="/assets/images/product/k-karp_019-25-950_parent_gladio_tx_micro_bite_indicator_set.jpg" alt="">&ndash;&gt;-->
<!--&lt;!&ndash;      <img src="/api/{{p.primaryPicture}}" class="card-img-prod" alt="{{p.name}}">&ndash;&gt;-->
<!--      <img src="/api/{{p.primaryPicture}}" class="commonListProductPicture" alt="{{p.name}}">-->
<!--    </div>-->
<!--    <div class="col-12 mt-2">-->
<!--      <a [routerLink]="'/termek/' + p.articleNumber">{{p.name}}</a>-->
<!--      <div class="d-flex align-items-center">-->
<!--        <ngx-input-star-rating [disabled]="true" [value]="calculateAverageRating(p)" class="mb-2"></ngx-input-star-rating>-->
<!--        <span *ngIf="p.reviews.length > 0">{{p.reviews.length}} Értékelés</span>-->
<!--        <span *ngIf="p.reviews.length === 0">Nincs értékelés</span>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div>-->
<!--      <ng-content></ng-content>-->
<!--    </div>-->
<!--  </div>-->
<!--  <mat-divider class="w-50" *ngIf="last"></mat-divider>-->
<!--</div>-->

