import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { ContentService } from '../modules/content/content.service';
import { ContentState } from '../store/content/ContentReducer';
import { Store } from '@ngrx/store';
import { selectContentState } from '../store/content/ContentSelector';
import { AddSubPage, InitContent } from '../store/content/ContentActions';

@Injectable({
  providedIn: 'root'
})
export class CommonTemplateResolver implements Resolve<any> {

  constructor(private readonly contentService: ContentService,
              private readonly router: Router,
              private readonly contentStore: Store<ContentState>) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    const menu = route.url[0].path;
    const subMenu = route.url[1].path;
    const subSubMenu = route.url[2]?.path;
      // console.log(url);
      // if (this.isFirstLoading) {
      //   this.isFirstLoading = false;
      //   return;
      // }



      // if (url.length === 2 && !this.isWaterPageCondition(url[0].path))  {
      //   this.isFirstLoading = true;
      // }

      // if (this.showWaterMaps) {
      //   if (subSubMenu) {
      //     this.contentService.findWater(subSubMenu).subscribe((water) => {
      //       this.selectedWater = water;
      //       this.waterPoints = [new Point([water.latitude, water.longitude])];
      //     });
      //   } else {
      //     this.contentService.findWaters().subscribe((waters) => {
      //       this.waters = waters;
      //       this.waterPoints = this.waters.map(w => new Point([w.longitude, w.latitude]));
      //     });
      //   }
      // } else
    const isWaterPage = ContentService.isWaterPageCondition(menu);
    const isFishPage = ContentService.isFishPageContition(menu);

    if (isWaterPage && ContentService.waterUrls.includes(subMenu)) {
      if (subSubMenu) {
        const selectedWater = await this.contentService.findWater(subSubMenu).toPromise();
        this.dispatchEvent(menu, subMenu, {selectedWater});
        //     .subscribe((water) => {
        //   this.selectedWater = water;
        //   this.waterPoints = [new Point([water.latitude, water.longitude])];
        // });
      } else {
        const waters = await this.contentService.findWaters().toPromise();
        this.dispatchEvent(menu, subMenu, {waters});
        //     .subscribe((waters) => {
        //   this.waters = waters;
        //   this.waterPoints = this.waters.map(w => new Point([w.longitude, w.latitude]));
        // });
      }
    } else if (isWaterPage)  {
        if (subMenu === ContentService.waterAllUrls[1]) {
          const waterLevels = await this.contentService.findWaterLevels().toPromise();
          this.dispatchEvent(menu, subMenu, {waterLevels})
        }
      } else if (isFishPage) {
        if (ContentService.fishUrls[0] === subMenu) {
          if (subSubMenu) {
            const selectedFish = await this.contentService.findFish(subSubMenu).toPromise();
            this.dispatchEvent(menu, subMenu, {selectedFish});
            // this.dispatchSubPageEvent(menu, subMenu, subSubMenu, {selectedFish});
          } else {
            const fishes = await this.contentService.findFishes().toPromise();
            this.dispatchEvent(menu, subMenu, {fishes})
          }
        }
        if (ContentService.fishUrls[1] === subMenu) {
          let rules = null;
          this.contentStore.select(selectContentState).subscribe((res) => {
            try {
              rules = res.content[menu][subMenu].rules;
            } catch(e) {

            }
          });
          if (subSubMenu) {
            if (!rules || rules.length === 0) {
              rules = await this.contentService.findRules().toPromise();
              this.dispatchEvent(menu, subMenu, {rules});
            }
            let selectedRule = await this.contentService.findRule(subSubMenu).toPromise();
            this.dispatchEvent(menu, subMenu, {rules, selectedRule});
          } else {
            const rules = await this.contentService.findRules().toPromise();
            this.dispatchEvent(menu, subMenu, {rules});
            this.router.navigateByUrl(`/${menu}/${subMenu}/${rules[0].ID}`)
            // this.dispatchEvent(menu, subMenu, {rules});
          }
        }
        if (ContentService.fishUrls[2] === subMenu || ContentService.fishUrls[3] === subMenu || ContentService.fishUrls[4] === subMenu || ContentService.fishUrls[5] === subMenu || ContentService.fishUrls[6] === subMenu) {
          const selectedFish = await this.contentService.findFish(ContentService.menuFishIds[subMenu]).toPromise();
          this.dispatchEvent(menu, subMenu, {selectedFish});
        }
        if (ContentService.fishUrls[7] === subMenu) {
          if (subSubMenu) {
            const selectedFish = await this.contentService.findFish(subSubMenu).toPromise();
            this.dispatchEvent(menu, subMenu, {selectedFish});

          } else {
            const fishes = await this.contentService.findHungarianFishes().toPromise();
            this.dispatchEvent(menu, subMenu, {fishes});
          }
        }

      } else {
        // this.selectedArticle = url[2] ? url[2].path : Object.keys(this.navigations[menu][subMenu])[0];
        //
        // this.actualNavigations = Object.values(this.navigations[menu][subMenu]);
        //
        // this.content.title = this.navigations[menu][subMenu][this.selectedArticle].title;
        // this.seoService.updateTitle(this.content.title);
        //
        // this.http.get(`assets/content/${this.selectedArticle}` + '.html', {responseType: 'text'}).subscribe((resp: any) => {
        //   this.content.content = resp;
        // }, error => console.log(error));
      }

  }

  dispatchEvent(menu: string, subMenu: string, content: any) {
    this.contentStore.dispatch(new InitContent({
      path: subMenu,
      menu: menu,
      content: content
    }));
  }

  dispatchSubPageEvent(menu: string, subMenu: string, subSubMenu: any, content: any) {
    this.contentStore.dispatch(new AddSubPage({
      id: subSubMenu,
      content: content,
      path: subMenu,
      menu: menu
    }));
  }

}
