import * as navigations from './navigations.json';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { ContentCardModel } from '../content-card/content-card.component';
import { SeoService } from '../../../service/seo.service';
import { HttpClient } from '@angular/common/http';
import { ContentService } from '../content.service';
import { NavigationElement } from '../../../model/NavigationElement';
import { WaterModel } from '../../../model/WaterModel';
import { Point } from 'ol/geom';
import { ContentType } from '../model/ContentType';
import { WaterLevel } from '../../../model/WaterLevel';
import { ContentState, PageContent } from '../../../store/content/ContentReducer';
import { Store } from '@ngrx/store';
import { initContentStateSelector } from '../../../store/content/ContentSelector';
import { Json } from '../../../model/Json';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-common-template',
  templateUrl: './common-template.component.html'
})
export class CommonTemplateComponent implements OnInit, OnDestroy {

  // common
  menu: string;
  subMenu: string;
  subSubMenu: string;
  isFirstLoading: boolean;
  contentType: ContentType;
  //

  // content type 0
  //

  // content type 1
  content: ContentCardModel;
  navigations: {[menu: string]: {[subMenu: string]: { [article: string]: NavigationElement }}} = (navigations as any).default;
  actualNavigations: Array<NavigationElement>;
  selectedArticle: string;

    // Fish
  rules: Array<any> = new Array<any>();
  fishes: Array<any> = new Array<any>();
  selectedFish: any;
  selectedRule: any;
  fishTypes: Array<string> = [];
  fishUrls: Array<string> = ['halszotar', 'horgasz-szabalyok', 'ponty', 'csuka', 'sullo', 'harcsa', 'keszegfelek', 'hazai-halfajok'];
  menuFishIds = {
    'ponty': 87,
    'csuka': 99,
    'sullo': 208,
    'harcsa': 220
  }
  isFishPage: boolean;
    //
  //

  // content type 2
  //

  // content type 3
    // Water

  contentState: Json<string, Json<string, PageContent>>;
  waters: Array<WaterModel>;
  waterPoints: Array<Point>;
  selectedWater: WaterModel;
  isWaterPage: boolean;
  showWaterMaps: boolean;
  waterLevels: Array<WaterLevel>;
  waterUrls = ['folyok', 'patakok', 'csatornak', 'tavak', 'tarozok', 'holtagak'];
  waterAllUrls = ['horgaszvizek-magyarorszagon', 'vizallasok', ...this.waterUrls]
  fishNames = ['','ponty', 'amur', 'csuka', 'süllő;', 'harcsa', 'keszeg', 'kárász', 'balin', 'busa', 'sügér', 'márna', 'angolna', 'pisztráng', 'törpeharcsa', 'afrikai harcsa', 'fekete sügér', 'tokfélék', 'kősüllő;', 'domolykó', 'naphal'];
    //
  //

  // content type 4
  //


  // title: string;

  private subscriptions: Array<Subscription> = new Array<Subscription>();

  constructor(private readonly activatedRoute: ActivatedRoute,
              private readonly seoService: SeoService,
              private readonly http: HttpClient,
              private readonly contentService: ContentService,
              private readonly store: Store<ContentState>,
              private readonly router: Router) {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngOnInit(): void {
    // this.title = this.activatedRoute.snapshot.url[1].path;
    this.subscriptions.push(
      this.store.select(initContentStateSelector).subscribe((state) => {
        this.contentState = state.content;
        console.log(state);
      })
    );
    this.content = new ContentCardModel('', '');
    try {
      this.actualNavigations = Object.values(this.navigations[this.activatedRoute.snapshot.url[0].path][this.activatedRoute.snapshot.url[1].path]);
    } catch (err) {
      console.log(err);
    }

    this.initMenuPaths(this.activatedRoute.snapshot.url);
    this.initWaterMapsFlag(this.menu, this.subMenu, this.subSubMenu);
    // TODO
    // if (!this.activatedRoute.snapshot.url[2] && !this.isWaterPage) {
    //   this.router.navigateByUrl(this.menu + '/' + this.subMenu + '/' + Object.keys(this.navigations[this.menu][this.subMenu])[0]);
    // }

    // this.activatedRoute.data.subscribe((data) => {
    //   console.log(data);
    // });
    // this.router.events.subscribe(ev => {
    //   console.log(ev)
    // })

    this.activatedRoute.url.subscribe(async (url) => {
      this.reset();
      if (this.isFirstLoading) {
        this.isFirstLoading = false;
        return;
      }



      // if (url.length === 2 && !this.isWaterPageCondition(url[0].path))  {
      //   this.isFirstLoading = true;
      // }

      this.initMenuPaths(url);
      this.initWaterMapsFlag(this.menu, this.subMenu, this.subSubMenu);
      this.initFishFlags(this.menu, this.subMenu, this.subSubMenu);
      if (this.showWaterMaps) {
        if (this.subSubMenu) {
          this.contentService.findWater(this.subSubMenu).subscribe((water) => {
            this.selectedWater = water;
            this.waterPoints = [new Point([water.latitude, water.longitude])];
          });
        } else {
          this.contentService.findWaters().subscribe((waters) => {
            this.waters = waters;
            this.waterPoints = this.waters.map(w => new Point([w.longitude, w.latitude]));
          });
        }
      } else if (this.isWaterPage)  {
        if (this.subMenu === this.waterAllUrls[1]) {
          this.contentService.findWaterLevels().subscribe((resp) => {
            this.waterLevels = resp;
          })
        }
      } else if (this.isFishPage) {
        if (this.fishUrls[0] === this.subMenu) {
          if (this.subSubMenu) {
            this.contentService.findFish(this.subSubMenu).subscribe((fish) => {
              this.selectedFish = fish;
            });
          } else {
            this.contentService.findFishes().subscribe((fishes) => {
              this.fishes = fishes;
            });
          }
        }
        if (this.fishUrls[1] === this.subMenu) {
          if (this.subSubMenu) {
            if (!this.rules || this.rules.length === 0) {
              this.contentService.findRules().subscribe((rules) => {
                this.rules = rules;
              });
            }
            this.contentService.findRule(this.subSubMenu).subscribe((rule) => {
              this.selectedRule = rule;
            });
          } else {
            this.contentService.findRules().subscribe((rules) => {
              this.rules = rules;
            });
          }
        }
        if (this.fishUrls[2] === this.subMenu || this.fishUrls[3] === this.subMenu || this.fishUrls[4] === this.subMenu || this.fishUrls[5] === this.subMenu || this.fishUrls[6] === this.subMenu) {
          this.contentService.findFish(this.menuFishIds[this.subMenu]).subscribe((resp) => {
            this.selectedFish = resp;
          });
        }
        if (this.fishUrls[7] === this.subMenu) {
          if (this.subSubMenu) {
            this.contentService.findFish(this.subSubMenu).subscribe((fish) => {
              this.selectedFish = fish;
            });
          } else {
            this.contentService.findHungarianFishes().subscribe((fishes) => {
              this.fishes = fishes;
            });
          }
        }

      } else {
        this.selectedArticle = url[2] ? url[2].path : Object.keys(this.navigations[this.menu][this.subMenu])[0];

        this.actualNavigations = Object.values(this.navigations[this.menu][this.subMenu]);

        this.content.title = this.navigations[this.menu][this.subMenu][this.selectedArticle].title;
        this.seoService.updateTitle(this.content.title);

        this.http.get(`assets/content/${this.selectedArticle}` + '.html', {responseType: 'text'}).subscribe((resp: any) => {
          this.content.content = resp;
        }, error => console.log(error));
      }
    })
  }

  initMenuPaths(url: UrlSegment[]) {
    this.menu = url[0].path;
    this.subMenu = url[1].path;
    this.subSubMenu = url[2]?.path;
  }

  initWaterMapsFlag(menu: string = this.menu, subMenu: string = this.subMenu, subSubMenu: string = this.subSubMenu) {
    if (this.isWaterPageCondition(menu) && this.waterUrls.includes(subMenu)) {
      this.showWaterMaps = true;
    } else {
      this.showWaterMaps = false;
    }
    if (this.isWaterPageCondition(menu)) {
      this.isWaterPage = true;
    } else {
      this.isWaterPage = false;
    }
  }

  initFishFlags(menu: string = this.menu, subMenu: string = this.subMenu, subSubMenu: string = this.subSubMenu) {
    if (this.isFishPageContition(menu)) {
      this.isFishPage = true;
      this.showWaterMaps = false;
    } else {
      this.isFishPage = false;
    }
  }

  isWaterPageCondition(url: string): boolean {
    return url === 'vizek';
  }

  isFishPageContition(url: string): boolean {
    return url === 'halak';
  }

  reset() {
    this.selectedArticle = null;
  }
}
