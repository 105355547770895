import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ProductService } from './product.service';
import { CategoryState } from '../store/category/CategoryReducer';
import { Store } from '@ngrx/store';
import { initCategoriesSelector } from '../store/category/CategorySelector';
import { Category } from '../model/Category';
import { OrderingType } from '../model/OrderingType';
import { SearchService } from './search.service';
import { FilterState } from '../store/filter/FilterReducer';
import { Query } from '../model/Query';
import { Constants } from '../model/Constants';

@Injectable()
export class ProductListResolver implements Resolve<any> {

  categories: Array<Category>;

  constructor(private productService: ProductService,
              private readonly searchService: SearchService,
              private filterStore: Store<FilterState>,
              private store: Store<CategoryState>) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    let categoryParam: string;
    let subCategoryParam: string;
    let pageParam: string;
    let limitParam: string;
    let ordering: string;
    let category: Category;

    if (route.routeConfig.path === Constants.SEARCH_PAGE_URL) {
      // TODO test query
      return this.productService.searchProducts('category.id');
      // this.searchService.search(route.routeConfig.)
    }

    categoryParam = route.params['category'];
    subCategoryParam = route.params['subCategory'];
    pageParam = route.queryParams[Constants.PAGE_PARAM_TEXT];
    limitParam = route.queryParams[Constants.LIMIT_PAGE_PARAM_TEXT];
    ordering = route.queryParams[Constants.ORDER_TEXT];

    this.store.select(initCategoriesSelector).subscribe((categories) => {
      category = categories.find(c => c.link === categoryParam);
    })
    if (!subCategoryParam) {
      // return this.productService.getProductsByCategory(category.id);
      return this.productService.getProductsCommonVersion(
        {categoryId: category.id},
        {
          currentPage: pageParam ? Number(pageParam) : undefined,
          itemsPerPage: limitParam ? Number(limitParam) : undefined
        },
        ordering ? ordering as OrderingType : OrderingType.POPULAR
      );
    } else {
      const subCategory = category.children.find(c => c.link === subCategoryParam);
      let query: Query = {categoryId: subCategory.id};
      if (subCategory.dedicatedFilters && subCategory.dedicatedFilters.length > 0) {
        query.dedicatedFilterOption = subCategory.dedicatedFilters[0].id;
      }
      // return this.productService.getProductsByCategory(subCategory.id);
      return this.productService.getProductsCommonVersion(
        query,
        {
          currentPage: pageParam ? Number(pageParam) : 1,
          itemsPerPage: limitParam ? Number(limitParam) : 12
        },
        ordering ? ordering as OrderingType : OrderingType.POPULAR);
    }
  }
}
