import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { WaterModel } from '../../../model/WaterModel';
import { WaterViewType } from '../model/WaterViewType';

@Component({
  selector: 'app-water-card',
  templateUrl: './water-card.component.html'
})
export class WaterCardComponent implements OnInit, OnChanges {

  @Input()
  menu: string;

  @Input()
  subMenu: string;

  @Input()
  waterModel: WaterModel;

  @Input()
  waterViewType: WaterViewType;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }
}
