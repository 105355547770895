import { ProductService } from './product.service';
import { SearchService } from './search.service';
import { Store } from '@ngrx/store';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ProductState } from '../store/product/ProductReducer';
import { initProductsSelector } from '../store/product/ProductSelector';
import { UtilsService } from './utils.service';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Constants } from '../model/Constants';

@Injectable()
export class ProductDetailsResolver implements Resolve<any> {

  constructor(private readonly productService: ProductService,
              private readonly searchService: SearchService,
              private readonly router: Router,
              private readonly store: Store<ProductState>) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    let productsByArticleNumbers;
    let product;
    let productId;
    this.store.select(initProductsSelector).subscribe((products) => {
      productsByArticleNumbers = UtilsService.convertProductsToJsonByArticleNumber(Object.values(products));
    });

    switch (route.url.length) {
      case 2: productId = route.url[1].path; break;
      case 3: productId = route.url[2].path; break;
      case 4: productId = route.url[3].path; break;
    }
    if (route.url[0].path === Constants.PRODUCT_PAGE_URL) {
      product = productsByArticleNumbers[route.url[1].path];
    } else {
      if (!productId) {
        this.router.navigateByUrl(Constants.NOT_FOUND_URL,  {skipLocationChange: true});
        return;
      }
      product = productsByArticleNumbers[productId];
    }

    if (product) {
      return product;
    }
    // if (!product) {
    //   this.router.navigateByUrl('not-found', {skipLocationChange: true});
    //   return;
    // }

    return this.productService.getProductById(productId)
      .pipe(
        map(res => res),
        catchError((err) => {
          console.log(err);
          this.router.navigateByUrl(Constants.NOT_FOUND_URL, {skipLocationChange: true});
          return of(null);
        })
      );
  }
}
