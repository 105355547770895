<div class="modal-header">

  <h4 class="modal-title">Szállítási információk</h4>
  <i class="fa fa-2x fa-times" (click)="cancelDeliveryModal()"></i>
</div>

<div class="modal-body modal-info">

  <h2>Csomag átvétele</h2>
  <p>Vásárlóink az alábbi átvételi módok közül választhatnak:</p>
  <ul>
    <li><b>csomag kiszállítása</b> futárszolgálattal a vásárló által megadott címre</li>
    <li><b>csomag kiszállítása</b> futárszolgálattal egy - a vásárló által megadott - átvételi pontra</li>
  </ul>

  <h2>Csomag kiszállítása futárszolgálattal a vásárló által megadott címre vagy átvételi pontra</h2>

  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Kit várjak, ki fogja a csomagomat kiszállítani?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>A megrendelt termékek kiszállítását a GLS- Hungary végzi.</p>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>A csomaggal, a szállítással kapcsolatban hogyan tudom felvenni a kapcsolatot a futárszolgálattal?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>A GLS Hungaryt a csomaggal, kiszállítással kapcsolatos információkért az alábbi telefonszámok valamelyikén érheti el:</p>

      <ul>
        <li>06-29-88-67-00</li>
        <li>+36-20-890-0660</li>
      </ul>
    </mat-expansion-panel>
  </mat-accordion>


  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Mikor kapom meg a megrendelt csomagot?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>A csomagot a megrendelését beérkezését követö <b>1-3 munkanapon belül</b> fogja megkapni.</p>
      <p>Az itt leírtak az <b>utánvétes</b> és <b>sikeres bankkártyás (paypal)</b> fizetési módra vonatkoznak. A <b>banki utalásos</b> fizetési mód választásakor csak annyiban térünk el, hogy a csomagot akkor adjuk át a GLS-nek, ha a teljes összeg megérkezett a bankszámlánkra.</p>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Van- e a csomag méretének korlátja?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>A GLS-Hungary maximum 30kg –ig szállítja ki a csomagokat. Ezért ha az Ön rendelése ezt a súlyhatárt meghaladja, akkor azt csak több csomagban – csomagonként megfizetett szállítási díj ellenében - tudjuk az Ön számára elküldeni.</p>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Kapok-e visszajelzést a csomagszállításról?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p><b>Igen</b>, sőt két üzenet fog kapni. Az üzeneteket már a GLS fogja elküldeni.<br>
      Miután a GLS átvette tőlünk az Ön csomagját, elküldi az <b>első</b> üzenetet. Az üzenet formája: email.</p>

      <p>Ebben tájékoztat:</p>

      <ul>
        <li>arról, hogy a következő munkanap a csomag kiszállításra kerül</li>
        <li>a <b>csomagszámról</b></li>
        <li>az esetleges utánvét összegéről</li>
      </ul>

      <p>A csomag kiszállításának napján (reggelén) a GLS küld egy újabb emailt, amiben tájékoztat:</p>

      <ul>
        <li>a csomag érkezés várható 3 órás intervallumáról</li>
        <li>valamint tartalmazza a futár telefonszámát.</li>
      </ul>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Mit kell tudnom a csomag átadás-átvételéről?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>Az előzetesen megjelölt időszakban a csomag kiszállításra kerül. Az átadás személyesen, családtagnak, vagy adott esetben szomszédnak (amennyiben erre hajlandó) is lehetséges. Utánvétes csomag átadása csak a teljes összeg kiegyenlítését követően történhet meg. Az átvevő aláírásával igazolja a csomag átvételét.</p>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Mit tegyek, ha nem tudtam átvenni a csomagot?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>A szomszéd általi átvétel esetén az átadás tényéről egy értesítőt hagy a futár az átvevő postaládájában.</p>

      <p>Amennyiben sem a címzett, sem a családtagja, sem a szomszéd nem tudta átvenni a csomagot, a futár egy értesítőt hagy a postaládában, a csomagot visszaszállítja a depóba és megkisérli a csomag kiszállítását a következő munkanap.</p>

      <p>Ezzel együtt a nap folyamán a GLS email-t küld, amiben tájékoztat az átvételi adatok (hely és idő) módosításának lehetőségéről.</p>

      <p>Az email tartalmaz egy linket, amiben a csomagszám segítségével módosítható at átvétel ideje, illetve helye. Lehetőség van másik cím megadására is, de lehetőség van GLS Csomagpontba irányítani a csomagot. Ennek a megoldásnak az az előnye, hogy annak nyitvatartási idejében a csomag bármikor átvehető.
      </p>

      <p>Az adatok módosításakor az alábbi lehetőségek közül választhat:</p>

      <ul>
        <li>Kézbesítés ugyanarra címre egy másik napon.</li>
        <li>Kézbesítés egy másik címre.</li>
        <li>Kézbesítés munkaidő után 17.00 és 20.00 óra között (csak GLS depóvárosokban).</li>
        <li>Kézbesítés egy kiválasztott GLS CsomagPontba.</li>
        <li>Személyes átvétel egy GLS depóban.Az átvétel visszautasítása.</li>
      </ul>

      <p> Amennyiben nem történik meg az adatok módosítása, úgy a futárszolgálat a következő munkanap  - illetve ha szükséges - harmadik alkalommal is megkisérli a csomag átadást.</p>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Tudom követni a csomag útját?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>Igen. Az emailban megküldött csomagazonosító segítségével <a href="https://gls-group.eu/HU/hu/csomagkovetes" target="_blank">ide kattintva</a> megtekinthető a csomagszállítás aktuális állapota.</p>
    </mat-expansion-panel>
  </mat-accordion>

  <h2 class="mt-5">További információk</h2>

  <p>Abban az esetben, ha egy címről az átvétel 2 esetben nem történik meg áruházunk fenntartja magának a jogot, hogy az ügyfél további megrendelését nem teljesíti.<br>
    A csomagban megtalálja a számlát.<br>
    Sérült, csomagot ne vegyen át és ne fizesse ki az ellenértéket!<br>
    Csomagolási díjat abban az esetben számolunk fel, ha azt a rendelt áru megkívánja (pl. horgászbot). A csomagolási díj ebben az esetben bruttó 300 Ft.
  </p>

  <h2>Tudnivalók a csomagpontra kért szállítási módról</h2>

  <p>Abban az esetben, ha nem szeretne várni a csomagszállító érkezésére, lehetőség van arra, hogy a csomagot átvételi pontra kérje kiszállítani. Ennek a megoldásnak az az előnye, hogy egyrészt olcsóbb ez a szállítási mód, másrészt akkor vesszi át a csomagot, amikor a saját időbeosztása ezt lehetővé teszi. Ehhez nem kell mást tenni, mint a csomag megérkezését jelző email/sms után 5 munkanapon belül felkeresni az átvételi pontot. Ebből jelenleg 650 működik az országban.  <a href="https://gls-group.eu/HU/hu/csomagpont-kereses" target="_blank">Az Ön közelében lévő csomagpontokról itt tájékozódhat.</a> Ugyanitt talál információt az átvételi pont nyitvatartási idejéről és a bankkártyás fizetési lehetőségről is.</p>
  <p>Felhívjuk szíves figyelmét, hogy az átvételhez szükség van a személyazonosság igazolására, ezért kérjük vigye magával személyi igazolványát/ útlevelét.
  </p>

  <h2>Szállítási díjak</h2>

  <p>Amennyiben nem találja a listában a célországot, kérjük vegye fel a kapcsolatot velünk az info@horgasz.hu címen a csomagküldéssel kapcsolatos egyeztetés érdekében.</p>

  <div class="table-responsive">
  <table class="table table-bordered">
    <thead>
    <tr>
      <th>
        Ország / Country
      </th>
      <th>
        Bruttó szállítási díj / doboz - Delivery cost / Box (max.30kg)
      </th>
      <th>
        Szállítási idő (munkanap) / Delivery time (workdays)
      </th>
    </tr>
    </thead>
    <tbody>

    <tr>
      <td><b>magyarországi GLS pontra</b> bruttó 17.000 Ft alatti rendelés esetén
      </td>
      <td>1 460 HUF
      </td>
      <td>1-3
      </td>
    </tr>
    <tr>
      <td><b>magyarországi GLS pontra </b> bruttó 17.000 Ft feletti rendelés esetén
      </td>
      <td>0
      </td>
      <td>1-3
      </td>
    </tr>
    <tr>
      <td><b>magyarországi címre</b> bruttó 17.000 Ft alatti rendelés és <b>utánvétes fizetés</b> a csomag átvételekor
      </td>
      <td>1 950 HUF
      </td>
      <td>1-3
      </td>
    </tr>
    <tr>
      <td><b>magyarországi címre</b> bruttó 17.000 Ft alatti rendelés  és <b>banki átutalás előrefizetés</b> esetén
      </td>
      <td>1 590 HUF
      </td>
      <td>1-3
      </td>
    </tr>
    <tr>
      <td><b>magyarországi címre</b> bruttó 17.000 Ft alatti rendelés és <b>bankkártyás/paypal előrefizetés</b> esetén
      </td>
      <td>1 950 HUF
      </td>
      <td>1-3
      </td>
    </tr>
    <tr>
      <td><b>magyarországi címre</b> bruttó 17.000 Ft feletti rendelés esetén
      </td>
      <td>0
      </td>
      <td>1-3
      </td>
    </tr>
    <tr>
      <td>
        <b>Romania, Slovakia</b><br>
          total under 20 000 HUF<br>
          total between 20 000-35 000 HUF<br>
          total over 35 000 HUF
      </td>
      <td>
        <b>Gross price:</b><br>
          2 340 HUF<br>
          1 334 HUF<br>
          0 HUF
      </td>
      <td>1-3
      </td>
    </tr>
    <tr>
      <td><b>Austria, Croatia, Czech Republic, Slovenia</b><br>
        total under 20 000 HUF<br>
        total between 20 000-35 000 HUF<br>
        total over 35 000 HUF
      </td>
      <td><b>Gross price:</b><br>
        4 994 HUF<br>
        2 223 HUF<br>
        0 HUF
      </td>
      <td>2-4
      </td>
    </tr>
    <tr>
      <td>
        <p><b>Belgium, Bulgaria, Germany, Luxemburg, Netherlands, Poland</b><br>
          total under 20 000 HUF<br>
          total between 20 000-35 000 HUF<br>
          total over 35 000 HUF
        </p>
      </td>
      <td><b>Gross price:</b><br>
        5 702 HUF<br>
        2 921 HUF<br>
        0 HUF
      </td>
      <td>2-5
      </td>
    </tr>
    <tr>
      <td><b>Denmark, France, Italy, Ireland  </b><br>
        total under 20 000 HUF<br>
        total between 20 000-40 000 HUF<br>
        total over 40 000 HUF
      </td>
      <td><b>Gross price:</b><br>
        9 562 HUF<br>
        4 826 HUF<br>
        0 HUF
      </td>
      <td>3-6
      </td>
    </tr>
    <tr>
      <td><b>Estonia, Finland, Greece, Latvia, Lithuania, Portugal, Spain, Sweden</b><br>
        total under 20 000 HUF<br>
        total between 20 000-50 000 HUF<br>
        total over 50 000 HUF
      </td>
      <td><b>Gross price:</b><br>
        11 707 HUF<br>
        6 985 HUF<br>
        0 HUF
      </td>
      <td>3-8
      </td>
    </tr>
    </tbody>
  </table>
  </div>

</div>

<div class="modal-footer">
  <button type="button" class="btn me-2 btn-outline-dark" (click)="cancelDeliveryModal()">Bezár</button>
</div>
