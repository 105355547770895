import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ActionReducer, ActionReducerMap, MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { productReducer } from './store/product/ProductReducer';
import { CoreModule } from './modules/core/core.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxPaginationModule } from 'ngx-pagination';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { CategoryService } from './service/category.service';
import { categoryReducer } from './store/category/CategoryReducer';
import { paginationReducer } from './store/pagination/PaginationReducer';
import { cartReducer } from './store/cart/CartReducer';
import { userReducer } from './store/user/UserReducer';
import { ApplicationService } from './service/application.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { propertyReducer } from './store/property/PropertyReducer';
import { filterReducer } from './store/filter/FilterReducer';
import { RouteReuseStrategy } from '@angular/router';
import { ReuseStrategyService } from './service/reuse.strategy.service';
import { ToastrModule } from 'ngx-toastr';
import { AvatarModule } from 'ngx-avatar';
import { SharedModule } from './modules/shared/shared.module';
import { ImageMapResizerDirective } from './modules/image-map-resizer.directive';
import { localStorageSync } from 'ngrx-store-localstorage';
import { RootState } from './model/RootState';
import { contentReducer } from './store/content/ContentReducer';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { ProgressInterceptor } from './service/progress.spinner.service';
import { SessionExpiredInterceptor } from './service/session-expired.interceptor';

export function initApplicationFactory(applicationService: ApplicationService) {
  return async (): Promise<void> => await applicationService.initApplication();
}

export function translateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const reducers: ActionReducerMap<RootState> = {
  productReducer,
  propertyReducer,
  filterReducer,
  categoryReducer,
  cartReducer,
  userReducer,
  paginationReducer
};

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: ['product', 'property', 'filter', 'category', 'cart', 'user', 'content', 'pagination'],
    // TODO
    // keys: [
    //   {
    //     product: {
    //       encrypt: state => btoa(state),
    //       decrypt: state => atob(state)
    //     }
    //   },
    //   {
    //     property: {
    //       encrypt: state => btoa(state),
    //       decrypt: state => atob(state)
    //     }
    //   },
    //   {
    //     filter: {
    //       encrypt: state => btoa(state),
    //       decrypt: state => atob(state)
    //     }
    //   },
    //   {
    //     category: {
    //       encrypt: state => btoa(state),
    //       decrypt: state => atob(state)
    //     }
    //   },
    //   {
    //     cart: {
    //       encrypt: state => btoa(state),
    //       decrypt: state => atob(state)
    //     }
    //   },
    //   {
    //     user: {
    //       encrypt: state => btoa(state),
    //       decrypt: state => atob(state)
    //     }
    //   },
    //   {
    //     pagination: {
    //       encrypt: state => btoa(state),
    //       decrypt: state => atob(state)
    //     }
    //   }
    // ],
    rehydrate: true,
    storageKeySerializer: key => {
      console.log(key);
      return key;
    }
  })(reducer);
}
const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
  declarations: [
    AppComponent,
    ImageMapResizerDirective
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    NgxPaginationModule,
    StoreModule.forRoot(reducers, {
      metaReducers
    }),
    StoreModule.forFeature('product', productReducer),
    StoreModule.forFeature('property', propertyReducer),
    StoreModule.forFeature('filter', filterReducer),
    StoreModule.forFeature('category', categoryReducer),
    StoreModule.forFeature('cart', cartReducer),
    StoreModule.forFeature('user', userReducer),
    StoreModule.forFeature('content', contentReducer),
    StoreModule.forFeature('pagination', paginationReducer),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      // logOnly: environment.production, // Restrict extension to log-only mode
    }),
    HttpClientModule,
    FormsModule,
    MatExpansionModule,
    MatSidenavModule,
    SharedModule,
    ReactiveFormsModule,
    AvatarModule.forRoot(),
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'hu_HU',
      useDefaultLang: true
    }),
    NgxSpinnerModule
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initApplicationFactory,
      deps: [ApplicationService, CategoryService],
      multi: true
    },
    {
      provide: RouteReuseStrategy,
      useClass: ReuseStrategyService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ProgressInterceptor,
      multi: true,
      deps: [NgxSpinnerService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionExpiredInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
