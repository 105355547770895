<div class="footer">
  <div class="shape-divider-bottom shape-divider-secondary">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
      <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
    </svg>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-lg-6">
        <h5>Conte Magyarország Kft.</h5>
        <ul>
        <li><a href="https://goo.gl/maps/tWEuqgJXpjw9mZK67">2040 Budaörs, Lajta köz 1.</a></li>
        <li class="text-white">Adószám: 25067101213</li>
        <li class="text-white">Cégjegyzékszám: 13 09 210781</li>
        </ul>

        <div class="footer-icons">
<!--        <p>Fizetési módok</p>-->

          <i class="fa fa-cc-mastercard"></i>
          <i class="fa fa-cc-visa"></i>
          <i class="fa fa-cc-paypal"></i>
        </div>


      </div>
      <div class="col-md-12 col-lg-6">
        <h5 class="pt-5 pt-md-0">Információk</h5>

        <ul>
          <li><a [routerLink]="'adatvédelem'">Adatvédelem</a></li>
          <li><a [routerLink]="'aszf'">ÁSZF</a></li>
          <li><a [routerLink]="'vasarlasi-informaciok#garancia'">Garancia</a></li>
          <li><a [routerLink]="'vasarlasi-informaciok#szallitas'">Szállítási info</a></li>
        </ul>
      </div>
      <div class="col-md-12 col-lg-6">
        <h5 class="pt-0 pt-md-5 pt-lg-0">Vevőszolgálat</h5>
        <ul>
          <li><a href="tel:+36304480063">Tel:  06-30-44-800-63</a></li>
          <li><a href="mailto:info@conte.hu">info@conte.hu</a></li>
        </ul>
      </div>
      <div class="col-md-12 col-lg-6">
        <h5 class="pt-0 pt-md-5 pt-lg-0">Kapcsolat</h5>
        <ul>
        <li><a [routerLink]="'kapcsolat'">Területi képviselők elérhetőségei</a></li>
        <li><a [routerLink]="'irjnekunk'">Írj nekünk</a></li>
        </ul>
      </div>
    </div>
  </div>


  <p class="text-center"><span class="social-headline">Kövess minket!</span></p>

</div>


<div class="footer-bottom">
  <a href="https://www.facebook.com/ConteHarisnya/" target="blank" tooltip="Facebook"><i class="fa fa-facebook"></i></a>
  <a href="https://instagram.com" target="_blank" tooltip="Instagram"><i class="fa fa-instagram"></i></a>
  <a href="https://www.youtube.com/channel/UCfbeEzYMLEt4TLHjhuIzaJw/feed" target="_blank" tooltip="Youtube"><i class="fa fa-youtube"></i></a>
  <a href="https://videa.hu/videok/emberek-vlogok/conte-elegant-harisnya-magyarorszag-f8PtvE5rlnUYUHGN" target="_blank" tooltip="Videa"><i class="fa fa-videa"></i></a>
  <a href="https://twitter.com/ConteHarisnya" target="_blank" tooltip="Twitter"><i class="fa fa-twitter"></i></a>
</div>

<div [ngClass]="scrollBottomClass ? 'scrolled page-scroll-bottom-container' : 'd-none'">
  <a pageScroll [pageScrollOffset]="40" [pageScrollDuration]="1500" href="#home">⌃<i class="fa fa-arrow-up"></i></a>
</div>
