import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'county'
})
export class CountyPipe implements PipeTransform {

  counties = ['Bács-Kiskun megye',
    'Baranya megye',
    'Békés megye',
    'Borsod-Abaúj-Zemplén megye',
    'Budapest',
    'Csongrád megye',
    'Fejér megye',
    'Győr-Moson-Sopron megye',
    'Hajdú-Bihar megye',
    'Heves megye',
    'Jász-Nagykun-Szolnok megye',
    'Komárom-Esztergom megye',
    'Nógrád megye',
    'Pest megye',
    'Somogy megye',
    'Szabolcs-Szatmár-Bereg megye',
    'Tolna megye',
    'Vas megye',
    'Veszprém megye',
    'Zala megye'
  ];
  transform(value: number): string {
    return this.counties[value - 1];
  }

}
