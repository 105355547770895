
<div class="aside-filters">


  <div class="alert fade show d-flex justify-content-between" role="alert" [ngStyle]="isFiltersEmpty ? {'visibility': 'hidden'} : {}" (click)="deleteFilters()">
    <b>Szűrők törlése</b>
      <i class="fa fa-filter-remove"></i>
  </div>


<h3><i class="fa fa-filter"></i>Szűrők</h3>

<mat-accordion>
  <mat-expansion-panel [expanded]="0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Ár
      </mat-panel-title>
    </mat-expansion-panel-header>

    <app-price-filter
      [minPrice]="filter.priceRange.min"
      [maxPrice]="filter.priceRange.max"
      [(ngModel)]="activeFilter.priceRange"
      (ngModelChange)="propertyChanged()">
    </app-price-filter>
  </mat-expansion-panel>
</mat-accordion>


<div *ngIf="numberOfProperties > 0">
<mat-accordion>
  <mat-expansion-panel class="mt-5 mb-5" *ngFor="let prop of (activeFilter.properties | keyvalue)">
    <mat-expansion-panel-header matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" [matRippleRadius]="200">
      <mat-panel-title>
        {{ 'property.label.' + prop.key | translate}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div *ngFor="let item of prop.value">
      <mat-checkbox [color]="'primary'" [(ngModel)]="item.isChecked" (change)="propertyChanged()">{{item.label}}</mat-checkbox>
    </div>
  </mat-expansion-panel>
</mat-accordion>
</div>

</div>


<!--<div *ngIf="numberOfProperties > 0">-->
<!--  <div class="mt-5 mb-5" *ngFor="let prop of (activeFilter.properties | keyvalue)">-->
<!--    <div>-->
<!--      <label>{{prop.key | translate}}</label>-->
<!--      <div *ngFor="let item of prop.value">-->
<!--        <mat-checkbox [color]="'primary'" [(ngModel)]="item.isChecked" (change)="propertyChanged()">{{item.label}}</mat-checkbox>-->
<!--&lt;!&ndash;        <input class="me-3" type="checkbox" placeholder="{{item}}" (change)="propertyChanged($event, prop.key, item)">&ndash;&gt;-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
