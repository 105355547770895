import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderComponent } from './order/order.component';
import { SharedModule } from '../../shared/shared.module';
import { PriceFilterComponent } from './price-filter/price-filter.component';
import { CheckboxFilterComponent } from './checkbox-filter/checkbox-filter.component';
import { FilterComponent } from './filter/filter.component';
import { PageSizerComponent } from './page-sizer/page-sizer.component';
import { DedicatedFilterComponent } from './dedicated-filter/dedicated-filter.component';

@NgModule({
  declarations: [
    OrderComponent,
    PriceFilterComponent,
    CheckboxFilterComponent,
    FilterComponent,
    PageSizerComponent,
    DedicatedFilterComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    FilterComponent,
    OrderComponent,
    PageSizerComponent,
    DedicatedFilterComponent
  ]
})
export class FilterModule {
}
