import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Product } from '../../../model/Product';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductState } from '../../../store/product/ProductReducer';
import { Store } from '@ngrx/store';
import { AddProductToCart } from '../../../store/cart/CartActions';
import { ProductInCart } from '../../../model/ProductInCart';
import { CartState } from '../../../store/cart/CartReducer';
import { PropertyState } from '../../../store/property/PropertyReducer';
import { RatingService } from '../../../service/rating.service';
import { FormGroup } from '@angular/forms';
import { FormService } from '../../../service/form.service';
import { DataExchangeService } from '../../../service/data-exchange.service';
import { Meta } from '@angular/platform-browser';
import { AddProduct, AddReview } from '../../../store/product/ProductActions';
import { User } from '../../../model/User';
import { Subscription } from 'rxjs';
import { Constants } from '../../../model/Constants';
import { initFavouriteProductsSelector, initUserSelector } from '../../../store/user/UserSelector';
import { UserState } from '../../../store/user/UserReducer';
import { ProductService } from '../../../service/product.service';
import { AddFavouriteProduct, RemoveFavouriteProduct } from '../../../store/user/UserActions';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Lightbox } from 'ngx-lightbox';
import { DeliveryComponent } from '../delivery/delivery.component';
import { Json } from '../../../model/Json';
import { TranslateService } from '@ngx-translate/core';
import { MissingProductComponent } from '../missing-product/missing-product.component';
import {SizeChartComponent} from "../size-chart/size-chart.component";

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html'
})
export class ProductDetailsComponent implements OnInit, OnDestroy {

  selectedProduct: Product;
  activeImage: string;

  // DATA IDEIGLENESEN A MÉRETEKNEK, SZÍNEKNEK
  sizeData: any = [
    {'name': '164-84-92/XS','aviable': true},
    {'name': '164-88-96/S','aviable': true},
    {'name': '164-92-100/M','aviable': true},
    {'name': '164-96-104/L','aviable': true},
    {'name': '176-84-92/XS','aviable': true},
    {'name': '176-88-96/S','aviable': true},
    {'name': '176-92-100/M','aviable': true},
    {'name': '176-96-104/L','aviable': false}
  ];

  sizeSelected = '164-84-92/XS';

  colorData: any = [
    {'name': 'fekete', icon: 'black.png'},
    {'name': 'piros', icon: 'red.jpg'},
    {'name': 'kék', icon: 'blue.jpg'}
  ];

  colorSelected = 'fekete';

  favouriteProducts: Array<number>;
  product: Product;

  amountByProduct: Json<number, number> = new Json<number, number>();

  reviewForm: FormGroup;
  averageRating: number;
  siteNavigation: boolean;

  // magnifier prefs
  imgWidth = "200";
  imgHeight = "200";
  resultWidth = "35";
  resultHeight = "35"
  resultTop = "6"
  resultRight = "31"

  deliveryModalRef: BsModalRef;
  missingProductModalRef: BsModalRef;
  sizeChartModalRef: BsModalRef;

  @ViewChild('deliveryModal', { static: false })
  deliveryModalTemplate: TemplateRef<any>;

  @ViewChild('missingProductModal', { static: false })
  missingProductModalTemplate: TemplateRef<any>;

  @ViewChild('sizeChartModal', { static: false })
  sizeChartModalTemplate: TemplateRef<any>;

  private _albums: Array<any> = [
    {
      src: '../assets/images/Roycreates-red.jpg',
      caption: 'test',
      thumb: '../assets/images/Roycreates-red.jpg'

    },
    {
      src: '../assets/images/Roycreates-red.jpg',
      caption: 'test',
      thumb: '../assets/images/Roycreates-red.jpg'
    }
  ];

  user: User;
  private readonly subscriptions: Array<Subscription> = new Array<Subscription>();

  constructor(private readonly cartStore: Store<CartState>,
              private readonly activatedRoute: ActivatedRoute,
              private readonly productStore: Store<ProductState>,
              private readonly propertyStore: Store<PropertyState>,
              private readonly ratingService: RatingService,
              private readonly formService: FormService,
              private readonly userStore: Store<UserState>,
              private readonly productService: ProductService,
              private readonly toastrService: ToastrService,
              private readonly dataexchangeService: DataExchangeService,
              private readonly modalService: BsModalService,
              protected readonly router: Router,
              private readonly metaService: Meta,
              private readonly translateService: TranslateService,
              private readonly _lightbox: Lightbox) {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngOnInit(): void {
    if (this.activatedRoute.routeConfig.path.includes(Constants.PRODUCT_PAGE_URL)) {
      this.siteNavigation = false;
    } else {
      this.siteNavigation = true;
    }
  //   TODO kivenni az url-ből a product id-t, betölteni a store-ból a productot, ha nincs ott, akkor a serverről

    this.averageRating = 0;
    this.product = this.activatedRoute.snapshot.data.preload;
    this.selectedProduct = this.product.variations[0];
    this.activeImage = this.product.pictures[0];
    this.productStore.dispatch(new AddProduct({[this.product.id]: this.product}));

    this.metaService.addTag({name: 'description', property: this.product.describe});
    this.reviewForm = this.formService.getReviewForm(this.product.id);
    this.calculateAverageRating();

    this.subscriptions.push(
      this.userStore.select(initUserSelector).subscribe((user) => {
        this.user = user;
      })
    );

    this.subscriptions.push(
      this.userStore.select(initFavouriteProductsSelector).subscribe((favouriteProducts) => {
        this.favouriteProducts = favouriteProducts;
      })
    );

    this.subscriptions.push(this.activatedRoute.url.subscribe((params) => {
      console.log('params');
      console.log(params);
      // if (params[0].path === 'termek') {
      //   this.product = this.productsByArticleNumbers[params[1].path];
      // } else {
      //   let productId;
      //   switch (params.length) {
      //     case 2: productId = params[1].path; break;
      //     case 3: productId = params[2].path; break;
      //     case 4: productId = params[3].path; break;
      //   }
      //   if (!productId) {
      //     this.router.navigateByUrl('not-found',  {skipLocationChange: true});
      //     return;
      //   }
      //   this.product = this.productsByArticleNumbers[productId];
      // }
      // if (!this.product) {
      //   this.router.navigateByUrl('not-found', {skipLocationChange: true});
      //   return;
      // }
      // this.metaService.addTag({name: 'description', property: this.product.description});
      // this.reviewForm = this.formService.getReviewForm(this.product.id);
      // this.calculateAverageRating();
    }));

    if (!this.product) {
      return;
    }

    this.product.variations.forEach(item => {
      this.amountByProduct[item.id] = 1;
    });


    for (let i = 1; i <= 4; i++) {
      const src = 'demo/img/image' + i + '.jpg';
      const caption = 'Image ' + i + ' caption here';
      const thumb = 'demo/img/image' + i + '-thumb.jpg';
      const album = {
        src,
        caption,
        thumb
      };

      this._albums.push(album);
    }

  }

  calculateAverageRating() {
    // this.averageRating = Number((this.product.reviews.reduce((a, b) => a + b.rating, 0) / this.product.reviews.length).toFixed(2));
    this.averageRating = 2;
  }

  addToCart() {
    this.cartStore.dispatch(new AddProductToCart(this.selectedProduct.id, new ProductInCart(this.selectedProduct.id, this.product.id, this.amountByProduct[this.selectedProduct.id])));
    this.dataexchangeService.setAddProduct(this.selectedProduct);
  }

  increaseAmountForProduct(productId: number) {
    this.amountByProduct[productId] += 1;
  }

  decreaseAmountForProduct(productId: number) {
    this.amountByProduct[productId] -= 1;
  }

  submitReview() {
    this.ratingService.reviewProduct(this.reviewForm.getRawValue()).subscribe(() => {
      this.productStore.dispatch(new AddReview({
        productId: this.product.id,
        rating: this.reviewForm.value.rating,
        review: this.reviewForm.value.review,
        name: this.user.username,
        creationDate: new Date()
      }));


      //  TODO letiltani az értékelést ehhez a termékhez
      //  hozzáadni a legújabbat
      this.calculateAverageRating();
    }, err => {
      console.log(err);
    });
  }

  addToFavouriteProducts() {
    this.productService.addToFavouriteProducts(this.product.id).subscribe(() => {
      this.toastrService.success(this.translateService.instant('label.product.added.to.favourite.products'));
      this.productStore.dispatch(new AddFavouriteProduct(this.product.id));
    });
  }

  deleteFromFavouriteProducts() {
    this.productService.removeFromFavouriteProducts(this.product.id).subscribe(() => {
      this.toastrService.success(this.translateService.instant('label.product.removed.from.favourite.products'));
      this.productStore.dispatch(new RemoveFavouriteProduct(this.product.id));
    });
  }


  openDeliveryModal(): void {
    this.deliveryModalRef = this.modalService.show(DeliveryComponent, {
      animated: true,
      ignoreBackdropClick: true,
      backdrop: false,
      class: 'modal-shadow modal-lg'
    });
  }


  cancelDeliveryModal() {
    this.deliveryModalRef.hide();
    this.deliveryModalRef = null;
  }

  openMissingProductModal(): void {
    this.missingProductModalRef = this.modalService.show(MissingProductComponent, {
      animated: true,
      ignoreBackdropClick: true,
      backdrop: false,
      class: 'modal-shadow modal-lg'
    });
  }

  openSizeChartModal(): void {
    this.missingProductModalRef = this.modalService.show(SizeChartComponent, {
      animated: true,
      ignoreBackdropClick: true,
      backdrop: false,
      class: 'modal-shadow modal-xl'
    });
  }

  cancelMissingProduct() {
    this.missingProductModalRef.hide();
    this.missingProductModalRef = null;
  }

  navigateToCheckout() {
    this.router.navigateByUrl('penztar');
  }

  open(): void {
    // open lightbox
    // this._lightbox.open(item);
    this._lightbox.open(this.product.pictures.map(p => {
      return {
        src: p,
        // caption?: item,
        thumb: p
      };
    }));
    // this._lightbox.open([{
    //   src: item,
    //   // caption?: item,
    //   thumb: item
    // },
    //   {
    //     src: item,
    //     // caption?: item,
    //     thumb: item
    //   },
    //   {
    //     src: item,
    //     // caption?: item,
    //     thumb: item
    //   }
    // ]);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  setSize(item) {
    this.sizeSelected = item;
  }

  setColor(item) {
    this.colorSelected = item;
  }


}
