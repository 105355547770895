

<div class="container-fluid product-list">
  <div class="row">
    <div class="col-lg-19">

      <h1 class="text-center">{{title}}Főkategória neve</h1>

      <div class="mt-4 pb-4 subcats-container d-flex justify-content-center">
        <app-sub-category-card (navigateEvent)="navigateToSubCategory($event)" *ngFor="let c of subCategories" [category]="c">
        </app-sub-category-card>
      </div>

        <div class="d-flex justify-content-center align-items-center btn-nav mt-5">
          <app-order [values]="orderings" [initialValue]="orderBy.link" [(ngModel)]="orderBy" (ngModelChange)="updateParams(filter, activeFilter, $event.link);"></app-order>
          <app-page-sizer [(ngModel)]="pagination.itemsPerPage" (ngModelChange)="updatePageSize($event)"></app-page-sizer>
        </div>

      <hr>


      <div class="row mt-5">
        <app-product class="col-12 col-md-8 col-lg-6 mb-5 d-flex"
                     *ngFor="let p of products | paginate: pagination"
                     [product]="p"
                     [link]="'/' + mainCategory.link + '/' + subCategory.link"
        ></app-product>

        <div class="col-24">
          <pagination-controls class="pt-5 pb-5 text-center" [nextLabel]="''" (pageChange)="pageChanged($event)"></pagination-controls>
        </div>

      </div>
      <!--        <div class="brands">-->
      <!--          <app-brand-list></app-brand-list>-->
      <!--        </div>-->

    </div>

    <div class="col-lg-5 aside">

      <!--SUBCATEGORIES-------------------------------------->
      <div class="list-group">
        <app-filter [(ngModel)]="activeFilter"
                    [initialValue]="activeFilter"
                    [filter]="filter"
                    (ngModelChange)="updateParams(filter, $event)"></app-filter>
        <!--        <app-sub-categories-list [category]="category" [activeSubcategory]="subCategoryParam"></app-sub-categories-list>-->
      </div>


    </div><!--  row END-->

  </div><!--  aside END-->
</div>
