<mat-sidenav-container
  class="sidenav-container" (backdropClick)="close('backdrop')">
  <mat-sidenav #sidenav (keydown.escape)="close('escape')" disableClose>
    <div class="p-2 d-flex justify-content-between align-items-center bg-gradient-secondary text-white"><span class="lead">Kategóriák</span> <i class="fa fa-2x fa-times" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" (click)="close('toggle button')"></i></div>

    <app-sidebar-categories (closeSidebarEvent)="sidenav.close()" [categories]="categories"></app-sidebar-categories>

  </mat-sidenav>

  <mat-sidenav-content class="main">
<!--    <p><button mat-button (click)="sidenav.open()">Open</button></p>-->
<!--    <p>Closed due to: {{reason}}</p>-->

<!--    <app-adtop></app-adtop>-->
    <app-masterhead (cartClicked)="cartSidenav.open()" (categoryClicked)="sidenav.open()"></app-masterhead>
    <app-adhead></app-adhead>

    <div>
      <router-outlet #outlet="outlet"></router-outlet>
    </div>
    <app-footer></app-footer>

  </mat-sidenav-content>

  <mat-sidenav position="end" [autoFocus]="false" #cartSidenav (keydown.escape)="cartSidenav.close()" disableClose class="position-fixed">

    <app-shopping-cart (closeCartEvent)="cartSidenav.close()" [sidenav]="sidenav" [cartSidenav]="cartSidenav"
    ></app-shopping-cart>

  </mat-sidenav>

</mat-sidenav-container>





