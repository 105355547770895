import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Product } from '../model/Product';

@Injectable({
  providedIn: 'root'
})
export class DataExchangeService {

  private _addProduct: BehaviorSubject<Product> = new BehaviorSubject<Product>(null);
  public addProduct: Observable<Product> = this._addProduct.asObservable();

  private _numberOfProductsInCart: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public numberOfProductsInCart: Observable<number> = this._numberOfProductsInCart.asObservable();

  constructor() {
  }

  setAddProduct(product: Product) {
    this._addProduct.next(product);
  }

  setNumberOfProductsInCart(value: number) {
    this._numberOfProductsInCart.next(value);
  }

  reset() {
    this.setAddProduct(null);
    this.setNumberOfProductsInCart(null);
  }
}
