import { Component, Input, OnInit } from '@angular/core';
import { Product } from '../../../model/Product';
import { Category } from '../../../model/Category';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html'
})
export class SearchResultComponent implements OnInit {

  @Input()
  products: Array<Product>;

  @Input()
  categories: Array<Category>;

  @Input()
  manufacturers: string[];

  constructor() { }

  ngOnInit(): void {
  }

}
