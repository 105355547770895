<form class="container registerForm" [formGroup]="form" (ngSubmit)="register()">
    <h2 class="mt-5 text-center">Regisztráció</h2>
    <div [formGroupName]="'personalData'">
      <div class="row mt-4">
        <input class="col-24 form-control" name="name" type="text" formControlName="name" [placeholder]="'contact.name' | translate">
      </div>

      <div class="row mt-4">
        <input type="text" class="col-24 form-control" name="email" formControlName="email" [placeholder]="'address.email' | translate">
      </div>
    </div>

    <div [formGroupName]="'password'">
      <div class="row mt-4">
        <input class="form-control" name="password" [placeholder]="'register.password' | translate" type="password" formControlName="password">
      </div>
      <div class="row mt-4">
        <input class="form-control" name="passwordAgain" [placeholder]="'register.passwordAgain' | translate" type="password" formControlName="passwordAgain">
      </div>
    </div>

    <div class="row text-center d-flex justify-content-center align-items-center mt-5">
      <button type="submit" class="btn btn-primary">{{'register.submit' | translate}}</button>
    </div>

</form>
