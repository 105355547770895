import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Order } from '../model/Order';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private httpClient: HttpClient) {
  }

  getOrders(): Observable<Array<Order>> {
    // return this.httpClient.get<Array<Order>>('/api/order');
    return of([
      {
        orderId: '3',
        address:         {
          address: 'Kossuth Lajos utca',
          city: 'Budapest',
          country: 'Magyarország',
          phoneNumber: '+36301234567',
          postalCode: 4000,
          id: 1
        },
        billingAddress:         {
          address: 'Kossuth Lajos utca',
          city: 'Budapest',
          country: 'Magyarország',
          phoneNumber: '+36301234567',
          postalCode: 4000,
          id: 1
        },
        products: [
          {
            id: 1,
            amount: 2,
            articleNumber: 'asd123',
            name: 'Leggings',
            grossPrice: 2.55,
            link: 'leggings',
            primaryPicture: 'https://akciosillat.hu/img/5166/5011417566589/5011417566589.jpg?time=1651315494',
            salePrice: 2.66,
            unitOfMeasure: 'db'
          }
        ],
        creationDate: new Date(),
        status: 'In progress',
        total: 30
      }
    ]);
  }

  getOrder(orderId: string) {
    let params = new HttpParams().set('orderId', orderId);
    return this.httpClient.get<Array<Order>>('/api/order?', {params: params});
  }
}
