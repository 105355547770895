import {Component, HostListener, OnInit} from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {

  scrollBottomClass = false;


  @HostListener('window:scroll', ['$event'])

  handleScroll(): void {
    this.scrollBottomClass = window.scrollY > 200;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
