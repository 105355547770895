import { FilterPropertyState } from './FilterPropertyState';

// export class Properties {
//   [k: string]: Array<FilterPropertyState>;
// }

export class ActiveFilter {
  priceRange: DoubleRange;
  properties: {[k: string]: Array<FilterPropertyState>};

  constructor() {
    this.priceRange = {};
    this.properties = {};
  }
}
