<div class="card">
  <div class="card-header">
    <div>
      {{waterViewType === 0 ? (waterModel.megye | county) : (waterModel.viztipus | water | titlecase)}}
    </div>
<!--    <div>-->
<!--      {{waterModel.vizterulet}}-->
<!--    </div>-->
  </div>
  <div class="card-body">
    <h5 class="mb-5">
<!--      <a href="">{{waterModel.megye | county}}</a>-->
      <a href="">{{waterModel.viznev}}</a>
    </h5>
    <p [innerHTML]="waterModel.leiras | shortens:175">
    </p>

  </div>

  <div class="card-footer">
      <a class="btn btn-sm btn-info text-white" [routerLink]="'/' + menu + '/' + subMenu + '/' + (waterModel.viznev | nameToUrl)"><i class="fa fa-chevron-right"></i> Tovább</a>
  </div>
</div>
