<carousel [noPause]="false" class="main-carousel">
  <slide>
    <img src="assets/images/carousel/slide1.jpg" alt="First slide">
    <div class="carousel-caption">
      <h1>First slide label</h1>
      <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
    </div>
  </slide>
  <slide>
    <img src="assets/images/carousel/slide2.jpg" alt="Second slide">
    <div class="carousel-caption">
      <h1>Second slide label</h1>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    </div>
  </slide>
  <slide>
    <img src="assets/images/carousel/slide3.jpg" alt="Third slide">
    <div class="carousel-caption">
      <h1>Third slide label</h1>
      <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
    </div>
  </slide>
</carousel>


<div class="container-fluid headline-block female-bg">
<h2><i class="fa fa-lg fa-female"></i> Újdonságok</h2>
</div>

<div class="product-list">
  <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 row-cols-xxl-6">
  <div class="col">
      <div class="card" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" [matRippleRadius]="265">
        <div class="card-header">
          <img src="assets/images/products/prod1.jpg" class="card-img-prod" alt="aaa">
        </div>
        <div class="card-body">
          <h3 class="card-title">Conte Elegant Fantasy Dolce Vita</h3>
        </div>
        <div class="card-footer text-center">

          <p class="card-text"><span class="offerprice">4999 Ft</span></p>
<!--          <button class="btn btn-outline-secondary btn-rounded"><i class="fa fa-chevron-right"></i> Részletek</button>-->
        </div>
      </div>
  </div>
  <div class="col">
    <div class="card" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" [matRippleRadius]="265">
      <div class="card-header">
        <img src="assets/images/products/prod2.jpg" class="card-img-prod" alt="aaa">
      </div>
      <div class="card-body">
        <h3 class="card-title">Femme 2020 fantázia harisnya</h3>
      </div>
      <div class="card-footer text-center">
        <p  class="card-text"><span class="offerprice">24 899 Ft</span></p>
<!--        <button class="btn btn-outline-secondary btn-rounded"><i class="fa fa-chevron-right"></i> Részletek</button>-->
      </div>
    </div>
  </div>
  <div class="col">
    <div class="card" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" [matRippleRadius]="265">
      <div class="card-header">
        <img src="assets/images/products/prod3.jpg" class="card-img-prod" alt="aaa">
      </div>
      <div class="card-body">
        <h3 class="card-title">Afina 2020 fantázia harisnya</h3>
      </div>
      <div class="card-footer text-center">
        <p  class="card-text"><span class="offerprice">15 999 Ft</span></p>
<!--        <button class="btn btn-outline-secondary btn-rounded"><i class="fa fa-chevron-right"></i> Részletek</button>-->
      </div>
    </div>







  </div>
  <div class="col">
    <div class="card" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" [matRippleRadius]="265">
      <div class="card-header">
        <img src="assets/images/products/prod4.jpg" class="card-img-prod" alt="aaa">
      </div>
      <div class="card-body">
        <h3 class="card-title">Gloss Chic fantázia leggings</h3>
      </div>
      <div class="card-footer text-center">
        <p  class="card-text"><span class="offerprice">15 999 Ft</span></p>
        <!--        <button class="btn btn-outline-secondary btn-rounded"><i class="fa fa-chevron-right"></i> Részletek</button>-->
      </div>
    </div>







  </div>
  <div class="col">
    <div class="card" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" [matRippleRadius]="265">
      <div class="card-header">
        <img src="assets/images/products/prod5.jpg" class="card-img-prod" alt="aaa">
      </div>
      <div class="card-body">
        <h3 class="card-title">Desire lux fantázia leggings</h3>
      </div>
      <div class="card-footer text-center">
        <p  class="card-text"><span class="offerprice">15 999 Ft</span></p>
        <!--        <button class="btn btn-outline-secondary btn-rounded"><i class="fa fa-chevron-right"></i> Részletek</button>-->
      </div>
    </div>







  </div>
  <div class="col">
    <div class="card" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" [matRippleRadius]="265">
      <div class="card-header">
        <img src="assets/images/products/prod3.jpg" class="card-img-prod" alt="aaa">
      </div>
      <div class="card-body">
        <h3 class="card-title">Afina 2020 fantázia harisnya</h3>
      </div>
      <div class="card-footer text-center">
        <p  class="card-text"><span class="offerprice">15 999 Ft</span></p>
        <!--        <button class="btn btn-outline-secondary btn-rounded"><i class="fa fa-chevron-right"></i> Részletek</button>-->
      </div>
    </div>







  </div>
  </div>
</div>


<div class="parallax-block" [ngStyle]="{'background-image': parallaxImage}">
<h1>In good shape</h1>
<button class="btn btn-lg btn-secondary">Vásárolj most!</button>
</div>

<div class="container-fluid headline-block male-bg">
  <h2><i class="fa fa-lg fa-male"></i> Újdonságok</h2>
</div>

<div class="product-list">
  <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 row-cols-xxl-6">
    <div class="col">
      <div class="card" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" [matRippleRadius]="265">
        <div class="card-header">
          <img src="assets/images/products/prod1.jpg" class="card-img-prod" alt="aaa">
        </div>
        <div class="card-body">
          <h3 class="card-title">Conte Elegant Fantasy Dolce Vita</h3>
        </div>
        <div class="card-footer text-center">

          <p class="card-text"><span class="offerprice">4999 Ft</span></p>
          <!--          <button class="btn btn-outline-secondary btn-rounded"><i class="fa fa-chevron-right"></i> Részletek</button>-->
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" [matRippleRadius]="265">
        <div class="card-header">
          <img src="assets/images/products/prod2.jpg" class="card-img-prod" alt="aaa">
        </div>
        <div class="card-body">
          <h3 class="card-title">Femme 2020 fantázia harisnya</h3>
        </div>
        <div class="card-footer text-center">
          <p  class="card-text"><span class="offerprice">24 899 Ft</span></p>
          <!--        <button class="btn btn-outline-secondary btn-rounded"><i class="fa fa-chevron-right"></i> Részletek</button>-->
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" [matRippleRadius]="265">
        <div class="card-header">
          <img src="assets/images/products/prod3.jpg" class="card-img-prod" alt="aaa">
        </div>
        <div class="card-body">
          <h3 class="card-title">Afina 2020 fantázia harisnya</h3>
        </div>
        <div class="card-footer text-center">
          <p  class="card-text"><span class="offerprice">15 999 Ft</span></p>
          <!--        <button class="btn btn-outline-secondary btn-rounded"><i class="fa fa-chevron-right"></i> Részletek</button>-->
        </div>
      </div>



    </div>
    <div class="col">
      <div class="card" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" [matRippleRadius]="265">
        <div class="card-header">
          <img src="assets/images/products/prod4.jpg" class="card-img-prod" alt="aaa">
        </div>
        <div class="card-body">
          <h3 class="card-title">Gloss Chic fantázia leggings</h3>
        </div>
        <div class="card-footer text-center">
          <p  class="card-text"><span class="offerprice">15 999 Ft</span></p>
          <!--        <button class="btn btn-outline-secondary btn-rounded"><i class="fa fa-chevron-right"></i> Részletek</button>-->
        </div>
      </div>




    </div>
    <div class="col">
      <div class="card" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" [matRippleRadius]="265">
        <div class="card-header">
          <img src="assets/images/products/prod5.jpg" class="card-img-prod" alt="aaa">
        </div>
        <div class="card-body">
          <h3 class="card-title">Desire lux fantázia leggings</h3>
        </div>
        <div class="card-footer text-center">
          <p  class="card-text"><span class="offerprice">15 999 Ft</span></p>
          <!--        <button class="btn btn-outline-secondary btn-rounded"><i class="fa fa-chevron-right"></i> Részletek</button>-->
        </div>
      </div>







    </div>
    <div class="col">
      <div class="card" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" [matRippleRadius]="265">
        <div class="card-header">
          <img src="assets/images/products/prod3.jpg" class="card-img-prod" alt="aaa">
        </div>
        <div class="card-body">
          <h3 class="card-title">Afina 2020 fantázia harisnya</h3>
        </div>
        <div class="card-footer text-center">
          <p  class="card-text"><span class="offerprice">15 999 Ft</span></p>
          <!--        <button class="btn btn-outline-secondary btn-rounded"><i class="fa fa-chevron-right"></i> Részletek</button>-->
        </div>
      </div>







    </div>
  </div>
</div>


