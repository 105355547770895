<div class="container-fluid product-detail">

    <div class="row">
      <div class="col-xl-19">

        <div class="row">
          <div class="col-xxl-8 d-flex align-items-center">
<!--            <app-site-navigation *ngIf="siteNavigation"></app-site-navigation>-->

            <nav aria-label="breadcrumb">
              <ol class="breadcrumb breadcrumb-arrow">
                <li><a [routerLink]="'/'"><i class="fa fa-lg fa-home"></i>Kezdőlap</a></li>
                <li>
                  <a><i class="fa fa-lg fa-home"></i>Női</a>
                  <span>Harisnya</span>
                </li>
              </ol>
            </nav>


          </div>

          <div class="col-xxl-12 d-flex justify-content-center">
            <h1>{{product?.name}}</h1>
          </div>

          <div class="col d-flex justify-content-xxl-end justify-content-center align-items-center rating">
<!--            <span class="me-2 d-none d-xxxl-inline-block">Értékelés:</span>-->
            <ngx-input-star-rating [value]="averageRating" [disabled]="true" tooltip="Értékelés"  [delay]="500"></ngx-input-star-rating>
          </div>

        </div>

      <div class="row prod-card" *ngIf="product">

        <div class="col-24 col-lg-2 col-xxxl-3 p-0 order-2 order-lg-1 d-none d-xl-block">
          <div class="gallery-container bg-light">
          <div class="scroll-block">
            <img *ngFor="let p of product.pictures" src="{{p}}" class="img-fluid" [ngClass]="activeImage === p ? 'active' : ''" (click)="activeImage = p">
<!--            <img src="{{product.primaryPicture}}" class="img-fluid active">-->
<!--            <img src="{{product.primaryPicture}}" class="img-fluid">-->
<!--            <img src="{{product.primaryPicture}}" class="img-fluid">-->
<!--            <img src="{{product.primaryPicture}}" class="img-fluid">-->
<!--            <img src="{{product.primaryPicture}}" class="img-fluid">-->
<!--            <img src="{{product.primaryPicture}}" class="img-fluid">-->
            </div>
          </div>
        </div>

        <div class="col-lg-7 col-xxxl-6 order-1 order-lg-2">
          <div class="prod-detail-img-container">
<!--          <img src="{{product.primaryPicture}}" (click)="open('/api/' + product.primaryPicture)" alt="{{product?.name}}" class="product-img">-->

<!-- https://www.npmjs.com/package/ng-img-magnifier -->

            <ng-img-magnifier [thumbImage]='activeImage' [fullImage]='activeImage'
                              [resultHeight]='resultHeight'
                              [top]='resultTop' [right]='resultRight' class="product-img"
                              (click)="open()">
            </ng-img-magnifier>


          </div>

<!--          <div *ngFor="let image of _albums; let i=index">-->
<!--            <img [src]="image.thumb" (click)="open(i)" />-->
<!--          </div>-->

<!--          <div class="d-flex justify-content-center">-->
<!--          <div class="starburst">-->
<!--            <div *ngIf="product.salePrice" class="text" #priceContainer>-->
<!--              <span class="salesprice"><s>{{product.grossPrice}} Ft</s></span>-->
<!--              <span class="terms">helyett</span>-->
<!--              <span class="offerprice" [fittext]="true" [modelToWatch]="product" [activateOnResize]="true" [container]="priceContainer">15 400 Ft</span>-->
<!--            </div>-->
<!--            <div *ngIf="!product?.salePrice" class="text d-flex align-items-center" #priceContainer> <span class="normalprice" [fittext]="true" [modelToWatch]="product" [activateOnResize]="true" [container]="priceContainer">{{product.grossPrice | number}} Ft</span></div>-->
<!--          </div>-->
<!--          </div>-->
        </div>

        <div class="col-lg-15 order-3 order-lg-3" *ngIf="product">
          <div class="prod-detail-content-container">

            <div *ngIf="user" class="container-favourite-button">

              <span [ngClass]="!user ? 'disabled-favourite-button' : ''" *ngIf="!favouriteProducts || !favouriteProducts.includes(product.id)" class="fa-stack fa-lg" (click)="addToFavouriteProducts()" tooltip="{{ 'label.add.to.favourites' | translate}}">
                 <i class="fa fa-circle fa-stack-2x"></i>
                 <i class="fa fa-heart-add fa-stack-1x"></i>
              </span>

              <span [ngClass]="!user ? 'disabled-favourite-button' : ''" *ngIf="favouriteProducts && favouriteProducts.includes(product.id)" class="fa-stack fa-lg" (click)="deleteFromFavouriteProducts()" tooltip="{{ 'label.remove.from.favourites' | translate}}">
                 <i class="fa fa-circle fa-stack-2x"></i>
                 <i class="fa fa-heart-remove fa-stack-1x"></i>
              </span>

            </div>


            <mat-tab-group>
              <mat-tab label="Leírás">

                <div class="mat-content-container">
                  <p [innerHTML]="product?.describe"></p>
                </div><!-- mat-content-container end-->
              </mat-tab>
              <mat-tab label="Szállítás és fizetés">

                <p>Vásárlóink az alábbi átvételi módok közül választhatnak:</p>

                <ul><li>csomag kiszállítása futárszolgálattal a vásárló által megadott címre</li>
                  <li>csomag kiszállítása futárszolgálattal egy - a vásárló által megadott - átvételi pontra</li>
                </ul>

                <p>A megrendelt termékek kiszállítását a GLS- Hungary végzi. A csomagot a megrendelését vagy a banki utalás beérkezését követő 1-3 munkanapon belül fogja megkapni. 17.000 Ft alatti rendelés esetén a szállítási díj 1 460 Ft.<br><button (click)="openDeliveryModal()" class="btn btn-link"><i class="fa fa-chevron-right"></i> További szállítási információk</button></p>

              </mat-tab>
              <mat-tab label="Vélemények">
                <div *ngIf="!user" class="container-review">
                  <div>Jelentkezz be, hogy értékeld a terméket</div>
                </div>
                <div class="container-review">
                  <form [formGroup]="reviewForm" (ngSubmit)="submitReview()" *ngIf="user && user.reviews && !user.reviews.includes(product.id)">
                    <h2>Értékeld a terméket!</h2>

                    <!--                  <mat-form-field [hideRequiredMarker]="true" class="w-100">-->
                    <!--                    <input matInput-->
                    <!--                           placeholder="Neved"-->
                    <!--                           name="name"-->
                    <!--                           autofocus-->
                    <!--                           autocapitalize="none"-->
                    <!--                           autocomplete="off"-->
                    <!--                           [formControl]="nameFormControl"-->
                    <!--                           required-->
                    <!--                           mat-no-asterisk>-->
                    <!--                    <mat-error *ngIf="nameFormControl.hasError('required')">-->
                    <!--                      Nevet kötelező írni!-->
                    <!--                    </mat-error>-->
                    <!--                  </mat-form-field>-->



                    <ngx-input-star-rating formControlName="rating" class="me-2"></ngx-input-star-rating>
                    <mat-form-field [hideRequiredMarker]="true" class="w-100">
                      <mat-label *ngIf="!reviewForm.get('review').value">

                        Kattints az egyik csillagra és értékeld a terméket!
                      </mat-label>
                      <textarea matInput
                                formControlName="review"
                                required
                                placeholder="Értékelés">
                    </textarea>
                      <mat-error *ngIf="reviewForm.get('review').hasError('required')">
                        Értékelést szükséges írni!
                      </mat-error>
                    </mat-form-field>


                    <div class="row">
                      <div class="col-12"><button type="submit" class="btn btn-rounded btn-primary w-100 text-light" [disabled]="reviewForm.invalid">Elküld
                      </button></div>
                      <div class="col-12" *ngIf="!product.reviews || product.reviews.length === 0">Legyél az első aki értékeli ezt a terméket!</div>
                    </div>

                  </form>

                  <div class="mt-5" *ngIf="product.reviews && product.reviews.length > 0">
                    <h2 class="pt-5">Értékelések és vélemények:</h2>
                    <hr>
                    <app-review class="mb-3" *ngFor="let r of product.reviews" [review]="r"></app-review>
                  </div>

                </div>
              </mat-tab>
            </mat-tab-group>


            <!-- PROD DETAILS -->
            <div class="price-container">
            <h5>
              Ára: <span>{{(selectedProduct.salePrice ? selectedProduct.salePrice : selectedProduct.grossPrice) | number}} Ft</span>
            </h5>
            </div>

            <h5 class="text-center mb-5">Szín: {{colorSelected}}</h5>
            <div class="d-flex justify-content-center colors-container mb-5">

              <ng-container *ngFor="let color of colorData">
                  <img src="/assets/color/{{color.icon}}" class="color-chooser" [ngClass]="{ 'active': colorSelected == color.name }" tooltip="{{color.name}}" placement="bottom" alt="{{product?.name}}" (click)="setColor(color.name)">
              </ng-container>
            </div>

            <div class="row pt-5">
              <div class="col">
            <button class="btn btn-link mb-4" (click)="openSizeChartModal()"> <i class="fa fa-2x fa-size"></i>Mérettáblázat</button>
              </div>
              <div class="col">
            <h5 class="text-end text-md-center text-lg-end text-xxl-center mb-5">Méret: <span>{{sizeSelected}}</span></h5>
              </div>
              <div class="col d-none d-md-flex d-lg-none d-xxl-flex"></div>
            </div>


            <div class="button-size row g-3" btnRadioGroup>
              <ng-container *ngFor="let item of sizeData">
                <div class="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-8 col-xxl-6 col-xxxl-4">
                  <label *ngIf="!item.aviable" btnRadio="{{item.name}}" class="btn not-aviable"
                         [(ngModel)]="sizeSelected" (click)="openMissingProductModal()" tooltip="Hiányzó termék. Kattints ha tudni szeretnéd mikor lesz a termék elérhető!" placement="bottom">{{item.name}}</label>
                  <label *ngIf="item.aviable" btnRadio="{{item.name}}" class="btn"
                         [(ngModel)]="sizeSelected" (click)="setSize(item.name)">{{item.name}}</label>
                </div>
              </ng-container>
            </div>

            <div class="quantity-container">

            <div class="row">
              <div class="col-12 text-end"><h5>Mennyiség:</h5></div>
              <div class="col-12">
                <div class="btn-group btn-quantity mb-0" role="group" aria-label="Mennyiseg">
                  <button [disabled]="amountByProduct[selectedProduct.id] < 2" (click)="decreaseAmountForProduct(selectedProduct.id)" type="button" class="btn btn-sm btn-sub" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" tooltip="Elvesz" [delay]="1000"><i class="fa fa-minus"></i></button>
                  <input class="form-control form-control-sm price-input" [(ngModel)]="amountByProduct[selectedProduct.id]" type="number" (click)="$event.target.select()" />
                  <button (click)="increaseAmountForProduct(selectedProduct.id)" type="button" class="btn btn-sm btn-add" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" tooltip="Hozzáad" [delay]="1000"><i class="fa fa-plus"></i></button>
                </div>
              </div>
            </div>


<!--              IDEIGLENES ROW-->
<!--              <div class="row">-->
<!--                <div class="col-12 text-end"><h5>Mennyiség:</h5></div>-->
<!--                <div class="col-12">-->
<!--                  <div class="btn-group btn-quantity mb-0" role="group" aria-label="Mennyiseg">-->
<!--                    <button type="button" class="btn btn-sm btn-sub" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" tooltip="Elvesz" [delay]="1000"><i class="fa fa-minus"></i></button>-->
<!--                    <input class="form-control form-control-sm price-input" type="number" value="1" />-->
<!--                    <button type="button" class="btn btn-sm btn-add" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" tooltip="Hozzáad" [delay]="1000"><i class="fa fa-plus"></i></button>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              IDEIGLENES ROW VEGE  -->


            </div>

            <div class="d-flex justify-content-center">
            <button class="btn btn-rounded btn-secondary btn-lg text-nowrap w-75" (click)="addToCart()"><i class="fa fa-lg fa-cart-plus"></i>
              <span class="add-card-txt">Kosárba</span></button>
            </div>



<!--            <div class="row prod-table d-none d-lg-flex mt-5">-->
<!--              <div class="col-md-4 table-header">Cikkszám</div>-->
<!--              <div class="col-md-8 table-header"><span>Tulajdonságok</span></div>-->
<!--              <div class="col-md-3 table-header">Ára</div>-->
<!--              <div class="col-md-2 table-header d-flex justify-content-center">Készlet</div>-->
<!--              <div class="col-md-3 table-header d-flex justify-content-center">Mennyiség</div>-->
<!--            </div>-->

<!--            <div class="row prod-table">-->
<!--              <div class="col-24 divider"></div>-->
<!--            </div>-->


<!--            &lt;!&ndash; PRODUCT START&ndash;&gt;-->
<!--            <div class="row prod-table" *ngFor="let item of product.variations">-->

<!--              <div class="col-md-4 table-col d-flex justify-content-between justify-content-lg-left">-->
<!--                <div class="text-nowrap pt-4 pt-md-0"><span class="d-md-none font-weight-bold">Cikkszám:</span> {{item.articleNumber}}</div>-->
<!--                <span class="d-md-none me-5">Raktáron <i class="fa fa-lg fa-check"></i></span>-->
<!--              </div>-->
<!--              <div class="col-md-8 table-col">-->
<!--                <span class="d-md-none table-header">Tulajdonságok:</span>-->
<!--                <table>-->
<!--                  <tr *ngFor="let p of (item.properties | keyvalue)">-->
<!--                    <td>{{p.key | translate}}</td>-->
<!--                    <td>{{p.value}}</td>-->
<!--                  </tr>-->
<!--                </table>-->

<!--              </div>-->
<!--              <div class="col-24 col-md-3 table-col"><span class="d-md-none table-header">Ára:</span> <span class="lead font-weight-bold"> {{(item.salePrice ? item.salePrice : item.grossPrice) | number}} Ft</span></div>-->

<!--              <div class="col-2 d-none d-md-flex justify-content-center"><i class="fa fa-lg fa-check mt-4"></i></div>-->

<!--              <div class="col-12 col-md-3">-->
<!--                <div class="btn-group btn-quantity mb-0" role="group" aria-label="Mennyiseg">-->
<!--                  <button [disabled]="amountByProduct[item.id] < 2" (click)="decreaseAmountForProduct(item.id)" type="button" class="btn btn-sm btn-sub" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" tooltip="Elvesz" [delay]="1000"><i class="fa fa-minus"></i></button>-->
<!--                  <input class="form-control form-control-sm price-input" [(ngModel)]="amountByProduct[item.id]" type="number" (click)="$event.target.select()" />-->
<!--                  <button (click)="increaseAmountForProduct(item.id)" type="button" class="btn btn-sm btn-add" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" tooltip="Hozzáad" [delay]="1000"><i class="fa fa-plus"></i></button>-->
<!--                </div>-->
<!--              </div>-->

<!--              <div class="col-12 text-end col-md-4">-->
<!--                <button class="btn btn-rounded btn-danger btn-sm text-nowrap" (click)="addToCart(item)"><i class="fa fa-lg fa-cart-plus"></i> <span class="add-card-txt d-xl-none d-xxxl-inline">Kosárba</span></button>-->
<!--              </div>-->


<!--              <div class="col-24 divider"></div>-->
<!--            </div>-->
<!--            &lt;!&ndash; PRODUCT END&ndash;&gt;-->


          </div>
        </div>
      </div>
      </div>

        <div class="col-xl-5 cart-total-container">

          <div class="cart-total">
          <h5><span class="fa fa-shopping-cart"></span> Kosár összesen</h5>

          <div class="row">
            <div class="col-8">
              <b>Részösszeg</b>
            </div>
            <div class="col-16 text-end">
              2 650 Ft
            </div>
          </div>
            <div class="col-24 divider"></div>

            <div class="row">
              <div class="col-8">
                <b>Szállítás</b>
              </div>
              <div class="col-16 text-end">
                1500 Ft
              </div>
            </div>
            <div class="col-24 divider"></div>

            <div class="row lead">
              <div class="col-8">
                Összeg
              </div>
              <div class="col-16 text-end text-secondary">
                4 999 Ft
              </div>
            </div>
            <div class="col-24 divider"></div>

            <p class="text-center">
            <button class="btn btn-info btn-rounded text-white mx-auto" (click)="navigateToCheckout()"><i class="fa fa-chevron-right"></i> Tovább a pénztárhoz </button>
            </p>

        </div>



        </div><!-- col END-->

  </div><!--  cart total END-->



</div>
<!--  container-fluid END-->


<div>
</div>

<div class="container-fluid headline-block bg-light">
  <h2>Hasonló termékek</h2>
</div>

<div class="container-fluid">

  <div class="product-list">
    <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 row-cols-xxl-6">
      <div class="col">
        <div class="card" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" [matRippleRadius]="265">
          <div class="card-header">
            <img src="assets/images/products/prod1.jpg" class="card-img-prod" alt="aaa">
          </div>
          <div class="card-body">
            <h3 class="card-title">Conte Elegant Fantasy Dolce Vita</h3>
          </div>
          <div class="card-footer text-center">

            <p class="card-text"><span class="offerprice">4999 Ft</span></p>
            <!--          <button class="btn btn-outline-secondary btn-rounded"><i class="fa fa-chevron-right"></i> Részletek</button>-->
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" [matRippleRadius]="265">
          <div class="card-header">
            <img src="assets/images/products/prod2.jpg" class="card-img-prod" alt="aaa">
          </div>
          <div class="card-body">
            <h3 class="card-title">Femme 2020 fantázia harisnya</h3>
          </div>
          <div class="card-footer text-center">
            <p  class="card-text"><span class="offerprice">24 899 Ft</span></p>
            <!--        <button class="btn btn-outline-secondary btn-rounded"><i class="fa fa-chevron-right"></i> Részletek</button>-->
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" [matRippleRadius]="265">
          <div class="card-header">
            <img src="assets/images/products/prod3.jpg" class="card-img-prod" alt="aaa">
          </div>
          <div class="card-body">
            <h3 class="card-title">Afina 2020 fantázia harisnya</h3>
          </div>
          <div class="card-footer text-center">
            <p  class="card-text"><span class="offerprice">15 999 Ft</span></p>
            <!--        <button class="btn btn-outline-secondary btn-rounded"><i class="fa fa-chevron-right"></i> Részletek</button>-->
          </div>
        </div>







      </div>
      <div class="col">
        <div class="card" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" [matRippleRadius]="265">
          <div class="card-header">
            <img src="assets/images/products/prod4.jpg" class="card-img-prod" alt="aaa">
          </div>
          <div class="card-body">
            <h3 class="card-title">Gloss Chic fantázia leggings</h3>
          </div>
          <div class="card-footer text-center">
            <p  class="card-text"><span class="offerprice">15 999 Ft</span></p>
            <!--        <button class="btn btn-outline-secondary btn-rounded"><i class="fa fa-chevron-right"></i> Részletek</button>-->
          </div>
        </div>







      </div>
      <div class="col">
        <div class="card" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" [matRippleRadius]="265">
          <div class="card-header">
            <img src="assets/images/products/prod5.jpg" class="card-img-prod" alt="aaa">
          </div>
          <div class="card-body">
            <h3 class="card-title">Desire lux fantázia leggings</h3>
          </div>
          <div class="card-footer text-center">
            <p  class="card-text"><span class="offerprice">15 999 Ft</span></p>
            <!--        <button class="btn btn-outline-secondary btn-rounded"><i class="fa fa-chevron-right"></i> Részletek</button>-->
          </div>
        </div>







      </div>
      <div class="col">
        <div class="card" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true" [matRippleRadius]="265">
          <div class="card-header">
            <img src="assets/images/products/prod3.jpg" class="card-img-prod" alt="aaa">
          </div>
          <div class="card-body">
            <h3 class="card-title">Afina 2020 fantázia harisnya</h3>
          </div>
          <div class="card-footer text-center">
            <p  class="card-text"><span class="offerprice">15 999 Ft</span></p>
            <!--        <button class="btn btn-outline-secondary btn-rounded"><i class="fa fa-chevron-right"></i> Részletek</button>-->
          </div>
        </div>







      </div>
    </div>
  </div>


</div>




<!-- DELIVERY MODAL ------------------------------------------ -->
<ng-template #deliveryModal>

  <app-delivery></app-delivery>

</ng-template>

<!-- MISSING PRODUCT MODAL ------------------------------------------ -->
<ng-template #missingProduct>

  <app-delivery></app-delivery>

</ng-template>
