export class WaterLevel {
  name: string;
  level: string;
  lnv: string;
  updated: Date;

  constructor(name: string, level: string, lnv: string, updated: Date) {
    this.name = name;
    this.level = level;
    this.lnv = lnv;
    this.updated = updated;
  }
}
