import { Component, OnDestroy, OnInit } from '@angular/core';
import { Product } from '../../../model/Product';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ProductService } from '../../../service/product.service';
import { PaginationInstance } from 'ngx-pagination';
import { Store } from '@ngrx/store';
import { PaginationState } from '../../../store/pagination/PaginationReducer';
import { CategoryState } from '../../../store/category/CategoryReducer';
import { initCategoriesSelector } from '../../../store/category/CategorySelector';
import { Category } from '../../../model/Category';
import { initDedicatedFiltersSelector, initFiltersSelector, initOrderingsSelector } from '../../../store/filter/FilterSelector';
import { FilterState } from '../../../store/filter/FilterReducer';
import { FilterService } from '../../../service/filter.service';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { ProductState } from '../../../store/product/ProductReducer';
import { AddProduct } from '../../../store/product/ProductActions';
import { UtilsService } from '../../../service/utils.service';
import { AbstractProductListingComponent } from '../abstract-product-listing/abstract-product-listing.component';
import { Constants } from '../../../model/Constants';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html'
})
export class ProductListComponent extends AbstractProductListingComponent implements OnInit, OnDestroy {

  pagination: PaginationInstance;

  title: string;
  mainCategory: Category;
  subCategory: Category;

  categoryParam: string;
  subCategoryParam: string;
  subSubCategoryParam: string;

  dedicatedFilter: any[];
  dedicatedFilters: any;
  dedicatedFilterValue: string = 'all';
  selectedDedicatedFilterId: number;

  trackByIdentity = (index: number, item: any) => item.articleNumber;

  constructor(private readonly productService: ProductService,
              private readonly paginationStore: Store<PaginationState>,
              private readonly categoryStore: Store<CategoryState>,
              private readonly filterStore: Store<FilterState>,
              private readonly router: Router,
              readonly productStore: Store<ProductState>,
              readonly filterService: FilterService,
              readonly activatedRoute: ActivatedRoute) {
    super(filterService, productStore, activatedRoute);
  }

  addProductsToStore(products: Array<Product>) {
    this.productStore.dispatch(new AddProduct(UtilsService.convertProductsToJson(products)));
  }

  ngOnInit(): void {
    this.isFirstLoading = true;

    this.subscriptions.push(
      this.filterStore.select(initFiltersSelector).subscribe((allFilters) => {
        this.allFilters = allFilters;
      })
    );

    this.subscriptions.push(
      this.filterStore.select(initOrderingsSelector).subscribe((orderings) => {
        this.orderings = orderings;
        this.orderBy = this.orderings[0];
      })
    );

    this.subscriptions.push(
      this.filterStore.select(initDedicatedFiltersSelector).subscribe((filters) => {
        this.dedicatedFilters = filters;
        console.log(filters)
      })
    );

    this.subscriptions.push(
      this.categoryStore.select(initCategoriesSelector).subscribe((categories) => {
        this.categories = categories;
      })
    );

    this.products = this.activatedRoute.snapshot.data['preload'].result;
    this.addProductsToStore(this.products);

    this.initPagination();
    this.initCategoriesAndFilters(this.activatedRoute.snapshot.params);

    this.subscriptions.push(
      this.activatedRoute.params.subscribe(params => {
        if (this.isFirstLoading) {
          return;
        }
        this.initCategoriesAndFilters(params);
        this.paramsChanged = true;
      })
    );

    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe((params) => {
        if (!this.isFirstLoading) {
          this.queryParamsChanged = true;
        }
        this.updatePagination(params);
        if (this.isFirstLoading) {
          this.isFirstLoading = false;
          return;
        }
        if (Object.keys(params).length > 0) {
          console.log('QUERY PARAMS CHANGED')
          // TODO in case of searching, call this.productService.searchProducts
          this.subscriptions.push(
            this.productService.getProductsCommonVersion({
                categoryId: this.subCategory.id,
                dedicatedFilterOption: UtilsService.isNotUndefinedOrNull(this.selectedDedicatedFilterId) ? this.selectedDedicatedFilterId : undefined,
                properties: this.getProperties(),
                manufacturers: this.getManufacturers()
              },
              {
                currentPage: this.pagination.currentPage,
                itemsPerPage: this.pagination.itemsPerPage
              },
              this.getOrderingType(),
              this.getPriceRangeQuery()).subscribe((resp) => {
              this.products = resp.result;
              this.reInitPagination(resp.pagination.totalItems, resp.pagination.itemsPerPage, resp.pagination.currentPage);
            })
          );
        } else if (!this.paramsChanged && this.subCategoryParam === this.subCategory.link) {
          console.log('NO PARAMS')
          // TODO in case of searching, call this.productService.searchProducts
          this.subscriptions.push(
            this.productService.getProductsCommonVersion({
              categoryId: this.subCategory.id,
              dedicatedFilterOption: UtilsService.isNotUndefinedOrNull(this.selectedDedicatedFilterId) ? this.selectedDedicatedFilterId : undefined
            }, {
              currentPage: this.pagination.currentPage,
              itemsPerPage: this.pagination.itemsPerPage
            }, this.getOrderingType()).subscribe((resp) => {
              this.products = resp.result;
              this.reInitPagination(resp.pagination.totalItems, resp.pagination.itemsPerPage, resp.pagination.currentPage);
              this.productStore.dispatch(new AddProduct(UtilsService.convertProductsToJson(resp.result)));
            })
          )
        }

        this.paramsChanged = false;
      })
    );
  }

  initSubcategoryAndFilter() {
    this.subCategory = this.mainCategory.children.find(sc => sc.link === this.subCategoryParam);
    this.filter = this.allFilters[this.subCategory.id];
    this.activeFilter = this.filterService.initActiveFilter(this.filter, this.activatedRoute.snapshot.queryParams);
    this.title = this.subCategory.longName;
    // this.dedicatedFilter = this.dedicatedFilters[this.subCategory.id];
  }

  initCategoriesAndFilters(params: Params) {
    this.categoryParam = params['category'];
    this.subCategoryParam = params['subCategory'];
    this.subSubCategoryParam = params['subSubCategory'];
    if (params['subSubCategory']) {
      this.dedicatedFilterValue = params['subSubCategory'];
    }

    if (this.activatedRoute.snapshot.queryParams['rendezes']) {
      this.orderBy = this.orderings.find(o => o.link === this.activatedRoute.snapshot.queryParams['rendezes']);
    }

    this.mainCategory = this.categories.find(c => c.link === this.categoryParam);

    if (this.isFirstLoading && this.categoryParam && this.subCategoryParam) {
      console.log('FIRST LOADING')
      if (this.categoryParam && this.subCategoryParam) {
        this.initSubcategoryAndFilter();
      } else {
        this.title = 'Keresés';
      }
      this.queryParamsChanged = false;
      return;
    }

    if (this.categoryParam && this.subCategoryParam) {
      this.initSubcategoryAndFilter();
      this.updatePagination(params);

      if (!this.queryParamsChanged) {
        console.log('CATEGORY CHANGED')
        // TODO cancel request if the filters changed in a while
        this.subscriptions.push(
          this.productService.getProductsCommonVersion({
            categoryId: this.subCategory.id,
            dedicatedFilterOption: UtilsService.isNotUndefinedOrNull(this.selectedDedicatedFilterId) ? this.selectedDedicatedFilterId : undefined
          }, {currentPage: this.pagination.currentPage, itemsPerPage: this.pagination.itemsPerPage}, this.getOrderingType()).subscribe((resp) => {
            this.products = resp.result;
            this.reInitPagination(resp.pagination.totalItems, resp.pagination.itemsPerPage, resp.pagination.currentPage);
            this.productStore.dispatch(new AddProduct(UtilsService.convertProductsToJson(resp.result)));
          })
        );
      } else {
        this.queryParamsChanged = false;
      }
      return;
    }

    const manufacturer = params['manufacturer'];

    if (manufacturer) {
      this.subscriptions.push(
        // this.productService.searchProducts('manufacturer=' + manufacturer).subscribe((products) => {
        this.productService.getProductsCommonVersion({
          manufacturer: manufacturer,
          dedicatedFilterOption: UtilsService.isNotUndefinedOrNull(this.selectedDedicatedFilterId) ? this.selectedDedicatedFilterId : undefined
        }, {currentPage: this.pagination.currentPage, itemsPerPage: this.pagination.itemsPerPage}, this.getOrderingType()).subscribe((resp) => {
          this.products = resp.result;
          this.reInitPagination(resp.pagination.totalItems, resp.pagination.itemsPerPage, resp.pagination.currentPage);
          this.productStore.dispatch(new AddProduct(UtilsService.convertProductsToJson(resp.result)));
        })
      );
      this.title = 'results';
      return;
    }
  }


  changeDedicatedFilter(value: MatButtonToggleChange) {
    // console.log(value)
    // const filter = this.dedicatedFilter.find(df => df.id == value.value);
    // this.navigate(filter.link);
    if (value.value === 'all') {
      this.selectedDedicatedFilterId = null;
      this.navigate('all')
    } else {
      this.selectedDedicatedFilterId = this.dedicatedFilter.find(df => df.link === value.value).id;
      this.navigate(value.value);
    }
  }

  // TODO clean code
  getProductLink(): string {
    if (this.dedicatedFilterValue === 'all') {
      return this.categoryParam && this.subCategoryParam && this.subSubCategoryParam ? '/' + this.categoryParam + '/' + this.subCategoryParam + '/' + this.subSubCategoryParam : '/' + Constants.PRODUCT_PAGE_URL;
    } else {
      return this.categoryParam && this.subCategoryParam && this.subSubCategoryParam ? '/' + this.categoryParam + '/' + this.subCategoryParam + '/' + this.dedicatedFilterValue : '/' + Constants.PRODUCT_PAGE_URL
    }
  }

  navigate(dedicatedFilterValue = this.dedicatedFilterValue) {
    if (dedicatedFilterValue === 'all') {
      this.router.navigateByUrl(`${this.categoryParam }/${this.subCategoryParam}${this.queryParams}`);
    } else {
      this.router.navigateByUrl(`${this.categoryParam }/${this.subCategoryParam}/${dedicatedFilterValue}${this.queryParams}`);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
