<div class="modal-header">

  <h4 class="modal-title">Hiányzó termék</h4>
  <i class="fa fa-2x fa-times" (click)="cancelMissingProcuctModal()"></i>
</div>

<div class="modal-body modal-info">

  <h2 class="mt-5">Töltsd ki az űrlapot és majd értesítést kapsz ha a termék raktáron lesz!</h2>

  <div class="form-floating mb-5">
    <input type="text" class="form-control" id="floatingName" placeholder="Név">
    <label for="floatingName">Név</label>
  </div>

  <div class="form-floating">
    <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com">
    <label for="floatingInput">E-mail cím</label>
  </div>


</div>

<div class="modal-footer">
  <button type="button" class="btn me-2 btn-outline-dark" (click)="cancelMissingProcuctModal()">Bezár</button>
  <button type="button" class="btn btn-primary">Elküld</button>
</div>
