import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PersonalData } from '../../../model/PersonalData';
import { FormService } from '../../../service/form.service';
import { Store } from '@ngrx/store';
import { UserState } from '../../../store/user/UserReducer';
import { UpdatePersonalData } from '../../../store/user/UserActions';
import { ProfileService } from '../../../service/profile.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-personal-data',
  templateUrl: './personal-data.component.html'
})
export class PersonalDataComponent implements OnInit {

  @Input()
  personalData: PersonalData;

  personalDataForm: FormGroup;
  companyChecked = false;
  customCompanyPanelClass = 'company-panel';

  constructor(private readonly formService: FormService,
              private readonly toastrService: ToastrService,
              private readonly profileService: ProfileService,
              private readonly userStore: Store<UserState>) { }

  ngOnInit(): void {
    this.personalDataForm = this.formService.getPersonalDataForm(this.personalData);
  }

  savePersonalData() {
    this.profileService.updatePersonalData(this.personalDataForm.value).subscribe(() => {
      this.toastrService.success('personal.data.updated');
      this.userStore.dispatch(new UpdatePersonalData(this.personalDataForm.value));
      this.personalDataForm.reset(this.personalDataForm.value);
    }, err => {
      console.log(err);
    });
  }

  resetPersonalData() {
    this.personalDataForm.reset(this.personalData);
  }

  companyCheck() {
    this.companyChecked = this.companyChecked !== true;
  }
}
