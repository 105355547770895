import { Component, Input, OnInit } from '@angular/core';
import { Product } from '../../../model/Product';
import { Store } from '@ngrx/store';
import { CartState } from '../../../store/cart/CartReducer';
import { Router } from '@angular/router';
import { CategoryState } from '../../../store/category/CategoryReducer';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html'
})
export class ProductComponent implements OnInit {

  @Input()
  product: Product;
  @Input()
  link: string;

  minPrice: number;
  maxPrice: number;

  productLink: string;

  constructor(private readonly cartStore: Store<CartState>,
              private readonly categoryStore: Store<CategoryState>,
              private router: Router) {
  }

  ngOnInit(): void {
    this.productLink = `${this.link}/${this.product.articleNumber}`;
    this.minPrice = this.product.grossPrice;
    this.maxPrice = this.product.grossPrice;
    // this.initMinPrice();
    // this.initMaxPrice();
  }

  getLowestPrice() {
    return Math.min.apply(Math, this.product.variations.map(p => p.grossPrice));
  }

  initMinPrice() {
    this.minPrice = Math.min.apply(Math, this.product.variations.map(p => {
      if (p.grossPriceCarriage > 0) {
        return p.grossPriceCarriage;
      }
      return p.grossPrice;
    }));
  }

  initMaxPrice() {
    this.maxPrice = Math.max.apply(Math, this.product.variations.map(p => {
      if (p.grossPriceCarriage > 0) {
        return p.grossPriceCarriage;
      }
      return p.grossPrice;
    }));
    // this.maxPrice = Math.max.apply(Math, this.product.variations.map(p => p.grossPrice));
  }

  // navigateToProduct() {
  //   this.router.navigateByUrl(`${this.link}/${this.product.articleNumber}`);
  // }
}
