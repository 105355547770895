import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { Filter } from '../../../../model/Filter';
import { ActiveFilter } from '../../../../model/ActiveFilter';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FilterService } from '../../../../service/filter.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterComponent),
      multi: true
    }
  ]
})
export class FilterComponent implements OnInit, ControlValueAccessor {

  @Input()
  filter: Filter;

  @Input()
  initialValue: ActiveFilter;

  activeFilter: ActiveFilter;

  isFiltersEmpty: boolean = true;

  numberOfProperties: number;

  propagateChange: any = () => {};

  constructor(private readonly filterService: FilterService) {
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(obj: any): void {
    if (obj) {
      this.activeFilter = obj;
      this.isFiltersEmpty = this.filterService.getFilterIsEmpty(this.activeFilter, this.filter);
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  ngOnInit(): void {
    this.numberOfProperties = Object.keys(this.filter.properties).length;
    this.activeFilter = this.initialValue;
    this.isFiltersEmpty = this.filterService.getFilterIsEmpty(this.activeFilter, this.filter);
  }

  propertyChanged() {
    this.isFiltersEmpty = this.filterService.getFilterIsEmpty(this.activeFilter, this.filter);
    this.propagateChange(this.activeFilter);
  }

  deleteFilters() {
    this.filterService.initPriceRange(this.activeFilter, this.filter.priceRange.min, this.filter.priceRange.max);
    this.filterService.clearFilters(this.activeFilter);
    this.isFiltersEmpty = true;
    this.propagateChange(this.activeFilter);
  }
}
