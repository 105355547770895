import { Action } from '@ngrx/store';

export enum FilterActionTypes {
  InitFilters = '[INIT_FILTERS]'
}

export class InitFilters implements Action {
  readonly type = FilterActionTypes.InitFilters;

  constructor(public filters: any, public dedicatedFilters: any) {
  }
}

export type FilterActions =
  InitFilters;
