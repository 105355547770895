<div class="container mt-md-5">
  <div class="row">
    <div class="col-md-18 user-edit-container">
    <router-outlet></router-outlet>
    </div>
    <div class="col-md-6 pt-3 user-nav-container">
      <ul class="list-group">
        <li class="list-group-item active">Profilom</li>
        <li class="list-group-item list-group-item-action" [routerLink]="'sajat-fiok'"><i class="fa fa-lg fa-user-o"></i> Adatok</li>
        <li class="list-group-item list-group-item-action" [routerLink]="'rendeleseim'"><i class="fa fa-lg fa-cart-check"></i> Rendelések</li>
        <li class="list-group-item list-group-item-action" [routerLink]="'ertekelesek'"><i class="fa fa-lg fa-star"></i> Értékelések</li>
        <li class="list-group-item list-group-item-action" [routerLink]="'kedvencek'"><i class="fa fa-lg fa-heart-o"></i> Kedvencek</li>
      </ul>

    </div>
</div>
</div>
