import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Product } from '../model/Product';
import { Query } from '../model/Query';
import { Pagination } from '../model/Pagination';
import { OrderingType } from '../model/OrderingType';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private httpClient: HttpClient) {
  }

  // getProductsByIds(ids: Array<number>) {
  //   // return this.httpClient.post('/api/products', ids);
  //   return of(TEST_PRODUCTS.map(tp => Product.buildProduct(tp)).filter(p => ids.includes(p.articleNumber)));
  // }

  getProductsCommonVersion(query: Query, pagination: Pagination, ordering: OrderingType, priceRange?: DoubleRange): Observable<any> {
    return of({
      result: [{
        "id": 10565,
        "name": "Leggings for girls CONTE ZOE",
        "link": "leggings for girls conte zoe",
        "brand": "Conte elegant",
        "primaryPicture": "https://akciosillat.hu/img/5166/5011417566589/5011417566589.jpg?time=1651315494",
        "material": "cotton 71%, elastane 5%, polyester 24%",
        "fullDescription": "Leggings for girls made of knitted fabric \"jeans\" - long, tight, with side seams. on the back - yoke and patch pockets with decorative applique (embroidery). on the front of the product - imitation codpiece and side pockets. side seams, patch pockets, yoke, seat seam with finishing stitches with contrasting threads. the upper section of the product is treated with a fitted belt with an elastic band inside.",
        "grossPrice": 10.2,
        "countAvailable": 3,
        "description": "Leggings for girls ZOE, s. 128-68, sea-green",
        "pictures": ["https://conteb2b.com/static/uploads/models_photos/9e/l5/uf/9eL5UFyfQIq58H0w.jpg?02e4ddcc54a9bc3cae5d628e0deeefc1"],
        "articleNumber": "14С-062ДЛСП"
      }, {
        "id": 10568,
        "name": "CONTE VIRGINIA Women's pants ",
        "link": "conte virginia women's pants ",
        "brand": "Conte elegant",
        "primaryPicture": "https://akciosillat.hu/img/5166/5011417566589/5011417566589.jpg?time=1651315494",
        "material": "viscose 100%",
        "fullDescription": "Women's trousers made of printed textile material of a loose fit with side pockets. The bottom of the product is treated with cuffs with an elastic band inside. The upper section of the product is treated with a fitted belt with an elastic band on the sides and narrow ties inserted in front of the grommets.",
        "grossPrice": 10.31,
        "countAvailable": 0,
        "description": "Women's trousers VIRGINIA, s. 176-80-108, blue",
        "pictures": ["https://conteb2b.com/static/uploads/models_photos/cw/nt/cg/cWNtCG1izubktxQH.jpg?c4110a4f3a34826ec434c9039791e49b"],
        "articleNumber": "15С-667БСП"
      }, {
        "id": 10571,
        "name": "CONTE VARIETA",
        "link": "conte varieta",
        "primaryPicture": "https://akciosillat.hu/img/5166/5011417566589/5011417566589.jpg?time=1651315494",
        "brand": "Conte elegant",
        "material": "cotton 93%, elastane 7%",
        "fullDescription": "Leggings for girls CONTE ELEGANT VARIETA, s.110,116-56, blue",
        "grossPrice": 6.46,
        "countAvailable": 86,
        "description": "Leggings for girls CONTE ELEGANT VARIETA, s.110,116-56, blue",
        "pictures": ["https://conteb2b.com/static/uploads/models_photos/l7/yw/pz/L7yWpzoFW4qg3msg.jpg?02e4ddcc54a9bc3cae5d628e0deeefc1"],
        "articleNumber": "15С-061ДЛСП"
      }, {
        "id": 10582,
        "name": "CONTE TENSION SOFT 40 (1 pair)",
        "link": "conte tension soft 40 (1 pair)",
        "primaryPicture": "https://akciosillat.hu/img/5166/5011417566589/5011417566589.jpg?time=1651315494",
        "brand": "Conte elegant",
        "material": "polyamide 83%, elastane 17%",
        "fullDescription": "Tight elastic knee socks with a sealed elastic band and a toe, for sensitive skin with vitamin E, universal size",
        "grossPrice": 1.01,
        "countAvailable": 3938,
        "description": "Women's knee socks TENSION SOFT 40 (1 pair), s. 36-39, natural",
        "pictures": ["https://conteb2b.com/static/uploads/models_photos/o8/o1/ib/o8o1IByYHTwQ3UhO.jpg?ee120ef06b05e402197b7bd20920b07b"],
        "articleNumber": "14С-57СП"
      }, {
        "id": 10583,
        "name": "CONTE TENSION SOFT 40 (1 pair)",
        "link": "conte tension soft 40 (1 pair)",
        "primaryPicture": "https://akciosillat.hu/img/5166/5011417566589/5011417566589.jpg?time=1651315494",
        "brand": "Conte elegant",
        "material": "polyamide 83%, elastane 17%",
        "fullDescription": "Thick elastic socks with a sealed elastic band and toe, for sensitive skin with vitamin E, universal size",
        "grossPrice": 0.8,
        "countAvailable": 2848,
        "description": "Women's socks TENSION SOFT 40 (1 pair), s. 36-39, natural",
        "pictures": ["https://conteb2b.com/static/uploads/models_photos/ex/ge/ov/ExGEOVnMWnNbRXiV.jpg?b701d8ea54d68a11232e2a08771cbdf6"],
        "articleNumber": "14С-55СП"
      }, {
        "id": 10584,
        "name": "CONTE TENSION SOFT 20 (1 pair)",
        "link": "conte tension soft 20 (1 pair)",
        "primaryPicture": "https://akciosillat.hu/img/5166/5011417566589/5011417566589.jpg?time=1651315494",
        "brand": "Conte elegant",
        "material": "polyamide 87%, elastane 13%",
        "fullDescription": "Thin elastic knee socks with a sealed elastic band and a toe, for sensitive skin with vitamin E, universal size",
        "grossPrice": 0.96,
        "countAvailable": 8688,
        "description": "Women's knee socks TENSION SOFT 20 (1 pair), s. 36-39, natural",
        "pictures": ["https://conteb2b.com/static/uploads/models_photos/gb/ez/fx/GbezfXWmF03OBWUH.jpg?ee120ef06b05e402197b7bd20920b07b"],
        "articleNumber": "14С-58СП"
      }, {
        "id": 10585,
        "name": "CONTE TENSION SOFT 20 (1 pair)",
        "link": "conte tension soft 20 (1 pair)",
        "primaryPicture": "https://akciosillat.hu/img/5166/5011417566589/5011417566589.jpg?time=1651315494",
        "brand": "Conte elegant",
        "material": "polyamide 87%, elastane 13%",
        "fullDescription": "Thin elastic socks with a sealed elastic band and toe, for sensitive skin with vitamin E, universal size",
        "grossPrice": 0.76,
        "countAvailable": 7309,
        "description": "Women's socks TENSION SOFT 20 (1 pair), s. 36-39, natural",
        "pictures": ["https://conteb2b.com/static/uploads/models_photos/zq/up/yn/ZQUPYNZ6gYsmZrWE.jpg?b701d8ea54d68a11232e2a08771cbdf6"],
        "articleNumber": "14С-56СП"
      }].map(p => {
        return {
          ...p,
          reviews: [],
          pictures: ["https://akciosillat.hu/img/5166/5011417566589/5011417566589.jpg?time=1651315494",
            "https://www.neptanckellek.hu/img/42348/2808000/456x456,r/harisnya-pamut.jpg?time=1587562741",
            "https://gate.bwcdn.net/media/2021/07/4/2/bodkovane-pancuchy-6107488-89-4230967-size-fr-large-c-v-1-compressed.jpg",
            "https://pix.bonprix.pl/imgc/0/0/1/7/2/9/6/4/3/1/_640/17296431/harisnya-es-harisnyatarto-20den-fekete.jpg",
            "https://harisnyaplaza.hu/shop_ordered/61741/pic/cikk60min.jpg"],
          primaryPicture: 'https://akciosillat.hu/img/5166/5011417566589/5011417566589.jpg?time=1651315494',
          variations: [
            {
              ...p,
              articleNumber: p.articleNumber + '_c'
            }
          ],
          describe: p.fullDescription
        }
      }),
      pagination: {
        totalItems: 7,
        itemsPerPage: 12,
        currentPage: 1
      }
    })
    // return this.httpClient.post<any>(`/api/product/find-products`, {pagination: pagination, filter: {priceRange: priceRange ? priceRange : undefined, ...query}, order: ordering});
  }

  searchProducts(query: string) {
    const params = new HttpParams().set('query', query);
    return this.httpClient.get<Array<Product>>(`/api/search`, {params: params});
    // return of(TEST_PRODUCTS.map(tp => Product.buildProduct(tp)).slice(0, 6));
  }

  getProductById(id: number): Observable<Product> {
    // const params = new HttpParams().set('id', id);
    // return this.httpClient.get<Product>(`/api/product/find-product`, {params: params});
    const p = {
      "id": 10585,
      "name": "CONTE TENSION SOFT 20 (1 pair)",
      "link": "conte tension soft 20 (1 pair)",
      "primaryPicture": "https://akciosillat.hu/img/5166/5011417566589/5011417566589.jpg?time=1651315494",
      "brand": "Conte elegant",
      "material": "polyamide 87%, elastane 13%",
      "fullDescription": "Thin elastic socks with a sealed elastic band and toe, for sensitive skin with vitamin E, universal size",
      "grossPrice": 0.76,
      "countAvailable": 7309,
      "description": "Women's socks TENSION SOFT 20 (1 pair), s. 36-39, natural",
      "pictures": ["https://akciosillat.hu/img/5166/5011417566589/5011417566589.jpg?time=1651315494",
        "https://www.neptanckellek.hu/img/42348/2808000/456x456,r/harisnya-pamut.jpg?time=1587562741",
        "https://gate.bwcdn.net/media/2021/07/4/2/bodkovane-pancuchy-6107488-89-4230967-size-fr-large-c-v-1-compressed.jpg",
        "https://pix.bonprix.pl/imgc/0/0/1/7/2/9/6/4/3/1/_640/17296431/harisnya-es-harisnyatarto-20den-fekete.jpg",
        "https://harisnyaplaza.hu/shop_ordered/61741/pic/cikk60min.jpg"],
      "articleNumber": "14С-56СП"
    };
    // @ts-ignore
    return of({
      ...p,
      variations: [
        {
          "id": 10585,
          "name": "CONTE TENSION SOFT 20 (1 pair)",
          "link": "conte tension soft 20 (1 pair)",
          "primaryPicture": "https://akciosillat.hu/img/5166/5011417566589/5011417566589.jpg?time=1651315494",
          "brand": "Conte elegant",
          "material": "polyamide 87%, elastane 13%",
          "fullDescription": "Thin elastic socks with a sealed elastic band and toe, for sensitive skin with vitamin E, universal size",
          "grossPrice": 2200,
          "countAvailable": 7309,
          "description": "Women's socks TENSION SOFT 20 (1 pair), s. 36-39, natural",
          "pictures": ["https://akciosillat.hu/img/5166/5011417566589/5011417566589.jpg?time=1651315494",
            "https://www.neptanckellek.hu/img/42348/2808000/456x456,r/harisnya-pamut.jpg?time=1587562741",
            "https://gate.bwcdn.net/media/2021/07/4/2/bodkovane-pancuchy-6107488-89-4230967-size-fr-large-c-v-1-compressed.jpg",
            "https://pix.bonprix.pl/imgc/0/0/1/7/2/9/6/4/3/1/_640/17296431/harisnya-es-harisnyatarto-20den-fekete.jpg",
            "https://harisnyaplaza.hu/shop_ordered/61741/pic/cikk60min.jpg"],
          "articleNumber": "14С-56СП"
        },
        {
          "id": 10585,
          "name": "CONTE TENSION SOFT 20 (1 pair)",
          "link": "conte tension soft 20 (1 pair)",
          "primaryPicture": "https://akciosillat.hu/img/5166/5011417566589/5011417566589.jpg?time=1651315494",
          "brand": "Conte elegant",
          "material": "polyamide 87%, elastane 13%",
          "fullDescription": "Thin elastic socks with a sealed elastic band and toe, for sensitive skin with vitamin E, universal size",
          "grossPrice": 2000,
          "countAvailable": 7309,
          "description": "Women's socks TENSION SOFT 20 (1 pair), s. 36-39, natural",
          "pictures": ["https://akciosillat.hu/img/5166/5011417566589/5011417566589.jpg?time=1651315494",
            "https://www.neptanckellek.hu/img/42348/2808000/456x456,r/harisnya-pamut.jpg?time=1587562741",
            "https://gate.bwcdn.net/media/2021/07/4/2/bodkovane-pancuchy-6107488-89-4230967-size-fr-large-c-v-1-compressed.jpg",
            "https://pix.bonprix.pl/imgc/0/0/1/7/2/9/6/4/3/1/_640/17296431/harisnya-es-harisnyatarto-20den-fekete.jpg",
          "https://harisnyaplaza.hu/shop_ordered/61741/pic/cikk60min.jpg"],
          "articleNumber": "14С-56СП"
        }
      ],
      reviews: [],
      describe: p.fullDescription

    })
  }
  // main category all products
  getProductsByCategory(categoryId: number): Observable<Array<Product>> {
    return this.httpClient.post<Array<Product>>(`/api/product`, {categoryId: categoryId});
    // return of(TEST_PRODUCTS.map(tp => Product.buildProduct(tp)));
  }
  // getProductsByCategoryTest(category_id: number, query?: string): Observable<Array<Product>> {
  //   // return this.httpClient.get<Array<Product>>(`/api/product?category=${category_id}`);
  //   const random = Math.floor(Math.random() * 10);
  //   return of(TEST_PRODUCTS.map(tp => Product.buildProduct(tp)).slice(7 + random, 12 + random + (Math.floor(Math.random() * 10))));
  // }

  addToFavouriteProducts(productId: number) {
    return this.httpClient.post('/api/favourite-product/add', {productId});
  }

  removeFromFavouriteProducts(productId: number) {
    return this.httpClient.post('/api/favourite-product/remove', {productId});
  }

  getFavouriteProducts() {
    // return this.httpClient.get('/api/favourite-product');
    return of(
      [
        {
          id: 1,
          amount: 2,
          articleNumber: 'asd123',
          name: 'Leggings',
          grossPrice: 2.55,
          link: 'leggings',
          primaryPicture: 'https://akciosillat.hu/img/5166/5011417566589/5011417566589.jpg?time=1651315494',
          salePrice: 2.66,
          unitOfMeasure: 'db',
          reviews: [],
          variations: []
        },
        {
          id: 2,
          amount: 2,
          articleNumber: 'asd123',
          name: 'Leggings',
          grossPrice: 2.55,
          link: 'leggings',
          primaryPicture: 'https://akciosillat.hu/img/5166/5011417566589/5011417566589.jpg?time=1651315494',
          salePrice: 2.66,
          unitOfMeasure: 'db',
          reviews: [],
          variations: []
        }
      ]
    );
    // return of(TEST_PRODUCTS.map(tp => Product.buildProduct(tp)).filter((p, i) => i < 4));
    // return of([]);
  }

  // getProductsAndFiltersByCategory(category_id: number, filter: boolean): Observable<ProductListState> {
  //   // return this.httpClient.get<Array<Product>>(`/api/product?category=${category_id}&filter=${filter}`);
  //   const properties = new FilterProperty();
  //   properties['u_anyaga'] = [
  //     'PE',
  //     'Nylon',
  //     'Gumi',
  //     'ALU',
  //     'carbon'
  //   ];
  //   properties['u_atmero'] = [
  //     '2,5 mm',
  //     '3,0 mm',
  //     '4 mm',
  //     '5 mm',
  //     '6 mm',
  //     '10 mm'
  //   ];
  //   properties['u_hossz'] = [
  //     '1 m',
  //     '3 m',
  //     '35 mm',
  //     '60 cm',
  //     '70 mm',
  //     '90 mm',
  //     '93 mm',
  //     '200 cm',
  //     '390 mm'
  //   ];
  //   return of({
  //     products: TEST_PRODUCTS.map(tp => Product.buildProduct(tp)),
  //     filter: new Filter({
  //       min: 255,
  //       max: 34578
  //     }, properties)
  //   });
  // }
}
