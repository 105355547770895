<div class="shape-divider-top shape-divider-pink d-none d-lg-inline">
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
    <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
  </svg>
</div>

<div class="container-fluid masterhead" id="home">

  <!--  MENU ROW-->
  <div class="row w-100">
    <div class="col-10 col-sm-14 col-md-10 col-lg-6 order-1 d-flex justify-content-between" [ngClass]="scrollClass ? 'scrolled-logo' : 'static-logo'">
      <img (click)="navigate(HOME_LINK)" src="assets/images/logo.png" class="logo" alt="Conte.hu webshop"
           tooltip="{{ 'label.tohome' | translate}}" [delay]="1000" [tooltipPlacement]="'bottom'">

      <div class="phone-container pt-lg-5 text-md-center">
        <a href="tel:+36304480063">
        <i class="fa fa-mobile-phone text-light mt-4 d-none d-md-block"></i>
        <span class="lead text-nowrap d-none d-sm-inline pt-5 pt-md-0 ps-4 ps-lg-0">30-44 80 063</span></a>
      </div>

    </div>
    <div class="col-lg-12 order-3 order-lg-2 d-flex flex-column" [ngClass]="showSearchInput ? 'show-input' : ''">
      <nav class="navbar navbar-expand-lg order-lg-2" [ngClass]="scrollClass ? 'scrolled-navbar' : ''">
        <div class="collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen }" id="mainNav">
          <div class="navbar-nav mx-auto">

            <!-- WOMEN ---------------------------------------------------- -->
            <div class="btn-group btn-group-sm" dropdown>
              <button id="button-women"
                      dropdownToggle
                      matRipple
                      [matRippleUnbounded]="false"
                      [matRippleCentered]="true"
                      [matRippleRadius]="200"
                      type="button"
                      class="btn btn-sm btn-link dropdown-toggle"
                      aria-controls="dropdown-women">{{ 'nav.women' | translate}} <i class="fa fa-angle-down"></i>
              </button>

              <div id="dropdown-women" *dropdownMenu class="dropdown-menu shadow pb-4" role="menu"
                   aria-labelledby="button-women">
                <div class="mega-content p-5 p-5">
                  <!--  MEGA CONTENT ROW -->
                  <div class="row">
                    <div class="col-24 col-lg-18">
                      <div class="row">
                        <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xxl-4">
                            <h5><a [routerLink]="'/women/clothing/'">{{ 'nav.clothing' | translate}}</a></h5>
                          <ul>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/clothing/knitwear'">{{ 'nav.knitwear' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/clothing/pullovers'">{{ 'nav.pullovers' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/clothing/blouses'">{{ 'nav.blouses' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/clothing/tshirts'">{{ 'nav.tshirts' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/clothing/dresses'">{{ 'nav.dresses' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/clothing/jumpsuits'">{{ 'nav.jumpsuits' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/clothing/bodies'">{{ 'nav.bodies' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/clothing/skirts'">{{ 'nav.skirts' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/clothing/shorts'">{{ 'nav.shorts' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/clothing/trousers'">{{ 'nav.trousers' | translate}}</a>
                            </li>
                          </ul>
                        </div>
                        <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xxl-4">
                          <h5><a [routerLink]="'/women/jeans/'">{{ 'nav.jeans' | translate}}</a></h5>
                          <ul>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/jeans/skinny'">{{ 'nav.skinny' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/jeans/straightcut'">{{ 'nav.straightcut' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/jeans/pushup'">{{ 'nav.pushup' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/jeans/colored'">{{ 'nav.colored' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/jeans/midrise'">{{ 'nav.midrise' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/jeans/superhighrise'">{{ 'nav.superhighrise' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/jeans/shorter'">{{ 'nav.shorter' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/jeans/plussize'">{{ 'nav.plussize' | translate}}</a>
                            </li>
                          </ul>
                        </div>
                        <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xxl-4">
                          <h5><a [routerLink]="'/women/leggings/'">{{ 'nav.leggings' | translate}}</a></h5>
                          <ul>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/leggings/classic'">{{ 'nav.classic' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/leggings/warm'">{{ 'nav.warm' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/leggings/modeling'">{{ 'nav.modeling' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/leggings/jeggings'">{{ 'nav.jeggings' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/leggings/sports'">{{ 'nav.sports' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/leggings/shorter'">{{ 'nav.shorter' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/leggings/trousers'">{{ 'nav.trousers' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/leggings/capris'">{{ 'nav.capris' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/leggings/undertheskin'">{{ 'nav.undertheskin' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/leggings/plussize'">{{ 'nav.plussize' | translate}}</a>
                            </li>
                          </ul>
                        </div>
                        <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xxl-4">
                          <h5><a [routerLink]="'/women/lingeria/'">{{ 'nav.lingerie' | translate}}</a></h5>
                          <ul>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/lingerie/bras'">{{ 'nav.bras' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/lingerie/bustier'">{{ 'nav.bustier' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/lingerie/panties'">{{ 'nav.panties' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/lingerie/tights'">{{ 'nav.tights' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/lingerie/shirts'">{{ 'nav.shirts' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/lingerie/body'">{{ 'nav.body' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/lingerie/thermounderwear1'">{{ 'nav.thermounderwear' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/accessories'">{{ 'nav.accessories' | translate}}</a>
                            </li>
                          </ul>
                        </div>
                        <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xxl-5">
                          <h5><a [routerLink]="'/women/clothesforhomeandsleep/'">{{ 'nav.clothesforhomeandsleep' | translate}}</a></h5>
                          <ul>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/clothesforhomeandsleep/pajamas1'">{{ 'nav.pajamas' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/clothesforhomeandsleep/tshirts1'">{{ 'nav.tshirts' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/clothesforhomeandsleep/bathrobes'">{{ 'nav.bathrobes' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/clothesforhomeandsleep/peignoirs'">{{ 'nav.peignoirs' | translate}}</a>
                            </li>
                          </ul>
                        </div>
                        <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xxl-4">
                          <h5><a [routerLink]="'/women/swimwear/'">{{ 'nav.swimwear' | translate}}</a></h5>
                          <ul>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/swimwear/fused'">{{ 'nav.fused' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/swimwear/twopiece'">{{ 'nav.twopiece' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/swimwear/bikinitop'">{{ 'nav.bikinitop' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/swimwear/bikinibottom'">{{ 'nav.bikinibottom' | translate}}</a>
                            </li>
                          </ul>
                        </div>
                        <div class="col-24 col-sm-12 col-md-8 col-lg-6 col-xxl-4">
                          <h5><a [routerLink]="'/women/tights/'">{{ 'nav.tights' | translate}}</a></h5>
                          <ul>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/tights/classic'">{{ 'nav.classic' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/tights/corrective'">{{ 'nav.corrective' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/tights/fantasy'">{{ 'nav.fantasy' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/tights/thin'">{{ 'nav.thin' | translate}}</a></li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/tights/tattoo'">{{ 'nav.tattoo' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/tights/openwork'">{{ 'nav.openwork' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/tights/wedding'">{{ 'nav.wedding' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/tights/warm'">{{ 'nav.warm' | translate}}</a></li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/tights/stockings'">{{ 'nav.stockings' | translate}}</a>
                            </li>
                          </ul>
                        </div>
                        <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xxl-4">
                          <h5><a [routerLink]="'/women/socks/'">{{ 'nav.socks' | translate}}</a></h5>
                          <ul>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/socks/classic'">{{ 'nav.classic' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/socks/fantasy'">{{ 'nav.fantasy' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/socks/thin'">{{ 'nav.thin' | translate}}</a></li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/socks/kneehights'">{{ 'nav.kneehights' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/socks/elongated'">{{ 'nav.elongated' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/socks/short'">{{ 'nav.short' | translate}}</a></li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/socks/footlets'">{{ 'nav.footlets' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/socks/warm'">{{ 'nav.warm' | translate}}</a></li>
                          </ul>
                        </div>
                        <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xxl-4">
                          <h5><a [routerLink]="'/women/accessories/'">{{ 'nav.accessories' | translate}}</a></h5>
                          <ul>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/accessories/headbands'">{{ 'nav.headbands' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/accessories/hats'">{{ 'nav.hats' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/accessories/scarves'">{{ 'nav.scarves' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/accessories/hairtie'">{{ 'nav.hairtie' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/accessories/wristbands'">{{ 'nav.wristbands' | translate}}</a>
                            </li>
                          </ul>
                        </div>
                        <div class="col-24 col-sm-12 col-md-8 col-lg-6 col-xxl-5">
                          <h5><a [routerLink]="'/women/guides/'">{{ 'nav.guides' | translate}}</a></h5>
                          <ul>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/guides/lycraguide'">{{ 'nav.lycraguide' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/guides/limitedcollection'">{{ 'nav.limitedcollection' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/guides/familylookcollection'">{{ 'nav.familylookcollection' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/guides/disneymarvelcollection'">{{ 'nav.disneymarvelcollection' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/women/guides/forexpectantmothers'">{{ 'nav.forexpectantmothers' | translate}}</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="d-none d-lg-block col-lg-6">

                      <div class="card">
                        <img src="assets/images/megamenu/women_800x1070.jpg" class="card-img" alt="Women">
                        <div class="card-img-overlay">
                          <div>
                            <h3 class="card-title">Be special Without rival</h3>
                            <!--                            <p class="card-text">Without rival</p>-->
                            <button class="btn btn-primary">Akarom a kedvezményt!</button>
                          </div>
                        </div>
                      </div>


                      <!--                        <img src="assets/images/megamenu/women_800x1070.jpg" class="img-fluid" alt="Women">-->
                    </div>
                  </div>
                  <!--  // MEGA CONTENT ROW END -->
                </div>
              </div>

            </div>

            <!-- MEN ---------------------------------------------------- -->
            <div class="btn-group btn-group-sm" dropdown>
              <button id="button-men"
                      dropdownToggle
                      matRipple
                      [matRippleUnbounded]="false"
                      [matRippleCentered]="true"
                      [matRippleRadius]="200"
                      type="button"
                      class="btn btn-sm btn-link dropdown-toggle"
                      aria-controls="dropdown-men">{{ 'nav.men' | translate}} <i class="fa fa-angle-down"></i>
              </button>

              <div id="dropdown-men" *dropdownMenu class="dropdown-menu shadow" role="menu"
                   aria-labelledby="button-men">
                <div class="mega-content p-5">
                  <!--  MEGA CONTENT ROW -->
                  <div class="row">
                    <div class="col-24 col-lg-18">
                      <div class="row">
                        <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xxl-7">
                          <h5><a [routerLink]="'/men/shirts/'">{{ 'nav.shirts' | translate}}</a></h5>
                          <ul>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/men/shirts'">{{ 'nav.tshirts' | translate}}</a>
                            </li>
<!--  ITT A CONTESHOP.COM-on 3 thirts kategoria van valami miatt -->
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/men/shirts/longsleeves'">{{ 'nav.longsleeves' | translate}}</a>
                            </li>
                          </ul>
                        </div>
                        <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xxl-7">
                          <h5><a [routerLink]="'/men/socks/'">{{ 'nav.socks' | translate}}</a></h5>
                          <ul>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/men/socks/footlets'">{{ 'nav.footlets' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/men/socks/short'">{{ 'nav.short' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/men/socks/classic'">{{ 'nav.classic' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/men/socks/colored'">{{ 'nav.colored' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/men/socks/thin'">{{ 'nav.thin' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/men/socks/classic'">{{ 'nav.classic' | translate}}</a>
                            </li>
                          </ul>
                        </div>
                        <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xxl-7">
                          <h5><a [routerLink]="'/men/pants/'">{{ 'nav.pants' | translate}}</a></h5>
                          <ul>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/men/pants/boxer'">{{ 'nav.boxer' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/men/pants/modeling'">{{ 'nav.modeling' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/men/pants/trunks'">{{ 'nav.trunks' | translate}}</a>
                            </li>
                          </ul>
                        </div>
                        <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xxl-7">
                          <h5><a [routerLink]="'/men/thermounderwear/'">{{ 'nav.thermounderwear' | translate}}</a></h5>
                          <ul>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/men/thermounderwear/pantaloon'">{{ 'nav.pantaloon' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/men/thermounderwear/singlet'">{{ 'nav.singlet' | translate}}</a>
                            </li>
                          </ul>
                        </div>
                        <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xxl-7">
                          <h5><a [routerLink]="'/men/accessories/'">{{ 'nav.accessories' | translate}}</a></h5>
                          <ul>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/men/accessories/wristbands'">{{ 'nav.wristbands' | translate}}</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/men/accessories/facemasks'">{{ 'nav.facemasks' | translate}}</a>
                            </li>
                          </ul>
                        </div>
                        <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xxl-7">
                          <h5><a [routerLink]="'/men/guides/'">{{ 'nav.guides' | translate}}</a></h5>
                          <ul>
                            <li role="menuitem"><a class="dropdown-item"
                                                   [routerLink]="'/men/guides/disneymarvelcollection'">{{ 'nav.disneymarvelcollection' | translate}}</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="d-none d-lg-block col-lg-6">

                      <div class="card">
                        <img src="assets/images/megamenu/women_800x1070.jpg" class="card-img" alt="Men">
                        <div class="card-img-overlay">
                          <div>
                            <h3 class="card-title">Be special Without rival</h3>
                            <!--                            <p class="card-text">Without rival</p>-->
                            <button class="btn btn-success">Akarom a kedvezményt!</button>
                          </div>
                        </div>
                      </div>


                      <!--                        <img src="assets/images/megamenu/women_800x1070.jpg" class="img-fluid" alt="Women">-->
                    </div>
                  </div>
                  <!--  // MEGA CONTENT ROW END -->
                </div>
              </div>
            </div>

            <!-- GIRLS ---------------------------------------------------- -->
            <div class="btn-group btn-group-sm" dropdown>
              <button id="button-girls"
                      dropdownToggle
                      matRipple
                      [matRippleUnbounded]="false"
                      [matRippleCentered]="true"
                      [matRippleRadius]="200"
                      type="button"
                      class="btn btn-sm btn-link dropdown-toggle"
                      aria-controls="dropdown-girls">{{ 'nav.girls' | translate}} <i class="fa fa-angle-down"></i>
              </button>

              <div id="dropdown-girls" *dropdownMenu class="dropdown-menu shadow" role="menu"
                   aria-labelledby="button-girls">
                <div class="mega-content p-5">
                  <!--  MEGA CONTENT ROW -->
                <div class="row">
                  <div class="col-24 col-lg-18">
                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xxl-6">
                        <h5><a [routerLink]="'/girls/clothing'">{{ 'nav.clothing' | translate}}</a></h5>
                        <ul>
                          <li role="menuitem"><a class="dropdown-item"
                                                 [routerLink]="'/girls/tunics'">{{ 'nav.tunics' | translate}}</a>
                          </li>
                          <li role="menuitem"><a class="dropdown-item"
                                                 [routerLink]="'/girls/jumpers'">{{ 'nav.jumpers' | translate}}</a>
                          </li>
                          <li role="menuitem"><a class="dropdown-item"
                                                 [routerLink]="'/girls/turtleneck'">{{ 'nav.turtleneck' | translate}}</a>
                          </li>
                        </ul>
                      </div>
                      <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xxl-6">
                        <h5><a [routerLink]="'/men/leggings/'">{{ 'nav.leggings' | translate}}</a></h5>
                        <ul>
                          <li role="menuitem"><a class="dropdown-item"
                                                 [routerLink]="'/girls/leggings/leggings'">{{ 'nav.leggings' | translate}}</a>
                          </li>
                          <li role="menuitem"><a class="dropdown-item"
                                                 [routerLink]="'/girls/leggings/capris'">{{ 'nav.capris' | translate}}</a>
                          </li>
                          <li role="menuitem"><a class="dropdown-item"
                                                 [routerLink]="'/girls/leggings/jeggings'">{{ 'nav.jeggings' | translate}}</a>
                          </li>
                          <li role="menuitem"><a class="dropdown-item"
                                                 [routerLink]="'/girls/leggings/pants'">{{ 'nav.pants' | translate}}</a>
                          </li>
                          <li role="menuitem"><a class="dropdown-item"
                                                 [routerLink]="'/girls/leggings/warm'">{{ 'nav.warm' | translate}}</a>
                          </li>
                        </ul>
                      </div>
                      <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xxl-6">
                        <h5><a [routerLink]="'/girls/swimwear'">{{ 'nav.swimwear' | translate}}</a></h5>
                        <ul>
                          <li role="menuitem"><a class="dropdown-item"
                                                 [routerLink]="'/girls/swimwear/joint'">{{ 'nav.joint' | translate}}</a>
                          </li>
                          <li role="menuitem"><a class="dropdown-item"
                                                 [routerLink]="'/girls/swimwear/separate'">{{ 'nav.separate' | translate}}</a>
                          </li>
                        </ul>
                      </div>
                      <div class="col-24 col-sm-12 col-md-8 col-lg-6 col-xxl-6">
                        <h5><a [routerLink]="'/men/tights/'">{{ 'nav.tights' | translate}}</a></h5>
                        <ul>
                          <li role="menuitem"><a class="dropdown-item"
                                                 [routerLink]="'/girls/tights/classic'">{{ 'nav.classic' | translate}}</a>
                          </li>
                          <li role="menuitem"><a class="dropdown-item"
                                                 [routerLink]="'/girls/tights/elegant'">{{ 'nav.elegant' | translate}}</a>
                          </li>
                          <li role="menuitem"><a class="dropdown-item"
                                                 [routerLink]="'/girls/tights/thin'">{{ 'nav.thin' | translate}}</a>
                          </li>
                          <li role="menuitem"><a class="dropdown-item"
                                                 [routerLink]="'/girls/tights/cheerfullegs'">{{ 'nav.cheerfullegs' | translate}}</a>
                          </li>
                          <li role="menuitem"><a class="dropdown-item"
                                                 [routerLink]="'/girls/tights/warm'">{{ 'nav.warm' | translate}}</a>
                          </li>
                        </ul>
                      </div>
                      <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xxl-6">
                        <h5><a [routerLink]="'/girls/socks/'">{{ 'nav.socks' | translate}}</a></h5>
                        <ul>
                          <li role="menuitem"><a class="dropdown-item"
                                                 [routerLink]="'/girls/socks/short'">{{ 'nav.short' | translate}}</a>
                          </li>
                          <li role="menuitem"><a class="dropdown-item"
                                                 [routerLink]="'/girls/socks/classic'">{{ 'nav.classic' | translate}}</a>
                          </li>
                          <li role="menuitem"><a class="dropdown-item"
                                                 [routerLink]="'/girls/socks/elegant'">{{ 'nav.elegant' | translate}}</a>
                          </li>
                          <li role="menuitem"><a class="dropdown-item"
                                                 [routerLink]="'/girls/socks/antislip'">{{ 'nav.antislip' | translate}}</a>
                          </li>
                          <li role="menuitem"><a class="dropdown-item"
                                                 [routerLink]="'/girls/socks/cheerfullegs'">{{ 'nav.cheerfullegs' | translate}}</a>
                          </li>
                          <li role="menuitem"><a class="dropdown-item"
                                                 [routerLink]="'/girls/socks/warm'">{{ 'nav.warm' | translate}}</a>
                          </li>
                          <li role="menuitem"><a class="dropdown-item"
                                                 [routerLink]="'/girls/socks/kneehights'">{{ 'nav.kneehights' | translate}}</a>
                          </li>
                        </ul>
                      </div>
                      <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xxl-6">
                        <h5><a [routerLink]="'/girls/socks/'">{{ 'nav.socks' | translate}}</a></h5>
                        <ul>
                          <li role="menuitem"><a class="dropdown-item"
                                                 [routerLink]="'/girls/accessories/hairtie'">{{ 'nav.hairtie' | translate}}</a>
                          </li>
                          <li role="menuitem"><a class="dropdown-item"
                                                 [routerLink]="'/girls/accessories/headbands'">{{ 'nav.headbands' | translate}}</a>
                          </li>
                        </ul>
                      </div>
                      <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xxl-6">
                        <h5><a [routerLink]="'/girls/guides/'">{{ 'nav.guides' | translate}}</a></h5>
                        <ul>
                          <li role="menuitem"><a class="dropdown-item"
                                                 [routerLink]="'/men/guides/familylookcollection'">{{ 'nav.familylookcollection' | translate}}</a>
                          </li>
                          <li role="menuitem"><a class="dropdown-item"
                                                 [routerLink]="'/men/guides/disneymarvelcollection'">{{ 'nav.disneymarvelcollection' | translate}}</a>
                          </li>
                          <li role="menuitem"><a class="dropdown-item"
                                                 [routerLink]="'/men/guides/lookbookcolour'">{{ 'nav.lookbookcolour' | translate}}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="d-none d-lg-block col-lg-6">

                    <div class="card">
                      <img src="assets/images/megamenu/women_800x1070.jpg" class="card-img" alt="Women">
                      <div class="card-img-overlay">
                        <div>
                          <h3 class="card-title">Be special Without rival</h3>
                          <!--                            <p class="card-text">Without rival</p>-->
                          <button class="btn btn-primary">Akarom a kedvezményt!</button>
                        </div>
                      </div>
                    </div>


                    <!--                        <img src="assets/images/megamenu/women_800x1070.jpg" class="img-fluid" alt="Women">-->
                  </div>
                </div>
                  <!--  // MEGA CONTENT ROW END -->
                </div>
              </div>
          </div>

            <!-- BOYS ---------------------------------------------------- -->
            <div class="btn-group btn-group-sm" dropdown>
              <button id="button-boys"
                      dropdownToggle
                      matRipple
                      [matRippleUnbounded]="false"
                      [matRippleCentered]="true"
                      [matRippleRadius]="200"
                      type="button"
                      class="btn btn-sm btn-link dropdown-toggle"
                      aria-controls="dropdown-boys">{{ 'nav.boys' | translate}} <i class="fa fa-angle-down"></i>
              </button>
              <div id="dropdown-boys" *dropdownMenu class="dropdown-menu shadow" role="menu"
                   aria-labelledby="button-boys">

                <div class="mega-content p-5">
                  <!--  MEGA CONTENT ROW -->
                <div class="row">
                  <div class="col-24 col-lg-18">
                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xxl-5">
                        <h5><a [routerLink]="'/boys/leggings/'">{{ 'nav.leggings' | translate}}</a></h5>
                        <ul>
                          <li role="menuitem"><a class="dropdown-item"
                                                 [routerLink]="'/boys/leggings/classic'">{{ 'nav.classic' | translate}}</a>
                          </li>
                          <li role="menuitem"><a class="dropdown-item"
                                                 [routerLink]="'/boys/leggings/warm'">{{ 'nav.warm' | translate}}</a>
                          </li>
                        </ul>
                      </div>
                      <div class="col-24 col-sm-12 col-md-8 col-lg-6 col-xxl-5">
                        <h5><a [routerLink]="'/boys/tights/'">{{ 'nav.tights' | translate}}</a></h5>
                        <ul>
                          <li role="menuitem"><a class="dropdown-item"
                                                 [routerLink]="'/boys/tights/classic'">{{ 'nav.classic' | translate}}</a>
                          </li>
                          <li role="menuitem"><a class="dropdown-item"
                                                 [routerLink]="'/boys/tights/cheerfullegs'">{{ 'nav.cheerfullegs' | translate}}</a>
                          </li>
                          <li role="menuitem"><a class="dropdown-item"
                                                 [routerLink]="'/boys/tights/warm'">{{ 'nav.warm' | translate}}</a>
                          </li>
                        </ul>
                      </div>
                      <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xxl-5">
                        <h5><a [routerLink]="'/boys/socks/'">{{ 'nav.socks' | translate}}</a></h5>
                        <ul>
                          <li role="menuitem"><a class="dropdown-item"
                                                 [routerLink]="'/boys/socks/short'">{{ 'nav.short' | translate}}</a>
                          </li>
                          <li role="menuitem"><a class="dropdown-item"
                                                 [routerLink]="'/boys/socks/classic'">{{ 'nav.classic' | translate}}</a>
                          </li>
                          <li role="menuitem"><a class="dropdown-item"
                                                 [routerLink]="'/boys/socks/antislip'">{{ 'nav.antislip' | translate}}</a>
                          </li>
                          <li role="menuitem"><a class="dropdown-item"
                                                 [routerLink]="'/boys/socks/cheerfullegs'">{{ 'nav.cheerfullegs' | translate}}</a>
                          </li>
                          <li role="menuitem"><a class="dropdown-item"
                                                 [routerLink]="'/boys/socks/warm'">{{ 'nav.warm' | translate}}</a>
                          </li>
                        </ul>
                      </div>
                      <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xxl-5">
                        <h5><a [routerLink]="'/boys/guides/'">{{ 'nav.guides' | translate}}</a></h5>
                        <ul>
                          <li role="menuitem"><a class="dropdown-item"
                                                 [routerLink]="'/men/boys/disneymarvelcollection'">{{ 'nav.disneymarvelcollection' | translate}}</a>
                          </li>
                          <li role="menuitem"><a class="dropdown-item"
                                                 [routerLink]="'/men/boys/lookbookcolour'">{{ 'nav.lookbookcolour' | translate}}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="d-none d-lg-block col-lg-6">

                    <div class="card">
                      <img src="assets/images/megamenu/women_800x1070.jpg" class="card-img" alt="Women">
                      <div class="card-img-overlay">
                        <div>
                          <h3 class="card-title">Be special Without rival</h3>
                          <!--                            <p class="card-text">Without rival</p>-->
                          <button class="btn btn-success">Akarom a kedvezményt!</button>
                        </div>
                      </div>
                    </div>


                    <!--                        <img src="assets/images/megamenu/women_800x1070.jpg" class="img-fluid" alt="Women">-->
                  </div>
                </div>
                <!--  // MEGA CONTENT ROW END -->
                </div>
              </div>
            </div>


            <div class="btn-group btn-group-sm">
              <button type="button"
                      class="btn btn-sm btn-link nav-link text-nowrap"
                      [routerLink]="'akciok'"
                      matRipple [matRippleUnbounded]="false"
                      [matRippleCentered]="true"
                      [matRippleRadius]="200"><i class="fa fa-chevron-right"></i> Akciók
              </button>
            </div>

            <div class="btn-group btn-group-sm">
              <button type="button"
                      class="btn btn-sm btn-link nav-link text-nowrap"
                      [routerLink]="'vasarlasi-informaciok'"
                      matRipple [matRippleUnbounded]="false"
                      [matRippleCentered]="true"
                      [matRippleRadius]="200"><i class="fa fa-chevron-right"></i> Vásárlási információk
              </button>
            </div>


          </div>

        </div> <!-- navbar-collapse.// -->
      </nav>
      <div class="search-bar order-lg-1 mt-0 mt-lg-5" [ngClass]="scrollClass ? '' : 'search-bar-top'"
           (click)="showSearchResult = true" (clickOutside)="showSearchResult = false">
        <input class="search-input" autocomplete="off" type="text" name="search" maxlength="80"
               placeholder="Mit keres? Pl. női harisnya" [(ngModel)]="query"
               (ngModelChange)="search($event)">
        <span class="search-icon"><a [routerLink]="'/kereses?query=' + query"><i class="fa fa-search"></i></a></span>
      </div>

    </div>

    <div class="col-14 col-sm-10 col-md-14 col-lg-6 d-flex justify-content-end cart order-2 order-lg-3"
         [ngClass]="scrollClass ? 'scrolled-cart' : 'align-items-center'">
<!--              <span class="fa-stack fa-sm" [ngClass]="scrollClass ? '' : 'd-none'">-->
<!--                 <i class="fa fa-circle-o fa-stack-2x"></i>-->
<!--                 <i class="fa fa-search fa-stack-1x" (click)="showSearch()"></i>-->
<!--              </span>-->


      <i *ngIf="!user" class="fa fa-user-o" (click)="openLogin('LOGIN');"
         tooltip="{{ 'label.login.registry' | translate}}" [delay]="500"></i>
      <div *ngIf="user" class="user-dropdown btn-group" dropdown #dp="bs-dropdown"
           tooltip="{{ 'label.aboutme' | translate}}" placement="top" [delay]="500">
        <i class="fa fa-user-o dropdown-toggle" id="button-user" dropdownToggle aria-controls="dropdown-user"></i>
        <ul id="dropdown-user" *dropdownMenu class="dropdown-menu pt-0 pb-0" role="menu" aria-labelledby="button-user">
          <li role="menuitem  ps-0"><span class="menu-headline'">{{ 'label.myprofil' | translate}}</span></li>
          <li role="menuitem"><a class="dropdown-item"
                                 [routerLink]="'profil/sajat-fiok'">{{ 'label.mydata' | translate}}</a></li>
          <li role="menuitem"><a class="dropdown-item"
                                 [routerLink]="'profil/rendeleseim'">{{ 'label.myorders' | translate}}</a></li>
          <li role="menuitem"><a class="dropdown-item"
                                 [routerLink]="'profil/ertekelesek'">{{ 'label.myratings' | translate}}</a></li>
          <li class="divider dropdown-divider"></li>
          <li role="menuitem"><a class="dropdown-item" (click)="logout()">{{ 'label.logout' | translate}}</a></li>
        </ul>
      </div>

      <i class="fa fa-heart-o" tooltip="{{ 'label.show.favourites' | translate}}" [delay]="500"
         [routerLink]="'profil/kedvencek'"></i>
      <span class="badge rounded-pill bg-favorit" [routerLink]="'profil/kedvencek'">{{contOfItem}}</span>

      <i tooltip="A kosár üres" [delay]="500" class="fa fa-shopping-cart" aria-hidden="true"
         *ngIf="contOfItem == 0"></i>
      <i tooltip="A kosár tartalma" [delay]="500" (click)="cartClicked.emit()" class="fa fa-shopping-cart"
         aria-hidden="true" *ngIf="contOfItem > 0"></i>
      <span class="badge rounded-pill bg-secondary" (click)="cartClicked.emit()">{{contOfItem}}</span>

      <i class="fa fa-reorder d-lg-none" (click)="toggleNavbar()"></i>
    </div>

    <div class="col-24 order-4">
      <app-search-result class="search-result"
                         *ngIf="searchResult && showSearchResult"
                         [categories]="searchedCategories"
                         [products]="searchResult.products"
                         [manufacturers]="searchResult.manufacturers"></app-search-result>
    </div>

  </div>
  <!-- // MENU ROW end-->
</div>
