import { ContentActions, ContentActionTypes } from './ContentActions';
import { Json } from '../../model/Json';

export class PageContent {
  pages: Json<string, any>;
  [k: string]: any;
}

export interface ContentState {
  // water: Json<string, any>
  content: Json<string, Json<string, PageContent>>;
  // rules: any;
}

export const initialState: ContentState = {
  content: new Json<string, Json<string, any>>()
  // rules: []
};

initialState.content['halak'] = new Json<string, PageContent>();

export function contentReducer(state: ContentState = initialState, action: ContentActions): ContentState {
  switch (action.type) {
    // case ContentActionTypes.InitFish0: {
    //   return {
    //     ...state,
    //     fish: {
    //       ...state.fish,
    //       [action.payload.path]: action.payload
    //     }
    //   };
    // }
    // case ContentActionTypes.InitFishRules: {
    //   return {
    //     ...state,
    //     fish: {
    //       ...state.fish,
    //       [action.payload.path]: {
    //         ...state.fish[action.payload.path],
    //         rules: action.payload.rules
    //       }
    //     }
    //   };
    // }
    // case ContentActionTypes.InitFishRulesAndSelectedRule: {
    //   return {
    //     ...state,
    //     fish: {
    //       ...state.fish,
    //       [action.payload.path]: {
    //         ...state.fish[action.payload.path],
    //         rules: action.payload.rules,
    //         selectedRule: action.payload.selectedRule
    //       }
    //     }
    //   };
    // }
    case ContentActionTypes.InitContent: {
      if (!state.content[action.payload.menu]) {
        return {
          ...state,
          content: {
            ...state.content,
            [action.payload.menu]: {
              ...state.content[action.payload.menu],
              [action.payload.path]: {
                ...action.payload.content
              }
            }
          }
        };
      } else if (!state.content[action.payload.menu][action.payload.path]) {
        return {
          ...state,
          content: {
            ...state.content,
            [action.payload.menu]: {
              ...state.content[action.payload.menu],
              [action.payload.path]: {
                ...action.payload.content
              },
            }
          }
        };
      }
      return {
        ...state,
        content: {
          ...state.content,
          [action.payload.menu]: {
            ...state.content[action.payload.menu],
            [action.payload.path]: {
              ...state.content[action.payload.menu][action.payload.path],
              ...action.payload.content
            }
          }
        }
      };
    }
    case ContentActionTypes.AddSubPage: {
      return {
        ...state,
        content: {
          ...state.content,
          [action.payload.menu]: {
            ...state[action.payload.menu],
            [action.payload.path]: {
              ...state[action.payload.menu][action.payload.path],
              pages: {
                ...state[action.payload.menu][action.payload.path]?.pages,
                // @ts-ignore
                [action.payload.id]: action.payload.content
              }
            }
          }
        }
      };
    }
  }
  return state;
}

export const initContentState = (state: ContentState) => state;
// export const initRules = (state: ContentState) => state.rules;

