import { Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { Category } from '../../../model/Category';
import { Store } from '@ngrx/store';
import { CategoryState } from '../../../store/category/CategoryReducer';
import { initCategoriesSelector } from '../../../store/category/CategorySelector';


@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  animations: [
      trigger("toggleSubCats", [
        state(
          "initial",
          style({
            transform: "translateY(85%)",
           // height: "0",
            overflow: "hidden"
          })
        ),
        state(
          "final",
          style({
            transform: "translateY(0)",
            height: "auto",
          })
        ),

        transition("initial=>final", [
          style({ transform: "translateY(85%)" }),
          animate("0.5s 300ms ease-in")
        ]),
        transition("final=>initial", [
          animate("0.3s ease-out",
          style({ transform: "translateY(85%)" }))
        ])
      ]),

    trigger("toggleSubCatsxxx", [
      state("initial", style({ transform: "translateY(0)" })),
      transition(":enter", [
        style({ transform: "translateY(100%)" }),
        animate("0.5s 300ms ease-in")
      ]),
      transition(":leave", [
        animate("0.3s ease-out", style({ transform: "translateY(100%)" }))
      ])
    ]),

    // trigger("toggleSubCatsLinks", [
    //   state("initial", style({ transform: "translateX(0)" })),
    //   transition(":enter", [
    //     style({ transform: "translateX(-100%)" }),
    //     animate("0.5s 300ms ease-in")
    //   ]),
    //   transition(":leave", [
    //     animate("0.3s ease-out", style({ transform: "translateX(100%)" }))
    //   ])
    // ])

  ]
})
export class CategoriesComponent implements OnInit {

  currentState = 'initial';

  image: string;

  parallaxImage: "url(\"/assets/images/backgrounds/women-banner.jpg\")"

  allCategories: Category[] = [];
  categories: Category[] = [];

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private readonly categoryStore: Store<CategoryState>) { }

  ngOnInit(): void {
    if (this.activatedRoute.routeConfig.path.includes('subcategory')) {
      this.image = '/assets/images/categories/cat2.jpg';
    } else {
      this.image = '/assets/images/categories/cat1.jpg';
    }

    // const sub = [
    //   new Category('asd', 'Bojlis', 'bojlis', []),
    //   new Category('asd', 'Feeder', 'feeder', []),
    //   new Category('asd', 'Harcsázó', 'harcsazo', [])
    // ];

    // if (this.activatedRoute.routeConfig.path === 'category/:id') {
    //   this.categories = [
    //     new Category('qwe', 'Subcategory', 'horgaszbot', []),
    //     new Category('qwe', 'Subcategory', 'egyik', []),
    //     new Category('qwe', 'Subcategory', 'egyik', []),
    //     new Category('qwe', 'Subcategory', 'egyik', []),
    //     // new Category('asd', 'Másik', 'masik', []),
    //   ];
    // } else {
    //   this.categories = [
    //     new Category('asd', 'Horgászbotok', 'horgaszbot', sub),
    //     new Category('asd', 'Egyik', 'egyik', []),
    //     // new Category('asd', 'Másik', 'masik', []),
    //   ];
    // }

    this.categoryStore.select(initCategoriesSelector).subscribe((categories: Array<Category>) => {
      this.allCategories = categories;
      if (this.activatedRoute.routeConfig.path === '') {
        this.categories = this.allCategories;
      }
    })
    this.activatedRoute.params.subscribe(params => {
      // TODO call server: load subcategories
      const category = params['category'];
      // const subCategory = params['subCategory'];
      if (category /*&& subCategory*/) {
        // this.categories = [
        //   new Category('qwe', 'Test subcategory', 'horgaszbot', [])
        // ];
        const actualCategory = this.allCategories.find(c => c.link === category);
        if (!actualCategory) {
          this.router.navigateByUrl('not-found');
          return;
        }
        this.categories = actualCategory.children;
      }
    });



  }

  openSubCats() {
    this.currentState = "final";
  }

  closeSubCats() {
    this.currentState = "initial";
  }


  navigateToSubCategory(link: string) {
    this.router.navigateByUrl(link);
  }
}
