import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentCardComponent } from './content-card/content-card.component';
import { ContentComponent } from './content.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { ContentNavigationComponent } from './content-navigation/content-navigation.component';
import { ContentService } from './content.service';
import { CommonTemplateComponent } from './common-template/common-template.component';
import { MapComponent } from './map/map.component';
import { RegionsMapComponent } from './regions-map/regions-map.component';
import { HIGHLIGHT_OPTIONS, HighlightModule } from 'ngx-highlightjs';
import { ImageMapComponent } from './image-map/image-map.component';
import { VizekTemplateComponent } from './vizek-template/vizek-template.component';
import { WaterPipe } from './water.pipe';
import { CountyPipe } from './county.pipe';
import { WaterCardComponent } from './water-card/water-card.component';
import { FishTemplateComponent } from './fish-template/fish-template.component';

@NgModule({
  declarations: [
    ContentCardComponent,
    ContentComponent,
    ContentNavigationComponent,
    CommonTemplateComponent,
    MapComponent,
    RegionsMapComponent,
    ImageMapComponent,
    VizekTemplateComponent,
    WaterPipe,
    CountyPipe,
    WaterCardComponent,
    FishTemplateComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    HighlightModule
  ],
  providers: [
    ContentService,
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        fullLibraryLoader: () => import('highlight.js'),
      }
    }
  ]
})
export class ContentModule {
}
