export class Json<K extends string | number, V> {
  [k: number]: V;
  [k: string]: V;

  // @ts-ignore
  // computeIfAbsent<K, V>(map: Map<K, V>, key: any, callback): V {
  //   const existingValue = map.get(key);
  //   if (existingValue) {
  //     return existingValue;
  //   }
  //   const value = callback(key);
  //   map.set(key, value);
  //   return value;
  // };
}
