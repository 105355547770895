import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Profile } from '../model/Profile';
import { Address } from '../model/Address';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpClient: HttpClient) {
  }

  getProfile(): Observable<Profile> {
    // return this.httpClient.get<Profile>('/api/user/profile');
    return of({
      addresses: [
        {
          address: 'Kossuth Lajos utca',
          city: 'Budapest',
          country: 'Magyarország',
          phoneNumber: '+36301234567',
          postalCode: 4000,
          id: 1
        }
      ],
      company: null,
      personalData: {
        email: 'user@gmail.com',
        phoneNumber: '+36301234567',
        name: 'User'
      }
    });
  }

  addAddress(address: Address): Observable<void> {
    return this.httpClient.post<void>('/api/user/address/add', address);
  }
}
