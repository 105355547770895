import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { CartState } from '../../../store/cart/CartReducer';
import { Subscription } from 'rxjs';
import { CategoryState } from '../../../store/category/CategoryReducer';
import { ChangeAmountOfProduct, RemoveProductFromCart } from '../../../store/cart/CartActions';
import { ProductKey } from '../../../model/ProductKey';
import { Product } from '../../../model/Product';
import { ProductState } from '../../../store/product/ProductReducer';
import { initProductsSelector } from '../../../store/product/ProductSelector';
import { Cart } from '../../../model/Cart';
import { CartService } from '../../../service/cart.service';
import { ProductService } from '../../../service/product.service';
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { ProductInCart } from '../../../model/ProductInCart';
import { DataExchangeService } from '../../../service/data-exchange.service';
import { OrderingType } from '../../../model/OrderingType';
import { initCartSelector } from '../../../store/cart/CartSelector';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html'
})
export class ShoppingCartComponent implements OnInit, OnDestroy {

  @Input()
  sidenav: MatSidenav;
  @Input()
  cartSidenav: MatSidenav;
  cart: Cart;

  productsInCart: Array<ProductInCart>;

  @Output()
  closeCartEvent: EventEmitter<void> = new EventEmitter<void>();

  reason = '';

  close(reason: string) {
    this.reason = reason;
    this.sidenav.close();
    this.cartSidenav.close();
  }

  products: ProductKey;
  productsById: { [k: number]: Product} = {};

  numberOfProducts: number;

  private subscriptions: Array<Subscription> = new Array<Subscription>();

  constructor(protected readonly cartStore: Store<CartState>,
              protected readonly categoryStore: Store<CategoryState>,
              protected readonly productStore: Store<ProductState>,
              protected readonly cartService: CartService,
              protected readonly productService: ProductService,
              protected readonly router: Router,
              protected readonly dataexchangeService: DataExchangeService) {
  }

  reinitProductsInCart() {
    for (const k of Object.keys(this.cart)) {
      const variation = this.products[this.cart[k].parentProductId].variations.find(pv => pv.id === Number(k));
      if (!this.productsById[variation.id]) {
        if (this.products[k]) {
          // main product, van ilyen?
          console.log('HE')
          this.productsById[this.products[k].id] = this.products[k];
        } else {
          // variation
          this.productsById[variation.id] = variation;
        }
      }
    }
    this.productsInCart = Object.values(this.cart);
  }

  ngOnInit(): void {
    const missingProducts: Array<string> = new Array<string>();
    this.subscriptions.push(
      this.productStore.select(initProductsSelector).subscribe((products) => {
        this.products = products;
      })
    );

    this.subscriptions.push(
      this.cartStore.select(initCartSelector).subscribe((cart) => {
        this.cart = cart;
        this.productsInCart = Object.values(this.cart);
        this.reinitProductsInCart();
      })
    )
    this.subscriptions.push(
      this.dataexchangeService.numberOfProductsInCart.subscribe((value) => {
        this.numberOfProducts = value;
      })
    );

    Object.keys(this.cart).forEach((k) => {
      const variation = this.products[this.cart[k].parentProductId].variations.find(pv => pv.id === Number(k));
      if (!this.products[this.cart[k].parentProductId]) {
        console.log('HIBA?!')
        missingProducts.push(this.cart[k].productId);
      } else {
        this.productsById[variation.id] = variation;
      }
    });

    if (missingProducts.length > 0) {
      console.log('HIBA?!')
      this.subscriptions.push(
        // this.productService.getProductsByIds(missingProducts).subscribe((products: Array<Product>) => {
        this.productService.getProductsCommonVersion({ids: missingProducts}, {currentPage: undefined, itemsPerPage: undefined}, OrderingType.POPULAR).subscribe((products: Array<Product>) => {
          products.forEach(p => {
            this.productsById[p.articleNumber] = p;
          });
        })
      );
    }
  }

  navigateToCheckout() {
    this.cartSidenav.close();
    this.router.navigateByUrl('penztar');
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  removeProductFromCart(productId: number) {
    this.cartStore.dispatch(new RemoveProductFromCart(productId));
  }

  increaseAmount(productId: number) {
    this.changeAmount(productId, this.cart[productId].amount + 1);
  }

  decreaseAmount(productId: number) {
    this.changeAmount(productId, this.cart[productId].amount - 1);
  }

  changeAmount(productId: number, amount: number) {
    this.cartStore.dispatch(new ChangeAmountOfProduct(productId, amount));
  }
}
