import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { WaterModel } from '../../model/WaterModel';
import { HttpClient } from '@angular/common/http';
import { WaterLevel } from '../../model/WaterLevel';
import { Fish } from '../../model/Fish';

@Injectable()
export class ContentService {

  public static waterUrls = ['folyok', 'patakok', 'csatornak', 'tavak', 'tarozok', 'holtagak'];
  public static waterAllUrls = ['horgaszvizek-magyarorszagon', 'vizallasok', ...ContentService.waterUrls]
  public static fishUrls = ['halszotar', 'horgasz-szabalyok', 'ponty', 'csuka', 'sullo', 'harcsa', 'keszegfelek', 'hazai-halfajok'];
  public static menuFishIds = {
    'ponty': 87,
    'csuka': 99,
    'sullo': 208,
    'harcsa': 220
  }

  constructor(private readonly router: Router,
              private readonly http: HttpClient) {
  }

  initNewestArticleIfNotLoaded(navLink: string, articlelink: string) {
    if (this.router.url.split('/').length === 3) {
      this.router.navigateByUrl(navLink + '/' + articlelink);
    }
  }

  // findWaterLevels() {
  //   return this.http.get('https://www.vizugy.hu/rss/?type=1');
  // }

  findRules() {
    return this.http.get<Array<any>>(`https://horgasz.rootor.eu/cms-data/rule.json`);
  }

  findRule(id: string) {
    return this.http.get<Array<any>>(`https://horgasz.rootor.eu/cms-data/rule-${id}.json`);
  }

  findFish(id: string): Observable<Fish> {
    return this.http.get<Fish>(`https://horgasz.rootor.eu/cms-data/fish-${id}.json`)
  }

  findHungarianFishes(): Observable<Array<Fish>> {
    return this.http.get<Array<Fish>>('https://horgasz.rootor.eu/cms-data/fish-hun.json')
  }

  findFishes(): Observable<Array<Fish>> {
    return this.http.get<Array<Fish>>('http://horgasz.rootor.eu/cms-data/fish.json')
    // return of([
    //   {
    //     englishName: 'ponty',
    //     hungarianName: 'ponty',
    //     metaDescription: 'lorem ipsum askdmnoaihnr anrud  nj a aia a ',
    //     metaTitle: 'meta title',
    //     scienceName: 'science name',
    //     isProtected: false
    //   },
    //   {
    //     englishName: 'csuka',
    //     hungarianName: 'csuka',
    //     metaDescription: 'lorem ipsum askdmnoaihnr anrud  nj a aia a ',
    //     metaTitle: 'meta title',
    //     scienceName: 'science name',
    //     isProtected: false
    //   },
    //   {
    //     englishName: 'Süllő',
    //     hungarianName: 'Süllő',
    //     metaDescription: 'lorem ipsum askdmnoaihnr anrud  nj a aia a ',
    //     metaTitle: 'meta title',
    //     scienceName: 'science name',
    //     isProtected: true
    //   },
    //   {
    //     englishName: 'Harcsa',
    //     hungarianName: 'Harcsa',
    //     metaDescription: 'lorem ipsum askdmnoaihnr anrud  nj a aia a ',
    //     metaTitle: 'meta title',
    //     scienceName: 'science name',
    //     isProtected: false
    //   },
    //   {
    //     englishName: 'Jászkeszeg',
    //     hungarianName: 'Jászkeszeg',
    //     metaDescription: 'lorem ipsum askdmnoaihnr anrud  nj a aia a ',
    //     metaTitle: 'meta title',
    //     scienceName: 'science name',
    //     isProtected: false
    //   },
    //   {
    //     englishName: 'Lapos keszeg',
    //     hungarianName: 'Lapos keszeg',
    //     metaDescription: 'lorem ipsum askdmnoaihnr anrud  nj a aia a ',
    //     metaTitle: 'meta title',
    //     scienceName: 'science name',
    //     isProtected: false
    //   },
    //   {
    //     englishName: 'Bodorka',
    //     hungarianName: 'Bodorka',
    //     metaDescription: 'lorem ipsum askdmnoaihnr anrud  nj a aia a ',
    //     metaTitle: 'meta title',
    //     scienceName: 'science name',
    //     isProtected: true
    //   }
    // ]);
  }

  findWaterLevels() {
    return of([
        new WaterLevel('Duna - Nagybajcs', '302 cm', '908cm', new Date()),
        new WaterLevel('Duna - Gönyű', '302 cm', '908cm', new Date()),
        new WaterLevel('Duna - Komárom', '302 cm', '908cm', new Date()),
        new WaterLevel('Duna - Esztergom', '302 cm', '908cm', new Date()),
        new WaterLevel('Duna - Budapest', '302 cm', '908cm', new Date()),
        new WaterLevel('Duna - Paks', '302 cm', '908cm', new Date()),
        new WaterLevel('Duna - Baja', '302 cm', '908cm', new Date()),
        new WaterLevel('Duna - Mohács', '302 cm', '908cm', new Date()),
        new WaterLevel('Tisza - Tiszabecs', '302 cm', '908cm', new Date()),
        new WaterLevel('Tisza - Vásárosnamény', '302 cm', '908cm', new Date()),
        new WaterLevel('Tisza - Záhony', '302 cm', '908cm', new Date()),
        new WaterLevel('Tisza - Tokaj', '302 cm', '908cm', new Date())
      ]
    );
  }

  findWaters(): Observable<Array<WaterModel>> {
    return of([
      new WaterModel('Fenyves-tó', 19, 4, 18.0914, 47.19375),
      new WaterModel('Fertő-tó', 8, 4, 16.78564, 47.6751),
      new WaterModel('Kunfehértó-horgásztó', 19, 4, 19.39362, 46.37791),
      new WaterModel('Eleki Munkás HE tavak (Eleki téglagyári gödör)',3 ,4 , 21.2655,46.52728 ),
      new WaterModel('Orosházi Téglagyári tavak',3 ,4 , 20.64297,46.58057 ),
      new WaterModel('Hasznosi-víztározó',13 ,5 , 19.767323,47.92612 ),
      new WaterModel('Marcal-tó (Alsóvíz)',8 ,6 , 17.62503,47.66504 ),
      new WaterModel('Szenke-tó',16 ,5 , 22.55553,47.9944 ),
      new WaterModel('Égeresi-tavak',12 ,4 , 18.30434,47.45566 ),
      new WaterModel('Gyúrói-tó',7 ,4 , 18.74199,47.36022 ),
      new WaterModel('Tisza (Szabolcs-Sz.-Bereg megye)',16 ,1 , 22.31941,48.1528 ),
      new WaterModel('Pötrétei-tavak',20 ,4 , 16.93817,46.6992 ),
      new WaterModel('Gödrösöki horgásztó',3 ,4 , 21.01976,46.3017 ),
      new WaterModel('Nagybirkás-tó',19 ,4 , 18.15079,47.17758 ),
      new WaterModel('Tiszalöki Er?m?',16 ,4 , 21.30798,48.02552 ),
      new WaterModel('Nicki gát (Rába)',18 ,1 , 17.03413,47.38452 ),
      new WaterModel('Mártélyi Holt-Tisza',6 ,6 , 20.21862,46.477 ),
      new WaterModel('Kádártai bányató',19 ,4 , 17.96183,47.11958 ),
      new WaterModel('Sárszentmihályi-tározó',7 ,5 , 18.2973,47.17775 ),
      new WaterModel('Suszti Horgásztó és Vendégház',3 ,4 , 21.34884,46.89217 ),
      new WaterModel('Babóti-kavicsbányató',8 ,4 , 17.07808,47.58336 ),
      new WaterModel('Jaffa-tó',18 ,4 , 16.92736,47.27492 ),
      new WaterModel('Stettni-tó',8 ,4 , 17.15635,47.91853 ),
      new WaterModel('Fehér-úti tó (Brennbergi víztározó)',8 ,5 , 16.48878,47.67553 ),
      new WaterModel('L?rintei-tó',19 ,5 , 17.51633,47.07503 ),
      new WaterModel('Cibakházi Holt-Tisza',11 ,6 , 20.18128,46.94804 ),
      new WaterModel('Vályogos tó',6 ,5 , 20.41368,46.18097 ),
      new WaterModel('Holt-Marcal (Nádorvárosi SE)',8 ,6 , 17.61349,47.64995 ),
      new WaterModel('Ász Horgásztó',9 ,4 , 21.1958,47.23204 ),
      new WaterModel('Feneketlen-tó',5 ,4 , 19.04189,47.47713 ),
      new WaterModel('Hárosi-öböl',5 ,6 , 19.01864,47.39807 ),
      new WaterModel('Újpesti téli-kiköt? (Újpesti-öböl)',5 ,6 , 19.06958,47.55238 ),
      new WaterModel('Bónom-zugi holtág (Gyomaendr?d)',3 ,6 , 20.75086,46.93292 ),
      new WaterModel('Farkincás tó',18 , 4, 16.79844,47.08912 )
    ])
  }

  findWater(waterName: string) {
    return of(
      new WaterModel('Bónom-zugi holtág (Gyomaendr?d)',3 ,6 , 20.75086,46.93292 )
    );
  }

  static isFishPageContition(url: string): boolean {
    return url === 'halak';
  }

  static isWaterPageCondition(url: string): boolean {
    return url === 'vizek';
  }
}
