import { Component, OnInit } from '@angular/core';
import Auth from '@aws-amplify/auth';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { FormService } from '../../../service/form.service';
import { FacebookLoginProvider, SocialAuthService, SocialUser } from 'angularx-social-login';
import { Router } from '@angular/router';
import { DataExchangeService } from '../../../service/data-exchange.service';
import { UserState } from '../../../store/user/UserReducer';
import { Store } from '@ngrx/store';
import { LoginUser } from '../../../store/user/UserActions';
import { User } from '../../../model/User';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RegisterService } from '../../../service/register.service';
import { AuthenticationService } from '../../../service/authentication.service';
import { SignUpParams } from '@aws-amplify/auth/src/types/Auth';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  registerForm: FormGroup;
  forgetForm: FormGroup;

  modalType: string;

  emailFocus: boolean = false;

  hidePsw = true;

  constructor(private readonly toastrService: ToastrService,
              private readonly translateSvice: TranslateService,
              private readonly formService: FormService,
              private readonly authService: SocialAuthService,
              private readonly authenticationService: AuthenticationService,
              private readonly router: Router,
              private readonly dataexchangeService: DataExchangeService,
              private readonly userStore: Store<UserState>,
              public readonly bsModalRef: BsModalRef,
              private readonly progressSpinnerService: NgxSpinnerService,
              private readonly registerService: RegisterService) {
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID)
      .then((resp: SocialUser) => {
        const user = new User(resp.id, resp.name, true, resp.photoUrl);
        this.userStore.dispatch(new LoginUser(user));
        this.router.navigateByUrl('');
      }).catch(err => {
        console.log(err);
    });
  }

  ngOnInit(): void {
    this.loginForm = this.formService.getLoginForm();
    this.registerForm = this.formService.getRegistrationForm();
    this.forgetForm = this.formService.getForgetPasswordForm();
  }

  login() {
    const username = this.loginForm.value.username;
    const password = this.loginForm.value.password;

    this.progressSpinnerService.show();

    Auth.signIn({
      username: username,
      password: password
    }).then((user: any) => {
      this.authenticationService.login(user.signInUserSession.accessToken.jwtToken).subscribe((resp) => {
        console.log(resp);
        // const authenticatedUser = new User(user.signInUserSession.accessToken.payload.sub, user.signInUserSession.accessToken.payload.username);
        localStorage.setItem('currentUser', JSON.stringify(resp));
        this.userStore.dispatch(new LoginUser(resp));
        this.progressSpinnerService.hide();
        this.bsModalRef.hide();
      }, err => {
        console.log(err);
        this.progressSpinnerService.hide();
      });
    }).catch(err => {
      console.log(err);
      this.progressSpinnerService.hide();
      this.toastrService.error(this.translateSvice.instant('login.error.invalidUsernameOrPassword'));
    });
  }

  forgotPassword(username: string) {
    Auth.forgotPassword(username)
      .then((resp) => {
        console.log(resp);
      })
      .catch(err => {
        console.log(err);
      });
  }

  setModalType(string) {
    this.modalType = string;
  }

  register() {
    const user: SignUpParams = {
      username: this.registerForm.value.username,
      password: this.registerForm.value.password,
      attributes: {
        email: this.registerForm.value.email
      }
    };
    Auth.signUp(user).then((createdUser => {
      console.log(createdUser);
      this.toastrService.show('Sikeres regisztráció, kattintson az emailben kapott linkre, hogy megerősítse regisztrációját!');
    }));
    this.registerService.register(this.registerForm.value).subscribe((resp) => {
      console.log(resp);
    }, err => {
      console.log(err);
    });
  }
}
