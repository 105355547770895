import { Component, EventEmitter, HostListener, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CartState } from '../../../store/cart/CartReducer';
import { initCartSelector } from '../../../store/cart/CartSelector';
import { SearchService } from '../../../service/search.service';
import { SearchResult } from '../../../model/SearchResult';
import { DataExchangeService } from '../../../service/data-exchange.service';
import { BsDropdownConfig, BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { AuthenticationService } from '../../../service/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { User } from '../../../model/User';
import { CategoryState } from '../../../store/category/CategoryReducer';
import { initCategoriesSelector } from '../../../store/category/CategorySelector';
import { Category } from '../../../model/Category';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginComponent } from '../../user/login/login.component';
import { UserState } from '../../../store/user/UserReducer';
import { LogoutUser } from '../../../store/user/UserActions';
import { initUserSelector } from '../../../store/user/UserSelector';
import { Subscription } from 'rxjs';
import { PageScrollService } from 'ngx-page-scroll-core';

@Component({
  selector: 'app-masterhead',
  templateUrl: './masterhead.component.html',
  providers: [{ provide: BsDropdownConfig, useValue: { autoClose: true } }]
})
export class MasterheadComponent implements OnInit, OnDestroy {

  @ViewChild('dp')
  profileDropdown: BsDropdownDirective;

  @Output()
  categoryClicked: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  cartClicked: EventEmitter<string> = new EventEmitter<string>();

  private subscriptions: Array<Subscription> = new Array<Subscription>();

  user: User;

  HOME_LINK = '';
  CART_LINK = '/kosar';

  contOfItem: number;

  query: string;

  searchResult: SearchResult;

  allCategories: Array<Category>;

  searchedCategories: Array<Category>;

  modalType: string;
  modalRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: false,
    class: 'modal-dialog-centered',
    animated: false
  };

  loginForm: FormGroup;
  passWordFocus = false;
  userNameFocus = false;

  showSearchResult: boolean;

  navbarOpen = false;
  scrollClass = false;

  showSearchInput: boolean;

  @HostListener('window:scroll', ['$event'])

  handleScroll(): void {
    this.scrollClass = window.scrollY > 50;
  }


  constructor(
    private readonly router: Router,
    private readonly cartStore: Store<CartState>,
    private readonly categoryStore: Store<CategoryState>,
    private readonly searchService: SearchService,
    private readonly userStore: Store<UserState>,
    private readonly dataexchangeService: DataExchangeService,
    private readonly authenticationService: AuthenticationService,
    private readonly toastr: ToastrService,
    private readonly modalService: BsModalService,
    private readonly formBuilder: FormBuilder,
    private pageScrollService: PageScrollService
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngOnInit(): void {
    this.categoryStore.select(initCategoriesSelector).subscribe((categories) => {
      this.allCategories = categories;
    });
    this.cartStore.select(initCartSelector).subscribe((cart) => {
      this.contOfItem = Object.values(cart)
        .map(p => p.amount)
        .reduce((a, b) => a + b, 0);
      this.dataexchangeService.setNumberOfProductsInCart(this.contOfItem);
    });
    this.subscriptions.push(this.userStore.select(initUserSelector).subscribe((user) => {
      this.user = user;
    }));
    // this.dataexchangeService.user.subscribe((user) => {
    //   this.user = user;
    // });

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.router.events.subscribe(() => {
      this.showSearchResult = false;
    });
  }

  clickCategory(): void {
    this.categoryClicked.emit('open');
  }

  navigate(link: string): void {
    this.router.navigateByUrl(link);
  }

  search(query: string): void {
    this.searchedCategories = [];
    if (!query || query.length === 0) {
      this.searchResult.products = [];
      this.searchResult.manufacturers = [];
      this.searchResult = null;
      return;
    }
    this.searchService.search(query).subscribe(async (resp) => {
      // this.searchedCategories = this.allCategories.filter(c => resp.categories.includes(c.id));
      // TODO MOCK DATA -> uncomment row above
      // this.searchedCategories = this.allCategories.filter((c) => resp.categories.includes(c.id));
      this.allCategories.forEach(c => {
        if (resp.categories.includes(c.id)) {
          this.searchedCategories.push(c);
        }
        c.children.forEach(sc => {
          if (resp.categories.includes(sc.id)) {
            this.searchedCategories.push(sc);
          }
        });
      });
      console.log(this.searchedCategories);
      this.searchResult = resp;
      this.showSearchResult = true;
    });
  }

  logout(): void {
    localStorage.removeItem('currentUser');
    this.authenticationService.logout().subscribe(() => {
      // this.dataexchangeService.setUser(null);
      this.userStore.dispatch(new LogoutUser());
      this.toastr.success('Sikeres kijelentkezés');
      this.router.navigateByUrl('');
    }, err => {
      // console.log(err);
      // this.toastr.error(err.error.message);
    });
  }

  openLogin(modalType: string): void {
    const initialState = {
      modalType
    };
    this.modalRef = this.modalService.show(LoginComponent, {
      backdrop: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered',
      animated: false,
      initialState
    });
  }

  setModalType(string) {
    this.modalType = string;
  }

  toggleNavbar(): void {
    this.navbarOpen = !this.navbarOpen;
    // this.showSearchInput = false;
  }

  showSearch(): void {
    this.showSearchInput = !this.showSearchInput;
   // this.scrollClass = true;

    // if (this.showSearchInput) {
    //   this.scrollClass = true;
    //   // console.log('most false', this.showSearchInput);
    // } else {
    //   this.scrollClass = false;
    // }

    // if (this.scrollClass) {
    //   this.showSearchInput = !this.showSearchInput;
    //   console.log('scrollclass true', this.scrollClass);
    // }


    //   this.scrollClass = false;
    // if (this.scrollClass) {
    // this.showSearchInput = !this.showSearchInput;
    // }
}

}
