import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'water'
})
export class WaterPipe implements PipeTransform {

  transform(value: number): string {
    switch (value) {
      case 1 : return 'folyó';
      case 2 : return 'patak';
      case 3 : return 'csatorna';
      case 4 : return 'tó';
      case 5 : return 'tározó';
      case 6 : return 'holtág';
    }
  }

}
