<div class="container-fluid product-list">

  <div class="row">
    <div class="col-xl-19">

      <div class="row">
        <div class="col-sm-12 col-xxl-7 d-flex align-items-center order-1">
<!--          <app-site-navigation></app-site-navigation>-->
        </div>

        <div class="col-xxl-10 d-flex align-items-end justify-content-center order-3 order-lg-3 order-xxl-2">
          <h1>{{title}}</h1>
        </div>

        <div class="col-sm-12 col-xxl-7 d-flex justify-content-center justify-content-sm-end align-items-center btn-nav order-2 order-lg-2 order-xxl-3 pt-4 pt-xxl-0">
          <app-order [values]="orderings" [initialValue]="orderBy.link" [(ngModel)]="orderBy"
                     (ngModelChange)="updateParams(filter, activeFilter, $event.link);"></app-order>
          <app-page-sizer [(ngModel)]="pagination.itemsPerPage" (ngModelChange)="updatePageSize($event)"></app-page-sizer>
        </div>
      </div>

      <div class="d-flex align-items-end justify-content-center">
        <mat-button-toggle-group *ngIf="dedicatedFilter" appearance="legacy" name="groups" aria-label="Csoportok"
                                 [(value)]="dedicatedFilterValue" (change)="changeDedicatedFilter($event)">
          <mat-button-toggle value="all">Összes</mat-button-toggle>
          <mat-button-toggle value="{{p.link}}" *ngFor="let p of dedicatedFilter">{{p.label}}</mat-button-toggle>
        </mat-button-toggle-group>
      </div>


      <div class="row">
        <app-product class="col-24 col-sm-12 col-md-8 col-lg-6 col-xl-8 col-xxl-6 mb-5 d-flex"
                     *ngFor="let p of products | paginate: pagination; trackBy:trackByIdentity"
                     [product]="p"
                     [link]="getProductLink()"
        ></app-product>

        <div class="col-24 text-center">
          <pagination-controls class="pt-5 pb-5"
                               nextLabel=""
                               previousLabel=""
                               responsive="true"
                               screenReaderPaginationLabel="Lapozó"
                               screenReaderPageLabel="oldal"
                               screenReaderCurrentLabel="aktív oldal"
                               (pageChange)="pageChanged($event)"></pagination-controls>
        </div>

      </div>

      <!--BRANDS-------------------------------------->
      <div class="brands">
        <app-brand-list></app-brand-list>
      </div>


    </div>

    <div class="col-xl-5 aside">

      <div class="row">
        <!--FILTERS-------------------------------------->
        <div class="col-md-12 col-xl-24" *ngIf="filter">
          <app-filter [(ngModel)]="activeFilter" [filter]="filter" [initialValue]="activeFilter"
                      (ngModelChange)="updateParams(filter, $event, orderBy.link, 1)"></app-filter>
        </div>

        <!--SUBCATEGORIES-------------------------------->
        <div class="col-md-12 col-xl-24">
          <div class="list-group" *ngIf="mainCategory">
            <app-sub-categories-list [category]="mainCategory" [activeSubcategory]="subCategoryParam"></app-sub-categories-list>
          </div>
        </div>

      </div>


    </div><!--  row END-->

  </div><!--  aside END-->


</div>
<!--  container-fluid END-->


