import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ShoppingCartComponent } from '../shopping-cart/shopping-cart.component';
import { Store } from '@ngrx/store';
import { CartState } from '../../../store/cart/CartReducer';
import { CategoryState } from '../../../store/category/CategoryReducer';
import { ProductState } from '../../../store/product/ProductReducer';
import { CartService } from '../../../service/cart.service';
import { ProductService } from '../../../service/product.service';
import { Router } from '@angular/router';
import { DataExchangeService } from '../../../service/data-exchange.service';
import { ChangeAmountOfProduct, RemoveProductFromCart } from '../../../store/cart/CartActions';

@Component({
  selector: 'app-checkout-shopping-cart',
  templateUrl: './checkout-shopping-cart.component.html'
})
export class CheckoutShoppingCartComponent extends ShoppingCartComponent implements OnInit {

  @Output()
  cartClicked: EventEmitter<string> = new EventEmitter<string>();

  constructor(cartStore: Store<CartState>,
              categoryStore: Store<CategoryState>,
              productStore: Store<ProductState>,
              cartService: CartService,
              productService: ProductService,
              router: Router,
              dataexchangeService: DataExchangeService) {
    super(cartStore, categoryStore, productStore, cartService, productService, router, dataexchangeService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  increaseAmountForProduct(productId: number) {
    this.cartStore.dispatch(new ChangeAmountOfProduct(productId, this.cart[productId].amount + 1));
  }

  decreaseAmountForProduct(productId: number) {
    if (this.cart[productId].amount === 1) {
      this.cartStore.dispatch(new RemoveProductFromCart(productId));
    } else {
      this.cartStore.dispatch(new ChangeAmountOfProduct(productId, this.cart[productId].amount - 1));
    }
  }

}
