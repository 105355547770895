import { Injectable } from '@angular/core';
import { ActiveFilter } from '../model/ActiveFilter';
import { Filter } from '../model/Filter';
import { FilterPropertyState } from '../model/FilterPropertyState';
import { Params } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  DEFAULT_PAGE_SIZE: number = 12;
  DEFAULT_ORDER: string = 'nepszeru';

  constructor() {
  }

  initActiveFilter(filter: Filter, queryParams?: Params): ActiveFilter {
    const activeFilter = new ActiveFilter();

    Object.keys(filter.properties).forEach(k => {
      activeFilter.properties[k] = new Array<FilterPropertyState>();
      filter.properties[k].forEach(p => activeFilter.properties[k].push(new FilterPropertyState(p, false)));
    });

    if (queryParams && queryParams['ar']) {
      const priceRange = queryParams['ar'].split('-');
      // TODO validations
      const minPrice = Number(priceRange[0]);
      const maxPrice = Number(priceRange[1]);
      this.initPriceRange(activeFilter, minPrice, maxPrice);
    } else {
      this.initPriceRange(activeFilter, filter.priceRange.min, filter.priceRange.max);
    }

    if (queryParams) {
      Object.keys(queryParams).forEach(p => {
        if (p === 'ar') {
          return;
        }
        const values = queryParams[p].split(',');
        values.forEach(v => {
          if (!activeFilter.properties[p]) {
            return;
          }
          const property = activeFilter.properties[p].find(param => param.label === v);
          if (property) {
            property.isChecked = true;
          }
        })
      })
    }

    return activeFilter;
  }

  getFilterIsEmpty(activeFilter: ActiveFilter, filter: Filter): boolean {
    if (activeFilter.priceRange.min !== filter.priceRange.min ||
      activeFilter.priceRange.max !== filter.priceRange.max ||
      Object.keys(activeFilter.properties).some(k => activeFilter.properties[k].some(prop => prop.isChecked))) {
      return false;
    } else {
      return true;
    }
  }

  initPriceRange(activeFilter: ActiveFilter, min: number, max: number): void {
    activeFilter.priceRange.min = min;
    activeFilter.priceRange.max = max;
  }

  clearFilters(activeFilter: ActiveFilter) {
    Object.keys(activeFilter.properties).forEach(k => {
      activeFilter.properties[k].forEach(p => {
        p.isChecked = false;
      });
    });
  }

  getQueryParams(filter: Filter, activeFilter: ActiveFilter, orderBy: string, currentPage: number, itemsPerPage: number): string {
    const properties = {}
    Object.keys(activeFilter.properties).forEach(k => {
      const props = [];
      activeFilter.properties[k].forEach(f => {
        if (f.isChecked) {
          props.push(f.label);
        }
      });
      if (props.length > 0) {
        properties[k] = props;
      }
    });
    const propKeys = Object.keys(properties);
    const isPriceQueried = filter.priceRange.min !== activeFilter.priceRange.min || filter.priceRange.max !== activeFilter.priceRange.max;
    const isPageDefault = itemsPerPage === this.DEFAULT_PAGE_SIZE && currentPage === 1;
    // let queryParam = isPriceQueried ? `?ar=${filter.priceRange.min}-${filter.priceRange.max}` : '';
    let queryParam;

    if (isPriceQueried && isPageDefault) {
      queryParam = `?ar=${activeFilter.priceRange.min}-${activeFilter.priceRange.max}`;
    } else if (isPriceQueried && !isPageDefault) {
      queryParam = `${currentPage === 1 ? '' : `?oldal=${currentPage}`}${itemsPerPage !== this.DEFAULT_PAGE_SIZE ? (currentPage === 1 ? `?oldal_meret=${itemsPerPage}` : `&oldal_meret=${itemsPerPage}`) : '' } &ar=${activeFilter.priceRange.min}-${activeFilter.priceRange.max}`;
    } else if (!isPriceQueried && isPageDefault) {
      queryParam = '';
    } else if (!isPriceQueried && !isPageDefault) {
      queryParam = `${currentPage === 1 ? '' : `?oldal=${currentPage}`}${itemsPerPage !== this.DEFAULT_PAGE_SIZE ? (currentPage === 1 ? `?oldal_meret=${itemsPerPage}` : `&oldal_meret=${itemsPerPage}`) : '' }`;
    }

    if (propKeys.length > 0) {
      propKeys.forEach((pk, index) => {
        if ((!isPriceQueried && isPageDefault) && index === 0) {
          queryParam += '?';
        } else {
          queryParam += '&';
        }
        queryParam += pk + '=' + properties[pk].toString().split(',').join('%2c');
      })
    }

    if (orderBy && orderBy !== this.DEFAULT_ORDER) {
      if (queryParam.length > 0) {
        queryParam += `&rendezes=${orderBy}`;
      } else {
        queryParam += `?rendezes=${orderBy}`;
      }
    }

    return queryParam;
  }
}
