

<!--  <ngx-avatar [size]="170" *ngIf="!user.isFacebookUser" class="col-6" src="assets/images/avatar.png"></ngx-avatar>-->
  <!--    <ngx-avatar [size]="170" [facebookId]="user.userId" class="col-6"></ngx-avatar>-->
    <app-personal-data [personalData]="profile.personalData"></app-personal-data>

<hr class="mt-5">
    <h2 class="mb-5 mt-5 text-center">Szállítási címek</h2>

    <div class="mb-5 text-center">
      <button class="btn btn-info text-white" *ngIf="!addNewAddressState" (click)="changeAddNewAddressState()"><i class="fa fa-plus"></i> Új cím hozzáadása</button>
    </div>

    <form *ngIf="addNewAddressState" class="row justify-content-center border bg-warning pb-5 pt-5 mb-5" [formGroup]="addressForm">

      <div class="col-24"><h3 class="mb-3"><i class="fa fa-plus"></i> Új szállítási cím</h3></div>

      <div class="col-lg-6">
         <div class="form-floating">
                <input type="text" id="countryInput" class="col-24 form-control" data-lpignore="true" [class.invalid]="addressForm.get('country').invalid &&
        (addressForm.get('country').dirty || addressForm.get('country').touched)"  name="country" formControlName="country" [placeholder]="'address.country' | translate">
                <label for="countryInput">Ország</label>
              </div>
      </div>

      <div class="col-8 col-lg-4">
        <div class="form-floating">
          <input type="number" id="postalCodeInput" class="form-control addressInput" data-lpignore="true" [class.invalid]="addressForm.get('postalCode').invalid &&
        (addressForm.get('postalCode').dirty || addressForm.get('postalCode').touched)" name="postalCode" formControlName="postalCode" [placeholder]="'address.postalCode' | translate">
          <label for="postalCodeInput">Irányítószám</label>
        </div>
      </div>


      <div class="col-16 col-lg-6">
        <div class="form-floating">
          <input type="text" id="cityInput" class="form-control addressInput" data-lpignore="true" [class.invalid]="addressForm.get('city').invalid &&
        (addressForm.get('city').dirty || addressForm.get('city').touched)" name="city" formControlName="city" [placeholder]="'address.city' | translate">
          <label for="cityInput">Város</label>
        </div>
      </div>

      <div class="col-lg-8">
        <div class="form-floating">
          <input type="text" id="addressInput" class="col-24 form-control addressInput" data-lpignore="true" [class.invalid]="addressForm.get('address').invalid &&
        (addressForm.get('address').dirty || addressForm.get('address').touched)" name="address" formControlName="address" [placeholder]="'address.address' | translate">
          <label for="addressInput">{{ 'address.address' | translate }}</label>
        </div>
      </div>

      <div class="col-lg-8 pt-5">
        <div class="form-floating">
          <input type="text" class="col-24 form-control addressInput" data-lpignore="true" [class.invalid]="addressForm.get('phoneNumber').invalid &&
        (addressForm.get('phoneNumber').dirty || addressForm.get('phoneNumber').touched)" name="phoneNumber" formControlName="phoneNumber" [placeholder]="'address.phoneNumber' | translate">
          <label for="addressInput">Telefonszám</label>
        </div>
      </div>


        <div class="d-flex justify-content-center mt-5 mb-5">
          <button class="btn btn-dark me-4" type="submit" [disabled]="!isSubmitEnabled" (click)="addNewAddress()">Hozzáadás</button>
          <button class="btn btn-outline-secondary" (click)="closeAddNewAddress()">Mégsem</button>
        </div>

    </form>
    <app-address *ngFor="let a of profile.addresses" [address]="a"></app-address>


    <h2 class="mb-5 text-center">Cég adatok</h2>
    <app-company [company]="profile.company"></app-company>


