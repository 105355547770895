import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  constructor(private httpClient: HttpClient) {
  }

  addProductToCart(productId: string, amount: number): Observable<void> {
    return this.httpClient.post<void>('/api/user/cart/add', {productId, amount});
  }

  removeProductFromCart(productId: string): Observable<void> {
    return this.httpClient.post<void>('/api/user/cart/remove', {productId});
  }

  updateAmountOfProduct(productId: string, amount): Observable<void> {
    return this.httpClient.post<void>('/api/user/cart/update-amount', {productId, amount});
  }
}
