import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Category } from '../../../model/Category';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar-categories',
  templateUrl: './sidebar-categories.component.html'
})
export class SidebarCategoriesComponent implements OnInit {

  @Input()
  categories: Array<Category>;

  @Output()
  closeSidebarEvent: EventEmitter<void> = new EventEmitter<void>();

  panelOpenState = false;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  navigateToSubcategory(link: string) {
    this.router.navigateByUrl(link);
    this.closeSidebarEvent.emit();
  }
}
